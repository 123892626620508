const genres = [
  {
    genreId: "e7249460-cd97-4d79-83fc-64e925fa7864",
    genreName: "Dance",
    colors: {
      background: "#E2783F",
      text: "#520000",
      next: "#A14226",
    },
  },
  {
    genreId: "a791173d-1b7c-49d3-b186-160f2f36533e",
    genreName: "Dramatics",
    colors: {
      background: "#20A9A5",
      text: "#063938",
      next: "#60BDBA",
    },
  },
  {
    genreId: "49afb8df-62da-4148-b1b1-38db784faaf6",
    genreName: "Speaking Arts",
    colors: {
      background: "#b5cc00",
      text: "#520000",
      next: "#A14226",
    },
  },

  {
    genreId: "f6faf03c-d4c3-4fc9-886c-f421869f18ca",
    genreName: "Fine Arts",
    colors: {
      background: "#033F63",
      text: "#87D1FD",
      next: "#60BDBA",
    },
  },
  {
    genreId: "ab80e629-261b-4c38-b4e8-8be5dd72658e",
    genreName: "Music",
    colors: {
      background: "#b5cc00",
      text: "#520000",
      next: "#A14226",
    },
  },
  {
    genreId: "0e14e69b-8adf-4e7b-9fa0-09d0c28aab9a",
    genreName: "Design and Digital Arts",
    colors: {
      background: "#E2783F",
      text: "#520000",
      next: "#A14226",
    },
  },

  {
    genreId: "1af2972e-9b06-4926-98e9-b9fb5fb5f818",
    genreName: "Literary Arts",
    colors: {
      background: "#20A9A5",
      text: "#063938",
      next: "#60BDBA",
    },
  },
  {
    genreId: "317bbb9f-6da2-48bf-a38f-2feae847b83b",
    genreName: "Journalism and Communications",
    colors: {
      background: "#AB5050",
      text: "#FFD900",
      next: "#A14226",
    },
  },
  {
    genreId: "545ff919-d2f2-48a0-808e-807b311ddfd6",
    genreName: "Lifestyle",
    colors: {
      background: "#033F63",
      text: "#87D1FD",
      next: "#60BDBA",
    },
  },
];

export default genres;
