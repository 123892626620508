import React, { useEffect } from "react";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import FirstPage from "./HomeComponents/FirstPage";
import SecondPage from "./HomeComponents/SecondPage";
import ThirdPage from "./HomeComponents/ThirdPage";
import LastPage from "./HomeComponents/LastPage";
import Testimonial from "./HomeComponents/Testimonials";
import FourthPage from "./HomeComponents/FourthPage";
import ResgisterCircle from "./HomeComponents/ResgisterCircle";
import ballon from "../../../assets/images/ccp/random_assets/ballon.svg";
import "./style.css";
import "./home_responsive.css";
import Helps from "../../small_component/Helps";

gsap.registerPlugin(ScrollTrigger);

const IndexHome = () => {
  useEffect(() => {
    if (window.innerWidth <= 730) {
      gsap.to(".registercirlce", {
        xPercent: 60,
        duration: 12,
        scrollTrigger: {
          trigger: ".registercirlce",
          start: "top center",
          end: "+=60%",
          scrub: true,
        },
      });
    } else {
      gsap.to(".registercirlce", {
        xPercent: 80,
        duration: 12,
        scrollTrigger: {
          trigger: ".registercirlce",
          start: "top center",
          end: "+=100%",
          scrub: true,
        },
      });
    }
  }, []);

  useEffect(() => {
    if (window.innerWidth <= 730) {
      // gsap.to(".object_g", {
      //   yPercent: 520,
      //   xPercent: -320,
      //   duration: 10,
      //   scrollTrigger: {
      //     trigger: ".object_g",
      //     start: "top",
      //     end: "+=90%", // Animation ends when scroll reaches 100% past the trigger
      //     scrub: true, // Smooth scrolling effect      // Show markers for debugging (you can set this to false in production)
      //   },
      // });
    } else {
      gsap.to(".object_g", {
        yPercent: 600,
        xPercent: -830,
        duration: 10,
        scrollTrigger: {
          trigger: ".object_g",
          start: "top",
          end: "+=90%", // Animation ends when scroll reaches 100% past the trigger
          scrub: true, // Smooth scrolling effect      // Show markers for debugging (you can set this to false in production)
        },
      });
    }
  }, []);

  return (
    <div className="home_main" style={{ overflow: "hidden" }}>
      <div className="registercirlce">
        <ResgisterCircle />
      </div>
      <FirstPage />
      <SecondPage />
      <img className="object_g" src={ballon} alt="ballon" />
      <ThirdPage />
      <FourthPage />
      <Testimonial />
      <LastPage />
      <Helps />
    </div>
  );
};

export default IndexHome;
