import React from "react";
import HomeCom from "./component/HomeCom";
import "./css/compi.css";
import "./css/genre.css";
import Lyps from "./component/Lyps";
import About from "./component/AboutCompi";

const IndexCompi = () => {
  return (
    <div className="compiMain">
      <HomeCom />
      <Lyps />
      <About />
    </div>
  );
};

export default IndexCompi;
