import React, { useState } from "react";
import closeIcon from "../../../assets/images/ccp/close.svg";

const WeeklyTarget = () => {
  const [isQuestionVisible, setIsQuestionVisible] = useState(false);

  const toggleQuestions = () => {
    setIsQuestionVisible(true);
  };
  const closeForm = () => {
    setIsQuestionVisible(false);
  };

  return (
    <div className="weekly_mobile">
      <div className="weeklytarget">
        <p className="announcement_heading">Weekly Target</p>
        <div className="weeklytarget_a">
          <div>
            <p>Social Media Shares </p>
            <p>Same College Referrals</p>
            <p>Different College Referrals</p>
            <p>Thread Posts</p>
            <p>Answer Weekly Questions</p>
          </div>
          <div>
            <p>0/2</p>
            <p>0/1</p>
            <p>0/1</p>
            <p>0/1</p>
            <p>0/2</p>
          </div>
        </div>
      </div>
      <div className="guideline">
        <button onClick={toggleQuestions}>Guidelines</button>
        {isQuestionVisible && (
          <div className="questions" style={{background: "rgba(246, 245, 242, 0.4)",}}>
            <div className="thread_form_header">
              <p className="guideline_heading">Guidelines</p>
              <img src={closeIcon} onClick={closeForm} alt="close" />
            </div>
            <h4>Social Media</h4>
            <p>
              Like, comment, and follow all the official social media handles of
              Mood Indigo, IIT Bombay on Instagram, Facebook, Twitter, LinkedIn,
              and Youtube. This is so your shares can be tracked. Make sure you
              first update your Facebook, Instagram and LinkedIn ID from the
              edit profile tab; this would fetch you some points as well
            </p>
            <h4>Facebook</h4>
            <ul>
              <li>
                Like and share new posts to earn social media share points.
              </li>
              <li>If you reshare a shared post, you won’t get points.</li>
              <li>
                Upload a screenshot of the shared content on the “Click here to
                get Points” tab.
              </li>
            </ul>
            <h4>Instagram</h4>
            <ul>
              <li>
                Follow the account CCP; we will follow back and you need to
                accept.
              </li>
              <li>
                Like and share new posts to your story and tag both accounts to
                earn social media share points.
              </li>
              <li>
                If you share it in ‘Close Friends,’ you will not get any points.
              </li>
              <li>
                Upload a screenshot of the shared content on the designated tab.
              </li>
            </ul>
          </div>
        )}
      </div>
    </div>
  );
};

export default WeeklyTarget;
