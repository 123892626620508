import React from "react";
import First from "../../../../assets/images/ccp/leaderboard/first.jpg";
import Second from "../../../../assets/images/ccp/leaderboard/second.jpg";
import Third from "../../../../assets/images/ccp/leaderboard/third.jpg";

const IndigoSquad = () => {
  return (
    <div className="indigo_sqd">
      <p className="indigo_sqd_hed">INDIGO SQUAD PERFORMERS OF THE MONTH</p>
      <div className="ind_sqd_det">
        <div>
          <img src={First} alt="placeholder" />
          <p>Parth Gevariya</p>
        </div>
        <div>
          <img src={Second} alt="placeholder" />
          <p>Nitisha Garg</p>
        </div>
        <div>
          <img src={Third} alt="placeholder" />
          <p>Krishna Shukla</p>
        </div>
      </div>
    </div>
  );
};

export default IndigoSquad;
