/* eslint-disable react/jsx-pascal-case */
import React from "react";
import Random_assets from "./Registration/Random_assets";
import "./Registration/registration.css";

const ComingSoon = () => {
  return (
    <div>
      <Random_assets />
      <div className= "registration_main comingsoon">
        <p>COMING SOON</p>
      </div>
    </div>
  );
};

export default ComingSoon;
