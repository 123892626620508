import React, { useState } from "react";
import Axios from "axios";
import collegeData from "../../../assets/constants/streams";
import allStates from "../../../assets/constants/states";

function CollegeInfo({ user, setUser, page, setPage }) {
  const [cities, setCities] = useState([]);
  const [colleges, setColleges] = useState([]);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (
      user.state !== "" &&
      user.city !== "" &&
      user.college !== "" &&
      user.stream !== "" &&
      user.yearOfStudy !== ""
    ) {
      setPage(page + 1);
    } else {
      alert("* marked fields cannot be left empty");
    }
  };

  const getCities = async (state) => {
    Axios.get("https://ashish.moodi.org/users/city", {
      params: {
        state: state,
      },
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => setCities(res.data))
      .catch((err) => console.error(err));
  };

  const getColleges = async (city) => {
    Axios.get("https://ashish.moodi.org/users/college", {
      params: {
        city: city,
      },
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => setColleges(res.data))
      .catch((err) => console.error(err));
  };

  const onStateChange = (e) => {
    getCities(e.target.value);
    setUser({ ...user, [e.target.name]: e.target.value });
  };

  const onCityChange = (e) => {
    getColleges(e.target.value);
    setUser({ ...user, [e.target.name]: e.target.value });
  };

  const onChange = (e) => {
    setUser({ ...user, [e.target.name]: e.target.value });
  };
  return (
    <form>
      <div className="personalInfo">
        <select
          name="state"
          id="state"
          onChange={onStateChange}
          value={user.state}
          required
          className="inputEmail"
        >
          <option value="">State*</option>
          {allStates.map((state) => {
            return <option value={state}>{state}</option>;
          })}
        </select>
        <select
          name="city"
          id="city"
          onChange={onCityChange}
          value={user.city}
          required
          className="inputEmail"
        >
          <option value="">City*</option>
          {cities.map((item) => {
            return <option value={item.cityId}>{item.cityName}</option>;
          })}
        </select>
      </div>
      <input
        type="text"
        name="college"
        id="college"
        placeholder="Full College Name*"
        onChange={onChange}
        value={user.college}
        list="colleges"
        className="inputEmail"
      />
      <datalist id="colleges">
        {colleges.map((college) => {
          return (
            <option value={college.collegeName}>{college.collegeName}</option>
          );
        })}
      </datalist>
      <div className="personalInfo">
        <select
          name="stream"
          id="stream"
          onChange={onChange}
          value={user.stream}
          required
          className="inputEmail"
        >
          <option value="">Stream*</option>
          {collegeData.streams.map((stream) => {
            return <option value={stream}>{stream}</option>;
          })}
        </select>
        <select
          name="yearOfStudy"
          id="yearOfStudy"
          onChange={onChange}
          value={user.yearOfStudy}
          required
          className="inputEmail"
        >
          <option value="">Year Of Study*</option>
          {collegeData.yearOfStudyOptions.map((yos) => {
            return <option value={yos}>{yos}</option>;
          })}
        </select>
      </div>
      <div className="college_button">
        <button
          type="submit"
          className="btn-10 custom-btn "
          onClick={() => {
            setPage(page - 1);
          }}
        >
          Back
        </button>
        <button
          type="submit"
          onClick={handleSubmit}
          className="btn-10 custom-btn"
        >
          Next
        </button>
      </div>
    </form>
  );
}

export default CollegeInfo;
