import React, { useState, useRef, useEffect } from "react";
import { gsap } from "gsap";
import "./fourthpage.css";
import hhpLogo from "../../../../assets/images/ccp/ngo_partners/hhhlogo.svg";
import peta from "../../../../assets/images/ccp/ngo_partners/peta.svg";
import udaan from "../../../../assets/images/ccp/ngo_partners/udaan.svg";
import Books from "../../../../assets/images/ccp/first_page_assets/book.svg";
import Mic from "../../../../assets/images/ccp/first_page_assets/mic.svg";
import deepalay from "../../../../assets/images/ccp/ngo_partners/deepalaya.png";
import cancer from "../../../../assets/images/ccp/ngo_partners/cancer.png";
import sood from "../../../../assets/images/ccp/ngo_partners/sood.png";

import aero from "../../../../assets/images/ccp/spon/aero.jpg";
import acad from "../../../../assets/images/ccp/spon/acad.jpg";
import iz from "../../../../assets/images/ccp/spon/iz.jpg";
import stock from "../../../../assets/images/ccp/spon/stock.jpg";

const FourthPage = () => {
  const [selected, setSelected] = useState("statistics");
  const statisticsRef = useRef(null);
  const sponsoredRef = useRef(null);
  const ngoRef = useRef(null);
  const intervalRef = useRef(null); // Use ref to store interval
  const timeoutRef = useRef(null); // Use ref to store timeout

  const types = ["statistics", "sponsored", "ngo"];

  useEffect(() => {
    const animateContent = () => {
      const statistics = statisticsRef.current;
      const sponsored = sponsoredRef.current;
      const ngo = ngoRef.current;

      const allContents = [statistics, sponsored, ngo];
      allContents.forEach((content) => {
        if (content.dataset.type === selected) {
          gsap.to(content, {
            scale: 2,
            duration: 0.3,
            x: 0,
            y: 0,
            zIndex: 1,
            opacity: 1,
          });
        } else {
          gsap.to(content, {
            scale: 0.8,
            duration: 1,
            x: content.dataset.type === "sponsored" ? -250 : 250,
            y: 0,
            zIndex: 0,
            opacity: 0.6,
            boxShadow: "none",
          });
        }
      });
    };

    animateContent();
  }, [selected]);

  const handleLogo = (url) => {
    window.location.href = url;
  };

  const handleClick = (type) => {
    setSelected(type);
    clearInterval(intervalRef.current); 

    clearTimeout(timeoutRef.current); 
    timeoutRef.current = setTimeout(() => {
      startInterval(); 
    }, 5000);
  };

  const startInterval = () => {
    intervalRef.current = setInterval(() => {
      setSelected((prev) => {
        const currentIndex = types.indexOf(prev);
        const nextIndex = (currentIndex + 1) % types.length;
        return types[nextIndex];
      });
    }, 3000);
  };

  useEffect(() => {
    startInterval();

    return () => {
      clearInterval(intervalRef.current);
      clearTimeout(timeoutRef.current);
    };
  }, []);

  return (
    <div className="fourthpage_main">
      <img className="fourthpage_mic" src={Mic} alt="mic" />
      <img className="fourthpage_books" src={Books} alt="books" />
      <div className="circle">
        <p
          className={`p1 ${selected === "sponsored" ? "active" : ""}`}
          onClick={() => handleClick("sponsored")}
        >
          SPONSORED BY
        </p>
        <p
          className={`p2 ${selected === "statistics" ? "active" : ""}`}
          onClick={() => handleClick("statistics")}
        >
          CCP STATISTICS
        </p>
        <p
          className={`p3 ${selected === "ngo" ? "active" : ""}`}
          onClick={() => handleClick("ngo")}
        >
          NGO PARTNERS
        </p>
      </div>

      <div
        className="fourthpage_image"
        ref={statisticsRef}
        data-type="statistics"
      >
        <div className="fourthpage_statistics">
          <p className="number_sta" style={{ color: "#D21148" }}>
            95000+ MEMBERS
          </p>
          <p className="number_sta" style={{ color: "#FB9F14" }}>
            5500+ Colleges
          </p>
          <p className="number_sta" style={{ color: "#0CB3A9" }}>
            500+ CITIES
          </p>
        </div>
      </div>

      <div
        className="fourthpage_image"
        ref={sponsoredRef}
        data-type="sponsored"
      >
        <div className="fourthpage_statistics">
          <div className="fourthpage_statistics_a" style={{ display: "flex" }}>
            <div className="partner">
              <img
                onClick={() => handleLogo("https://aerobay.in/")}
                className="peta"
                src={aero}
                alt="peta"
              />
              <p className="partner_image">Official Partner</p>
            </div>
            <div className="partner">
              <img
                onClick={() => handleLogo("https://rpwhiz.com/")}
                className="peta"
                src={iz}
                alt="peta"
              />
              <p className="partner_image"> Training partner</p>
            </div>
          </div>
          <div className="fourthpage_statistics_a" style={{ display: "flex" }}>
            <div className="partner">
              <img
                onClick={() => handleLogo("https://acadspace.org/")}
                className="hhplogo"
                src={acad}
                alt="hhp logo"
              />
              <p className="partner_image">Official Career partner</p>
            </div>
            <div className="partner">
              <img
                onClick={() => handleLogo("https://www.stockgro.club/")}
                className="udaan"
                src={stock}
                alt="udaan"
              />
              <p className="partner_image">Finance and Trading partner</p>
            </div>
          </div>
        </div>
      </div>

      <div className="fourthpage_image" ref={ngoRef} data-type="ngo">
        <div className="fourthpage_statistics">
          <div className="fourthpage_statistics_a">
            <img
              onClick={() => handleLogo("https://hhhindia.org/")}
              className="hhplogo"
              src={hhpLogo}
              alt="hhp logo"
            />
            <img
              onClick={() => handleLogo("https://www.petaindia.com/")}
              className="peta"
              src={peta}
              alt="peta"
            />
          </div>
          <div className="fourthpage_statistics_a">
            <img
              onClick={() => handleLogo("https://www.indiancancersociety.org/")}
              className="hhplogo"
              src={cancer}
              alt="hhp logo"
            />
            <img
              onClick={() => handleLogo("https://www.udaanwelfare.org/")}
              className="udaan"
              src={udaan}
              alt="udaan"
            />
          </div>
          <div className="fourthpage_statistics_a">
            <img
              onClick={() => handleLogo("https://deepalaya.org/")}
              className="peta"
              src={deepalay}
              alt="peta"
            />
            <img
              onClick={() => handleLogo("https://soodcharityfoundation.org/")}
              className="udaan"
              src={sood}
              alt="udaan"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default FourthPage;
