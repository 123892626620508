import { useState } from "react";
import userContext from "./userContext";
import Axios from "axios";
import { jwtDecode } from "jwt-decode";

const UserState = (props) => {
    const [user, setUser] = useState({});

    const getUser = () => {
        Axios.post(
            "https://ashish.moodi.org/users/get_user_details",
            {
                userId: jwtDecode(sessionStorage.getItem("miauthtoken")).user
                    .userId,
            },
            {
                headers: {
                    "Content-Type": "application/json",
                },
            }
        )
            .then((res) => {
                setUser(res.data[0]);
            })
            .catch((err) => console.error(err));
    };

    const updateCcpPoints = (points) => {
        setUser({ ...user, ccpPoints: user.ccpPoints + points });
    };

    return (
        <userContext.Provider value={{ user, getUser, updateCcpPoints }}>
            {props.children}
        </userContext.Provider>
    );
};

export default UserState;
