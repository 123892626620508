import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const Vogue = () => {
  const navigate = useNavigate();

  useEffect(() => {
    navigate("/competitions/genre/545ff919-d2f2-48a0-808e-807b311ddfd6");
  }, []);
  return <div></div>;
};

export default Vogue;
