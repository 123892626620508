import React from "react";
import PageOne from "./component/Clpageone";

const IndexCl = () => {
  return (
    <div style={{ overflow: "hidden", width: "100vw" }}>
      <PageOne />
    </div>
  );
};

export default IndexCl;
