import React from "react";

const Levelup = () => {
  return (
    <div className="levelup">
      <p className="announcement_heading">Level up</p>
      <ul>
        <li>
          Go follow{" "}
          <a
            href="https://www.instagram.com/indigosquad.moodi/"
            rel="noreferrer"
            target="_blank"
          >
            @indigosquad.moodi on
          </a>{" "}
          instagram for the exclusive Indigo Squad Content and notifications!
        </li>
        <li>
          Go to earn points section and complete various tasks to boost up your
          CCP points!
        </li>
        <li>
          Go to Clubs section and interact with other Indigo Squad Members all
          over the country!
        </li>
        <li>
          Ask your friends to register on my.moodi.org using your referral code
          to earn CCP points!
        </li>
        <li>Keep checking your mails for latest updates from Mood Indigo!</li>
      </ul>
    </div>
  );
};

export default Levelup;
