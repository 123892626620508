// src/TypedText.js
import React, { useEffect, useRef } from 'react';
import Typed from 'typed.js';

const TypedText = () => {
    const typedElement1 = useRef(null);
    const typedElement2 = useRef(null);
    const typedElement3 = useRef(null);
    const typedElement4 = useRef(null);

    useEffect(() => {
        const options1 = {
            strings: ["IN A WORLD FULL OF"],
            typeSpeed: 100,
            backSpeed: 100,
            backDelay: 1000,
            loop: true,
        };

        const options2 = {
            strings: ["COLLEGE AMBASSADORS."],
            typeSpeed: 100,
            backSpeed: 100,
            backDelay: 1000,
            loop: true,
        };

        const options3 = {
            strings: ["BE AN INDIGO SQUAD"],
            typeSpeed: 100,
            backSpeed: 100,
            backDelay: 1000,
            loop: true,
        };

        const options4 = {
            strings: ["MEMBER."],
            typeSpeed: 100,
            backSpeed: 100,
            backDelay: 1000,
            loop: true,
        };
        const typed1 = new Typed(typedElement1.current, options1);
        const typed2 = new Typed(typedElement2.current, options2);
        const typed3 = new Typed(typedElement3.current, options3);
        const typed4 = new Typed(typedElement4.current, options4);

        return () => {
            typed1.destroy();
            typed2.destroy();
            typed3.destroy();
            typed4.destroy();
        };
    }, []);

    return (
        <div className='nice_text'>
            <div className="type-wrap">
                <span ref={typedElement1} className="text"></span>
            </div>
            <div className="type-wrap">
                <span ref={typedElement2} className="text"></span>
            </div>
            <div className="type-wrap">
                <span ref={typedElement3} className="text"></span>
            </div>
            <div className="type-wrap">
                <span ref={typedElement4} className="text"></span>
            </div>
        </div>
    );
};

export default TypedText;
