import React from "react";

const Competition = () => {
  return (
    <div className="point_main">
      <p className="point_main_heading">Competition</p>
      <p  >Coming Soon</p>
    </div>
  );
};

export default Competition;
