import React from "react";

const Workshop = () => {
  return (
    <div className="point_main">
      <p className="point_main_heading">🎓 AcadSpace celebrates the fields of Commerce & Management</p>
      <p>Sessions are exclusively for BBA, BCom, MBA, and other students/aspirants! 🌟</p>
      <p>With participation from 180+ colleges, this is an opportunity you can't miss!</p>

      <h3>📆 Webinar Schedule:</h3>
      <div className="webinar">
        <p className="webinar_title">New Webinar:</p>

        <ul>
          <li>
            <strong>100 Days Strategy to Prepare for CAT 2024!</strong><br />
            🗓 <strong>Saturday, 17th August, 5 PM</strong><br />
            🔗 <a href="https://lu.ma/rpz0eqts" target="_blank" rel="noopener noreferrer">Register</a>
          </li>

          <li>
            <strong>How to Land a 9 LPA Job in Digital Marketing!</strong><br />
            🗓 <strong>Tuesday, 20th August, 7 PM</strong><br />
            🔗 <a href="https://lu.ma/rg1irn14" target="_blank" rel="noopener noreferrer">Register</a>
          </li>

          <li>
            <strong>Marketing Launchpad 2.0 - A Deep Dive into Program and Placements</strong><br />
            🗓 <strong>Sunday, 25th August, 12 PM</strong><br />
            🔗 <a href="https://lu.ma/n86htjug" target="_blank" rel="noopener noreferrer">Register</a>
          </li>
        </ul>
      </div>

      <h3>Why Attend?</h3>
      <ul>
        <li>Learn from top mentors from IIM, FMS, Bank of America, and HUL! 👩🏻‍💼👨🏻‍💻</li>
        <li>Earn a participation certificate 🌟</li>
      </ul>

      <p>🔥 Don’t miss this opportunity to network, learn, and grow.</p>

      <p><strong>Note:</strong> Certificates and CCP points are only awarded to those who attend the full webinar. (Join the meet with your full name and MI number in the bracket) The meet link will be shared to your registered email id.</p>
    </div>
  );
};

export default Workshop;
