import React, { useState } from "react";
import closeIcon from "../../../assets/images/ccp/close.svg";

const HelpSection = ({ onClose }) => {
  // State to manage form inputs
  const [topic, setTopic] = useState("");
  const [description, setDescription] = useState("");
  const [senderEmail, setSenderEmail] = useState("");
  const [responseMessage, setResponseMessage] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Data to send
    const data = { topic, description, senderEmail };

    try {
      const response = await fetch("https://ashish.moodi.org/email/help", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const result = await response.json();
      setResponseMessage("Help request sent successfully.");
      window.alert("Your query was sent successfully");
      window.location.reload();
    } catch (error) {
      setResponseMessage("Error sending help request.");
      console.error("Error:", error);
    }
  };

  return (
    <div
      className="questions"
      style={{
        overflowY: "scroll",
        background: "rgba(172, 221, 222, 0.4)",
        zIndex: "5"
      }}
    >
      <div className="thread_form_header">
        <p className="guideline_heading" style={{ marginTop: "-0.1vh" }}>
          Help Section
        </p>

        {/* Close Icon */}
        <img
          src={closeIcon}
          alt="close"
          className="close_icon"
          onClick={onClose}
        />
      </div>

      <form onSubmit={handleSubmit} className="help-form">
        <div className="form-group">
          <label htmlFor="topic">Topic:</label>
          <input
            type="text"
            id="topic"
            value={topic}
            onChange={(e) => setTopic(e.target.value)}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="description">Ask Your Query:</label>
          <textarea
            id="description"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            required
          ></textarea>
        </div>
        <div className="form-group">
          <label htmlFor="senderEmail">Your Email Id:</label>
          <input
            type="email"
            id="senderEmail"
            value={senderEmail}
            onChange={(e) => setSenderEmail(e.target.value)}
            required
          />
        </div>
        <button className="form-group-btn" type="submit">
          Send Help Request
        </button>
      </form>
      {responseMessage && <p>{responseMessage}</p>}
    </div>
  );
};

export default HelpSection;
