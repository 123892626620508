import React, { useEffect, useState } from "react";
import Axios from "axios";
import TeamMemberDetails from "./TeamMemberDetails.js";

const TeamCompiReg = ({
  tcRef,
  competition,
  user,
  regCompi,
  setRegCompi,
  team,
  setTeam,
}) => {
  const [teamDetails, setTeamDetails] = useState([]);
  const [member, setMember] = useState({ mi_no: "" });
  const [submitDisabled, setSubmitDisabled] = useState(false);
  const [newSubmitDisabled, setNewSubmitDisabled] = useState(false);

  const onCloseBtnClick = () => {
    tcRef.current.classList.add("no-disp");
  };

  const doLeaderReg = () => {
    Axios.post(
      "https://ashish.moodi.org/competitions/compi_leader_reg",
      {
        user: user.userId,
        competition: competition.competitionId,
        compi_name: competition.competitionName,
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
      .then((res) => {
        setTeam(res.data.team);
        fetchTeamDetails(res.data.team);
      })
      .catch((err) => console.error(err));
  };

  const fetchTeamDetails = (teamId) => {
    Axios.post(
      "https://ashish.moodi.org/competitions/compi_team_details",
      {
        team: teamId,
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
      .then((res) => {
        if (res.data.status === "DNE") {
          alert("Team does not exist");
        } else {
          setTeamDetails(res.data);
        }
      })
      .catch((err) => console.error(err));
  };

  const onMemberAddClick = () => {
    setSubmitDisabled(true);
    if (teamDetails.length >= competition.maxParticipants - 1) {
      alert(
        `You have added the maximum number of participants for this competition.`
      );
      setSubmitDisabled(false);
      return;
    }
    if (
      teamDetails.find((element) => element.MI_NO === member.mi_no) ||
      member.mi_no === user.MI_NO
    ) {
      alert("A user can be added only once in a team!");
      setSubmitDisabled(false);
    } else {
      Axios.post(
        "https://ashish.moodi.org/competitions/compi_member_reg",
        {
          team: team,
          user: member.mi_no,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
        .then((res) => {
          if (res.data.status === "done") {
            setTeamDetails((current) => [
              ...current,
              {
                userName: res.data.userName,
                MI_NO: member.mi_no,
              },
            ]);
            setSubmitDisabled(false);
          } else {
            alert(res.data.status);
            setSubmitDisabled(false);
          }
        })
        .catch((err) => console.error(err));
    }
  };

  const onSubmitClick = () => {
    setNewSubmitDisabled(true);
    if (teamDetails.length < competition.minParticipants - 1) {
      alert(
        `You need a team of at least ${competition.minParticipants} people to participate in this competition.`
      );
      setNewSubmitDisabled(false);
      return;
    }

    Axios.post(
      "https://ashish.moodi.org/competitions/confirm_team_reg",
      {
        team: team,
        compi_name: competition.competitionName,
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
      .then((res) => {
        if (res.data.status === "done") {
          setRegCompi((current) => [
            ...current,
            { competition: competition.competitionId },
          ]);
          setNewSubmitDisabled(true);
          alert(
            `Team Registration successfull for ${competition.competitionName}!! You may close the pop-up window.`
          );
          setNewSubmitDisabled(true);
          window.location.reload();
        }
      })
      .catch((err) => console.error(err));
  };

  const onChange = (e) => {
    setMember({ ...member, [e.target.name]: e.target.value });
  };

  useEffect(() => {
    doLeaderReg();
  }, []);
  return (
    <div ref={tcRef} className={`ne_compi_reg_page no-disp`}>
      <div className="ne_reg_container ">
        <button className="cross_btn_te" onClick={onCloseBtnClick}>
          X
        </button>
        <div className="ne_tem_a">
          <div className="tc_page_title" style={{ color: "#60BDBA" }}>
            TEAM REGISTRATION
          </div>
          <li>Only Team leader should register for this competition</li>
          <li>
            No other team member should register themselves as the Team Leader
          </li>
        </div>
        <div className="ne_main">
          <div className="tc_left">
            <p
              className="labe_tem"
              style={
                window.innerWidth > 770
                  ? { fontSize: "2vw", margin: "0.5vh 0" }
                  : { fontSize: "5vw" }
              }
            >
              Add your team members
            </p>
            <p>
              Team limit: <span>{competition.maxParticipants}</span>
            </p>
            <div className="members_form">
              <label
                htmlFor="mi_no"
                style={{ color: "#60BDBA", fontWeight: "500" }}
              >
                Add New Members:
              </label>
              <input
                type="text"
                name="mi_no"
                id="mi_no"
                value={member.mi_no}
                onChange={onChange}
                placeholder="Enter the MI Number of the member"
                maxLength={11}
              />
              {submitDisabled ? (
                <button className="member_add_btn" disabled>
                  Adding...
                </button>
              ) : (
                <button
                  className="member_add_btn"
                  onClick={onMemberAddClick}
                  style={{ background: "#A14225" }}
                >
                  Add
                </button>
              )}
            </div>
            <div>
              <li style={{ color: "#60BDBA", marginTop: "1vh" }}>
                You need a team of atleast {competition.minParticipants} people
                to participate in this competition
              </li>
            </div>
          </div>
          <div className="tc_right">
            <div className="tc_right_title">Team Members</div>
            <div className="member_details">
              <p>{user.userName}</p>
              {user ? <p>(Leader)</p> : <></>}
            </div>
            {teamDetails.map((element) => {
              return (
                <TeamMemberDetails
                  member={element}
                  teamDetails={teamDetails}
                  setTeamDetails={setTeamDetails}
                  team={team}
                />
              );
            })}
            {newSubmitDisabled ? (
              <button
                className="tc_submit_btn"
                style={{ background: "#A14225" }}
                disabled
              >
                Submiting...
              </button>
            ) : (
              <button
                className="tc_submit_btn"
                style={{ background: "#A14225" }}
                onClick={onSubmitClick}
              >
                Submit
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TeamCompiReg;
