import React from "react";
import Multicityhome from "./multicityComponents/MulticityHome";
import "./css/multicityHome.css"

const IndexMulticity = () => {
 
  return (
    <div>
      <Multicityhome />
    </div>
  );
};

export default IndexMulticity;
