import React, { useEffect, useState, useRef } from "react";
import mul_cities from "../../../../assets/constants/mul_cities";
import { useNavigate } from "react-router-dom";
import RollingAnimation from "../../Home/HomeComponents/RollingAnimation";
import gsap from "gsap";
import {
  FaInstagram,
  FaLinkedin,
  FaFacebook,
  FaTwitter,
  FaWhatsapp,
  FaYoutube,
} from "react-icons/fa6";
import CityInfo from "./CityInfo";
import { motion } from "framer-motion";
import ObjHome from "../../../../assets/images/multicity/Group.svg";
import LargeHome from "../../../../assets/images/multicity/center.svg";
import TestComputer from "../../../../assets/images/multicity/keyboard.svg";
import Replace from "../../../../assets/images/multicity/replace.png";
import Trophy from "../../../../assets/images/multicity/trophy.svg";

import Bengup from "../../../../assets/images/multicity/mulBackground/bengaluru_up.svg";
import Bengdown from "../../../../assets/images/multicity/mulBackground/bengaluru_down.svg";
import Delhiup from "../../../../assets/images/multicity/mulBackground/delhi_up.svg";
import Delhidown from "../../../../assets/images/multicity/mulBackground/delhi_down.svg";
import BhaglapurUp from "../../../../assets/images/multicity/mulBackground/bhag_up.svg";
import BhaglapurDown from "../../../../assets/images/multicity/mulBackground/bhag_down.svg";
import BhilaUp from "../../../../assets/images/multicity/mulBackground/bhil_up.svg";
import BhilaDown from "../../../../assets/images/multicity/mulBackground/bhil_down.svg";
import ChandiUp from "../../../../assets/images/multicity/mulBackground/chandi_up.svg";
import ChandiDown from "../../../../assets/images/multicity/mulBackground/chandi_down.svg";
import Gwaliorup from "../../../../assets/images/multicity/mulBackground/gwa_up.svg";
import Gwaliordown from "../../../../assets/images/multicity/mulBackground/gwa_down.svg";
import HydUp from "../../../../assets/images/multicity/mulBackground/hyd_up.svg";
import HyDown from "../../../../assets/images/multicity/mulBackground/hyd_down.svg";
import Jaipurup from "../../../../assets/images/multicity/mulBackground/Jaipur_up.svg";
import Jaipurdown from "../../../../assets/images/multicity/mulBackground/Jaipur_down.svg";
import KurUp from "../../../../assets/images/multicity/mulBackground/kur_up.svg";
import Kurdown from "../../../../assets/images/multicity/mulBackground/kur_down.svg";
import MumUp from "../../../../assets/images/multicity/mulBackground/mum_up.svg";
import MumDown from "../../../../assets/images/multicity/mulBackground/mum_down.svg";
import NagUp from "../../../../assets/images/multicity/mulBackground/nag_up.svg";
import NagDown from "../../../../assets/images/multicity/mulBackground/nag_down.svg";
import PudUp from "../../../../assets/images/multicity/mulBackground/pud_up.svg";
import Pudown from "../../../../assets/images/multicity/mulBackground/pud_down.svg";
import PuneUp from "../../../../assets/images/multicity/mulBackground/pune_up.svg";
import PuneDown from "../../../../assets/images/multicity/mulBackground/pune_down.svg";
import RoparUp from "../../../../assets/images/multicity/mulBackground/ropar_up.svg";
import Ropardown from "../../../../assets/images/multicity/mulBackground/ropar_down.svg";
import ThriUp from "../../../../assets/images/multicity/mulBackground/thri_up.svg";
import ThriDown from "../../../../assets/images/multicity/mulBackground/thri_down.svg";

import MainImage from "../../../../assets/images/multicity/multicityComputer/website/mainPng.png";
import ImageOne from "../../../../assets/images/multicity/multicityComputer/website/one.jpg";
import ImageTwo from "../../../../assets/images/multicity/multicityComputer/website/tow.png";
import ImageThree from "../../../../assets/images/multicity/multicityComputer/website/unnamed.png";
import ImageFour from "../../../../assets/images/multicity/multicityComputer/website/multicity1.png";
import ImageFive from "../../../../assets/images/multicity/multicityComputer/website/rand.jpg";
import ImageSix from "../../../../assets/images/multicity/multicityComputer/website/randone.jpg";
import ImageSeven from "../../../../assets/images/multicity/multicityComputer/website/randthree.jpg";

import Krishna from "../../../../assets/images/multicity/team_Info/krishna.jpg";
import Leena from "../../../../assets/images/multicity/team_Info/leena.jpg";

const Multicityhome = () => {
  const navigate = useNavigate();
  const cityCardRef = useRef(null);
  // const typedElement5 = useRef(null);

  // useEffect(() => {
  //   const text = {
  //     // strings: ["COMING TO YOUR CITY!"],
  //     strings: ["MULTICITIES"],
  //     typeSpeed: 200,
  //     backSpeed: 100,
  //     backDelay: 1000,
  //     // loop: true,
  //   };
  //   const typed1 = new Typed(typedElement5.current, text);

  //   return () => {
  //     typed1.destroy();
  //   };
  // }, []);

  const text = [" MOOD INDIGO MOOD INDIGO"];
  const customStyle = {
    fontSize: "7vw",
    color: "rgba(255, 255, 255, 0.42)",
    fontWeight: "700",
    fontFamily: "lemonMilk",
    lineHeight: "1.18",
  };

  const scroll = (direction) => {
    const scrollAmount = 300; // Adjust the scroll amount as needed
    if (cityCardRef.current) {
      cityCardRef.current.scrollBy({
        left: direction * scrollAmount,
        behavior: "smooth",
      });
    }
  };

  const containerRef = useRef(null);

  const images = [
    MainImage,
    ImageOne,
    ImageTwo,
    ImageThree,
    ImageFour,
    ImageFive,
    ImageSix,
    ImageSeven,
  ];

  const totalLoops = 4;

  useEffect(() => {
    const images = containerRef.current.children;
    const totalImages = images.length / totalLoops;

    const animateSlides = () => {
      gsap.to(images, {
        xPercent: -100 * totalImages,
        duration: 30,
        ease: "none",
        delay: 3,
        repeat: -1,
        modifiers: {
          xPercent: gsap.utils.wrap(-100 * totalImages, 0),
        },
      });
    };

    animateSlides();

    return () => {
      gsap.killTweensOf(images);
    };
  }, []);

  return (
    <div className="multicity_home_main">
      <div className="replace">
        <img className="replace_img" src={Replace} alt="" />

        <div className="computer_animation" ref={containerRef}>
          {Array.from({ length: totalLoops }).map((_, loopIndex) =>
            images.map((src, index) => (
              // eslint-disable-next-line jsx-a11y/img-redundant-alt
              <img
                key={`${loopIndex}-${index}`}
                className="com_animation"
                src={src}
                alt={`image${index + 1}`}
              />
            ))
          )}
        </div>
        <img className="keyboard" src={TestComputer} alt="test" />
      </div>
      <img className="trophy" src={Trophy} alt="" />
      <div className="mul_cham">
        <div className="mul_cham_ind">
          <div className="mul_cham_hed_main">
            <p className="mul_cham_heading">Multicity Championships</p>
          </div>
          <div className="mul_he_sec">
            <p className="mul_cham_head_a">
              Multicity championships are your chance to get your college at the
              top!
            </p>
            <div className="mul_he_sec_a">
              <p className="mul_cham_head_b">
                The top 3 participants of each competition will be allotted a
                certain number of points and at the end of all the competitions,
                the college with the maximum number of points becomes the
                champion of that Multicity!
              </p>
            </div>
          </div>
        </div>
      </div>

      <p className="team_info_head mul_head" style={{ textAlign: "center" }}>
        CITIES
      </p>
      <img className="mul_center" src={LargeHome} alt="" />
      <div className="city_card_container">
        <div className="arrow left-arrow" onClick={() => scroll(-1)}>
          &#10094;
        </div>
        <div className="city_card" ref={cityCardRef}>
          {mul_cities.slice(0, 1).map((city) => {
            return (
              <motion.div
                whileHover={{ scale: 1.1 }}
                className="city_info_grp"
                style={{ background: city.cityColor }}
                onClick={() => {
                  navigate(`/multicities/cityinfo/${city.cityName}`);
                }}
              >
                <img className="city_down" src={Bengup} alt="bengup" />
                <CityInfo
                  cardNo={1}
                  cityName={city.cityName}
                  date={city.cityDate}
                  cityUp={city.cityUp}
                />
                <img className="city_down" src={Bengdown} alt="bengdown" />
              </motion.div>
            );
          })}
          <div className="city_card_2">
            {mul_cities.slice(1, 3).map((city) => {
              const cityUpImage =
                city.cityName === "Delhi" ? Delhidown : Jaipurup; // Replace `Delhiup` with the correct import or path for the Delhi image
              const cityDownImage =
                city.cityName === "Delhi" ? Delhiup : Jaipurdown;
              const cityBackgroundColor =
                city.cityName === "Delhi" ? city.cityColor : city.cityColor;

              return (
                <motion.div
                  whileHover={{ scale: 1.1 }}
                  className="city_info_grp next_city"
                  style={{ background: cityBackgroundColor }}
                  onClick={() => {
                    navigate(`/multicities/cityinfo/${city.cityName}`);
                  }}
                >
                  <img className="city_down" src={cityUpImage} alt="bengup" />
                  <CityInfo
                    cardNo={2}
                    cityName={city.cityName}
                    date={city.cityDate}
                    cityUp={city.cityUp}
                  />
                  <img
                    className="city_down"
                    src={cityDownImage}
                    alt="bengdown"
                  />
                </motion.div>
              );
            })}
          </div>
          {mul_cities.slice(3, 4).map((city) => {
            return (
              <motion.div
                whileHover={{ scale: 1.1 }}
                className="city_info_grp next_city"
                style={{ background: city.cityColor }}
                onClick={() => {
                  navigate(`/multicities/cityinfo/${city.cityName}`);
                }}
              >
                <img className="city_down" src={PuneUp} alt="bengup" />
                <CityInfo
                  cardNo={1}
                  cityName={city.cityName}
                  date={city.cityDate}
                  cityUp={city.cityUp}
                />
                <img className="city_down" src={PuneDown} alt="bengdown" />
              </motion.div>
            );
          })}
          <div className="city_card_2">
            {mul_cities.slice(4, 6).map((city) => {
              const cityUpImage =
                city.cityName === "Mumbai" ? MumUp : Gwaliorup; // Replace `Delhiup` with the correct import or path for the Delhi image
              const cityDownImage =
                city.cityName === "Mumbai" ? MumDown : Gwaliordown;
              const cityBackgroundColor =
                city.cityName === "Mumbai" ? city.cityColor : city.cityColor;
              return (
                <motion.div
                  whileHover={{ scale: 1.1 }}
                  className="city_info_grp next_city"
                  style={{ background: cityBackgroundColor }}
                  onClick={() => {
                    navigate(`/multicities/cityinfo/${city.cityName}`);
                  }}
                >
                  <img className="city_down" src={cityUpImage} alt="bengup" />
                  <CityInfo
                    cardNo={2}
                    cityName={city.cityName}
                    date={city.cityDate}
                    cityUp={city.cityUp}
                  />
                  <img
                    className="city_down"
                    src={cityDownImage}
                    alt="bengdown"
                  />
                </motion.div>
              );
            })}
          </div>
          {mul_cities.slice(6, 7).map((city) => {
            return (
              <motion.div
                whileHover={{ scale: 1.1 }}
                className="city_info_grp next_city"
                style={{ background: city.cityColor }}
                onClick={() => {
                  navigate(`/multicities/cityinfo/${city.cityName}`);
                }}
              >
                <img className="city_down" src={ThriUp} alt="bengup" />
                <CityInfo
                  cardNo={1}
                  cityName={city.cityName}
                  date={city.cityDate}
                  cityUp={city.cityUp}
                />
                <img className="city_down" src={ThriDown} alt="bengdown" />
              </motion.div>
            );
          })}
          <div className="city_card_2">
            {mul_cities.slice(7, 9).map((city) => {
              const cityUpImage = city.cityName === "Hyderabad" ? HydUp : KurUp; // Replace `Delhiup` with the correct import or path for the Delhi image
              const cityDownImage =
                city.cityName === "Hyderabad" ? HyDown : Kurdown;
              const cityBackgroundColor =
                city.cityName === "Hyderabad" ? city.cityColor : city.cityColor;
              return (
                <motion.div
                  whileHover={{ scale: 1.1 }}
                  className="city_info_grp next_city"
                  style={{ background: cityBackgroundColor }}
                  onClick={() => {
                    navigate(`/multicities/cityinfo/${city.cityName}`);
                  }}
                >
                  <img className="city_down" src={cityUpImage} alt="bengup" />
                  <CityInfo
                    cardNo={2}
                    cityName={city.cityName}
                    date={city.cityDate}
                    cityUp={city.cityUp}
                  />
                  <img
                    className="city_down"
                    src={cityDownImage}
                    alt="bengdown"
                  />
                </motion.div>
              );
            })}
          </div>
          {mul_cities.slice(9, 10).map((city) => {
            return (
              <motion.div
                whileHover={{ scale: 1.1 }}
                className="city_info_grp next_city"
                style={{ background: city.cityColor }}
                onClick={() => {
                  navigate(`/multicities/cityinfo/${city.cityName}`);
                }}
              >
                <img className="city_down" src={NagUp} alt="bengup" />
                <CityInfo
                  cardNo={2}
                  cityName={city.cityName}
                  date={city.cityDate}
                  cityUp={city.cityUp}
                />
                <img className="city_down" src={NagDown} alt="bengdown" />
              </motion.div>
            );
          })}
          <div className="city_card_2">
            {mul_cities.slice(10, 12).map((city) => {
              const cityUpImage =
                city.cityName === "Chandigarh" ? ChandiUp : BhilaUp; // Replace `Delhiup` with the correct import or path for the Delhi image
              const cityDownImage =
                city.cityName === "Chandigarh" ? ChandiDown : BhilaDown;
              const cityBackgroundColor =
                city.cityName === "Chandigarh"
                  ? "linear-gradient(0deg, rgba(240, 212, 217, 1) -38.23%, rgba(207, 17, 50, 1) 140.76%)" // RGBA gradient for Delhi
                  : city.cityColor;
              return (
                <motion.div
                  whileHover={{ scale: 1.1 }}
                  className="city_info_grp next_city"
                  style={{ background: city.cityColor }}
                  onClick={() => {
                    navigate(`/multicities/cityinfo/${city.cityName}`);
                  }}
                >
                  <img className="city_down" src={cityUpImage} alt="bengup" />
                  <CityInfo
                    cardNo={2}
                    cityName={city.cityName}
                    date={city.cityDate}
                    cityUp={city.cityUp}
                  />
                  <img
                    className="city_down"
                    src={cityDownImage}
                    alt="bengdown"
                  />
                </motion.div>
              );
            })}
          </div>
          {mul_cities.slice(12, 13).map((city) => {
            return (
              <motion.div
                whileHover={{ scale: 1.1 }}
                className="city_info_grp next_city"
                style={{ background: city.cityColor }}
                onClick={() => {
                  navigate(`/multicities/cityinfo/${city.cityName}`);
                }}
              >
                <img className="city_down" src={RoparUp} alt="bengup" />
                <CityInfo
                  cardNo={2}
                  cityName={city.cityName}
                  date={city.cityDate}
                  cityUp={city.cityUp}
                />
                <img className="city_down" src={Ropardown} alt="bengdown" />
              </motion.div>
            );
          })}
          <div className="city_card_2">
            {mul_cities.slice(13, 15).map((city) => {
              const cityUpImage =
                city.cityName === "Puducherry" ? PudUp : BhaglapurUp; // Replace `Delhiup` with the correct import or path for the Delhi image
              const cityDownImage =
                city.cityName === "Puducherry" ? Pudown : BhaglapurDown;
              const cityBackgroundColor =
                city.cityName === "Puducherry"
                  ? city.cityColor
                  : city.cityColor;
              return (
                <motion.div
                  whileHover={{ scale: 1.1 }}
                  className="city_info_grp next_city"
                  style={{ background: cityBackgroundColor }}
                  onClick={() => {
                    navigate(`/multicities/cityinfo/${city.cityName}`);
                  }}
                >
                  <img className="city_down" src={cityUpImage} alt="bengup" />
                  <CityInfo
                    cardNo={2}
                    cityName={city.cityName}
                    date={city.cityDate}
                    cityUp={city.cityUp}
                  />
                  <img
                    className="city_down"
                    src={cityDownImage}
                    alt="bengdown"
                  />
                </motion.div>
              );
            })}
          </div>
        </div>
        <div className="arrow right-arrow" onClick={() => scroll(1)}>
          &#10095;
        </div>
      </div>

      <img className="faq_bac" src={ObjHome} alt="" />
      <img className="faq_bac ext_faq_bac" src={ObjHome} alt="" />
      <img className="faq_bac_1" src={ObjHome} alt="" />
      <img className="faq_bac_2" src={ObjHome} alt="" />
      <div className="faq_sec">
        <div className="faq_sec_a" style={{ textAlign: "center" }}>
          <p className="faq_sec_heading">FREQUENTLY ASKED QUESTIONS</p>
          {/* <img src={Arrow} alt="arrow" /> */}
        </div>
      </div>
      <div className="faq_que_main">
        <div className="faq_que">
          <p>
            Q.{" "}
            <span>
              If a team/individual participant did not qualify in the
              multicities, can they still take part in the main fest?
            </span>
          </p>
          <p>
            Ans.{" "}
            <span>
              Yes, participants who did not qualify through multicity
              eliminations can take part in the All India Eliminations which
              takes place in the main festival.
            </span>
          </p>
        </div>
        <div className="faq_que">
          <p>
            Q.{" "}
            <span>
              How many teams from a college can participate in the multicity?
            </span>
          </p>
          <p>
            Ans.{" "}
            <span>
              Any number of teams from a college can participate, in fact, we
              welcome maximum participation from your end. The only thing to be
              kept in mind is that one person must be present in only a single
              team from the college.
            </span>
          </p>
        </div>
        <div className="faq_que">
          <p>
            Q.{" "}
            <span>
              What is the incentive for the winner(s) of the multicity
              eliminations round?
            </span>
          </p>
          <p>
            Ans.{" "}
            <span>
              The winner(s) of the multicity eliminations qualify for the higher
              rounds of the competitions that take place in the main fest.
            </span>
          </p>
        </div>
        <div className="faq_que">
          <p>
            Q. <span>What is Multicity Championship?</span>
          </p>
          <p>
            Ans.{" "}
            <span>
              Points are allotted to the top 3 winners of each competition,
              which is in turn added to the college points. At the end of the
              multicity eliminations, the college with the maximum number of
              points is crowned the Multicity Champion.
            </span>
          </p>
        </div>
      </div>
      <div className="team_info">
        <p className="team_info_head">COMPETITIONS TEAM</p>
        <div className="cg_detail_mul">
          <div className="mul_cg_de_main">
            <div className="img">
              <img src={Krishna} alt="krishna" />
            </div>
            <div className="mul_cg_de">
              <p className="mul_cg_name">Krishna Chaudhary</p>
              <p className="mul_cg_depa">Competitions and LYP Head</p>
              <a href="tel:+917066615145" className="cg_phone_mul">
                +91 70666 15145
              </a>
              <div className="cg_mul_cont">
                <a
                  href="https://www.instagram.com/krishna._142/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FaInstagram
                    color="black"
                    size={window.innerWidth > 770 ? 30 : 20}
                  />
                </a>
                <a
                  href="https://www.linkedin.com/authwall?trkInfo=AQHbNQ2blrifzgAAAZFmfchIZ1Puzs-om96bQYwuyjWkGveCNnaZ_j8DPYlp-2n1-j39dwxwWgU8tWXudTfJhj9et8lQWSH1tIEaJ3yVK5Pqu655yTVLDk6tURJeBV7pVEHVrAc=&original_referer=&sessionRedirect=https%3A%2F%2Fwww.linkedin.com%2Fin%2Fkrishna-chaudhary-4862a9256%2F"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FaLinkedin
                    color="black"
                    size={window.innerWidth > 770 ? 30 : 20}
                  />
                </a>
                <a
                  href="https://wa.me/7066615145"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FaWhatsapp
                    color="black"
                    size={window.innerWidth > 770 ? 30 : 20}
                  />
                </a>
              </div>
            </div>
          </div>
          <div className="mul_cg_de_main ext_leena">
            <div className="img">
              <img src={Leena} alt="krishna" />
            </div>
            <div className="mul_cg_de ">
              <p className="mul_cg_name">Leena Jagwani</p>
              <p className="mul_cg_depa">Competitions and LYP Head</p>
              <a href="tel:+917066615145" className="cg_phone_mul">
                +91 93993 59503
              </a>
              <div className="cg_mul_cont">
                <a
                  href="https://www.instagram.com/leena_jagwani/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FaInstagram
                    color="black"
                    size={window.innerWidth > 770 ? 30 : 20}
                  />
                </a>
                <a
                  href="https://www.linkedin.com/authwall?trkInfo=AQGT60rkxHepLgAAAZFmnJMYMVMoPsxctyqfu4_XKTjdlSh_Z3fyKIGUWYc2gJ0dlXFv0wNvwo1y7tfveGyunp3wXVyi-PCCXjbwcG66SphbMvIS86Usfa88sRlEqpo4_iFROOs=&original_referer=&sessionRedirect=https%3A%2F%2Fwww.linkedin.com%2Fin%2Fleena-jagwani-3b3907257%3Futm_source%3Dshare%26utm_campaign%3Dshare_via%26utm_content%3Dprofile%26utm_medium%3Dandroid_app"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FaLinkedin
                    color="black"
                    size={window.innerWidth > 770 ? 30 : 20}
                  />
                </a>
                <a
                  href="https://wa.me/9399359503"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FaWhatsapp
                    color="black"
                    size={window.innerWidth > 770 ? 30 : 20}
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="footer_mul_b">
        <div className="footer_text_animation">
          <RollingAnimation
            style={customStyle}
            text={text}
            direction={"left"}
          />
        </div>
        <div
          className="footer_details mul_footer_details"
          style={{
            background:
              "linear-gradient(318deg, #022171 -123.76%, #02646A 141.32%)",
          }}
        >
          <p>
            Mood Indigo Office, Student Activity Center (SAC), IIT Bombay,
            Powai, Mumbai, Maharashtra - 400076
          </p>
          <div className="footer_icon">
            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.instagram.com/iitbombay.moodi?igsh=MTNmam9pY3Z1YW93aQ=="
            >
              <FaInstagram color="white" size={30} />
            </a>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.linkedin.com/company/mood-indigo/"
            >
              <FaLinkedin color="white" size={30} />
            </a>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://x.com/iitb_moodi?t=tyaVKeWlXXTcc9qIerNTkQ&s=08"
            >
              <FaTwitter color="white" size={30} />
            </a>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.facebook.com/iitbombay.moodi?mibextid=ZbWKwL"
            >
              <FaFacebook color="white" size={30} />
            </a>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.youtube.com/@iitbmoodindigo"
            >
              <FaYoutube color="white" size={30} />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Multicityhome;
