import React from "react";
import { motion } from "framer-motion";
import { useNavigate } from "react-router-dom";

const CityInfo = ({ cardNo, bg, cityName, date, cityUp, cityDown, cityColor }) => {
  console.log(cityName, cityUp, cityDown);
  const navigate = useNavigate();
  return (
    <motion.div
      whileHover={{ scale: 1.1 }}
      className="width_pro"
      onClick={() => {
        navigate(`/multicities/cityinfo/${cityName}`);
      }}
    >
    
      <div className="city_info">
        <p style={{color: cityColor}} className="cityName_1">{cityName.toUpperCase()}</p>
        <p className="cityDate_1">{date}</p>
      </div>
    </motion.div>
  );
};

export default CityInfo;
