import React, { useState, useEffect } from "react";
import Axios from "axios";
import avatar from "../../../assets/images/ccp/avatar/one.svg";
import ccppoint from "../../../assets/images/ccp/dashboard/ccppoint.svg";

import levelone from "../../../assets/images/ccp/badges/level1.svg";
import leveltwo from "../../../assets/images/ccp/badges/level2.svg";
import levelthree from "../../../assets/images/ccp/badges/level3.svg";
import levelfour from "../../../assets/images/ccp/badges/level4.svg";
import levelfive from "../../../assets/images/ccp/badges/level5.svg";
import levelsix from "../../../assets/images/ccp/badges/level6.svg";
import levelseven from "../../../assets/images/ccp/badges/level7.svg";
import closeIcon from "../../../assets/images/ccp/close.svg";
import insta from "../../../assets/images/ccp/instagram-black.svg";
import facebook from "../../../assets/images/ccp/fb-black.svg";
import LinkedIn from "../../../assets/images/ccp/linked.png";
import twitter from "../../../assets/images/ccp/twitter-black.svg";

import menuIcon from "../../../assets/images/ccp/navbar/menu.svg";
import closebar from "../../../assets/images/ccp/navbar/cross.svg";
import first from "../../../assets/images/ccp/navbar/first.svg";
import second from "../../../assets/images/ccp/navbar/second.svg";
import third from "../../../assets/images/ccp/navbar/third.svg";
import fourth from "../../../assets/images/ccp/navbar/fourth.svg";
import five from "../../../assets/images/ccp/navbar/five.svg";
import six from "../../../assets/images/ccp/navbar/six.svg";

const DashboardLeft = ({ currentPage, setCurrentPage, user }) => {
  const [oal, setOal] = useState([]);

  const [badge, setBadge] = useState(levelone);
  const [badgeText, setBadgeText] = useState("Badge");
  const [isVisible, setIsVisible] = useState(false);
  const [collegeName, setCollegeName] = useState("");
  const [state, setStateName] = useState("");
  const [collegePosition, setCollegePosition] = useState(null);
  const [cityName, setCityName] = useState("");
  const [error, setError] = useState("");
  const [disabled, setDisabled] = useState(false);
  const [updateUser, setUpdateUser] = useState({
    insta: "",
    facebook: "",
    twitter: "",
    linkedIn: "",
  });
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const fetchCollegeData = () => {
    Axios.get(`https://ashish.moodi.org/ccp/cl`)
      .then((res) => {
        if (res.data && Array.isArray(res.data)) {
          const collegeData = res.data.find(
            ({ collegeId }) => collegeId === user.collegeId
          );

          if (collegeData) {
            const position = res.data.indexOf(collegeData) + 1; // Position +1 for 1-based index
            setCollegeName(collegeData.collegeName);
            setCollegePosition(position);
          } else {
            setCollegeName("College not found");
            setCollegePosition(null);
          }
        } else {
          setCollegeName("No data available");
          setCollegePosition(null);
        }
      })
      .catch((err) => {
        setCollegeName("Error fetching data");
        setCollegePosition(null);
      });
  };

  const string = user.collegeId;

  const str = "https://ashish.moodi.org/users/college/city?collegeId=" + string;

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const fetchCityName = () => {
    if (!user.collegeId) {
      setError("College ID is required.");
      return;
    }

    Axios.get(str)
      .then((res) => {
        if (res.data.cityName) {
          setCityName(res.data.cityName);
          setError("");
          setError("City name not found.");
        }
        if (res.data.state) {
          setStateName(res.data.state);
          setError("");
          setError("City name not found.");
        }
      })
      .catch((err) => {
        if (err.response) {
          setError(
            "Failed to fetch city name: " +
              (err.response.data.error || "Unknown error")
          );
        } else if (err.request) {
          setError("No response from server.");
        } else {
          setError("Request error: " + err.message);
        }
      });
  };

  const handleVisble = () => {
    setIsVisible(true);
  };
  const handleClosing = () => {
    setIsVisible(false);
  };
  const fetchOverallLeaderboard = () => {
    Axios.get("https://ashish.moodi.org/ccp/oal")
      .then((res) => setOal(res.data))
      .catch((err) => console.error(err));
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const updateBadge = () => {
    const rank = oal.findIndex((element) => element.userId === user.userId) + 1;

    if (rank === 0) {
      setBadge(levelseven);
      setBadgeText("Indigo Beginner");
    } else if (rank >= 1 && rank <= 3) {
      setBadge(levelone);
      setBadgeText("Indigo Champion");
    } else if (rank >= 4 && rank <= 10) {
      setBadge(leveltwo);
      setBadgeText("Indigo Elite");
    } else if (rank >= 11 && rank <= 100) {
      setBadge(levelthree);
      setBadgeText("Indigo Vanguard");
    } else if (rank >= 101 && rank <= 200) {
      setBadge(levelfour);
      setBadgeText("Indigo Catalyst");
    } else if (rank >= 201 && rank <= 500) {
      setBadge(levelfive);
      setBadgeText("Indigo Phoenix");
    } else if (rank >= 501 && rank <= 1000) {
      setBadge(levelsix);
      setBadgeText("Indigo Premier");
    } else {
      setBadge(levelseven);
      setBadgeText("Indigo Beginner");
    }
  };

  const onSubmitClick = () => {
    setDisabled(true);
    Axios.post(
      "https://ashish.moodi.org/users/update_user_profile",
      {
        insta: updateUser.insta,
        facebook: updateUser.facebook,
        twitter: updateUser.twitter,
        linkedIn: updateUser.linkedIn,
        user: user.userId,
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
      .then((res) => {
        if (res.data.status === "done") {
          window.alert("Profile updated successfully!");
          window.location.reload();
        }
      })
      .catch((err) => console.error(err));
  };

  const onChange = (e) => {
    setUpdateUser({ ...updateUser, [e.target.name]: e.target.value });
  };

  const check = () => {
    if (
      updateUser.insta !== "" ||
      updateUser.facebook !== "" ||
      updateUser.linkedIn !== "" ||
      updateUser.twitter !== ""
    ) {
      if (
        updateUser.insta === user.insta &&
        updateUser.facebook === user.facebook &&
        updateUser.linkedIn === user.linkedIn &&
        updateUser.twitter === user.twitter
      ) {
        return true;
      } else {
        return false;
      }
    } else {
      return true;
    }
  };

  useEffect(() => {
    fetchCollegeData();
    fetchCityName();
    updateBadge();
    fetchOverallLeaderboard();
    setTimeout(updateBadge, 2000);
  }, [fetchCityName, fetchCollegeData, updateBadge, user]);

  return (
    <>
      <div className="dashboard_left">
        <div className="user_profile">
          <div className="usr_prf">
            <img src={avatar} alt="useravatar" />
            <div className="user_detail">
              <p>{user.userName}</p>
              <p>{user.MI_NO}</p>
            </div>
          </div>
          <div className="usr_prf">
            <img src={ccppoint} alt="" />
            <div className="user_detail">
              <p>CCP POINTS</p>
              <p>{user.ccpPoints}</p>
            </div>
          </div>
          <div className="usr_prf">
            <img src={badge} alt="" />
            <div className="det">
              <p style={{ marginTop: "2vh", fontWeight: "700" }}>{badgeText}</p>
            </div>
          </div>
          <div className="link" onClick={handleVisble}>
            Update Your Profile
          </div>
          {isVisible && (
            <div
              className="questions"
              style={{
                overflow: "hidden",
                background: "rgba(246, 245, 242, 0.4)",
                height: "52vh",
              }}
            >
              <div className="thread_form_header">
                <p className="guideline_heading">Update Profile</p>
                <img src={closeIcon} onClick={handleClosing} alt="close" />
              </div>
              <div className="thread_main">
                <div className="profile_top">
                  <div className="profile_image">
                    <img src={`${user.profilePic}`} alt="" />
                  </div>
                  <div className="profile_top_a">
                    <div className="profile_name">{user.userName}</div>
                    <div className="profile_mino">{user.MI_NO}</div>
                    <div className="profile_mino">
                      Refer Code - {user.referralCode}
                    </div>
                  </div>
                </div>
                <div className="pi_row">
                  <div className="profile_info">
                    <div className="pi_row">
                      <span>College: </span>
                      <span>{collegeName}</span>
                    </div>
                    <div className="pi_row">
                      <span>City: </span>
                      <span>{cityName}</span>
                    </div>
                    <div className="pi_row">
                      <span>State: </span>
                      <span>{state}</span>
                    </div>
                    <div className="pi_row">
                      <span>Contact Number:</span>
                      <span>{user.phone}</span>
                    </div>
                    <div className="pi_row">
                      <span>Email ID:</span>
                      <span>{user.email}</span>
                    </div>
                  </div>
                  <div className="profiel_social">
                    <div
                      className="pi_row"
                      style={{
                        display: "flex",
                        flexDirection: "row",
                      }}
                    >
                      <label htmlFor="insta">
                        <img src={insta} alt="" />
                      </label>
                      <input
                        type="text"
                        name="insta"
                        id="insta"
                        placeholder={
                          user.insta !== ""
                            ? user.insta
                            : "Instagram profile link"
                        }
                        onChange={onChange}
                        value={updateUser.insta}
                      />
                    </div>
                    <div
                      className="pi_row"
                      style={{
                        display: "flex",
                        flexDirection: "row",
                      }}
                    >
                      <label htmlFor="facebook">
                        <img src={facebook} alt="" />
                      </label>
                      <input
                        type="text"
                        name="facebook"
                        id="facebook"
                        placeholder={
                          user.facebook !== ""
                            ? user.facebook
                            : "Facebook profile link"
                        }
                        onChange={onChange}
                        value={updateUser.facebook}
                      />
                    </div>
                    <div
                      className="pi_row"
                      style={{
                        display: "flex",
                        flexDirection: "row",
                      }}
                    >
                      <label htmlFor="twitter">
                        <img src={twitter} alt="" />
                      </label>
                      <input
                        type="text"
                        name="twitter"
                        id="twitter"
                        placeholder={
                          user.twitter !== ""
                            ? user.twitter
                            : "Twitter profile link"
                        }
                        onChange={onChange}
                        value={updateUser.twitter}
                      />
                    </div>
                    <div
                      className="pi_row"
                      style={{
                        display: "flex",
                        flexDirection: "row",
                      }}
                    >
                      <label htmlFor="linkedIn">
                        <img src={LinkedIn} alt="" />
                      </label>
                      <input
                        type="text"
                        name="linkedIn"
                        id="linkedIn"
                        placeholder={
                          user.linkedIn !== ""
                            ? user.linkedIn
                            : "LinkedIn profile link"
                        }
                        onChange={onChange}
                        value={updateUser.linkedIn}
                      />
                    </div>
                  </div>
                </div>
                <button
                  className="tc_submit_btn"
                  onClick={onSubmitClick}
                  disabled={check() && disabled}
                >
                  Submit
                </button>
              </div>
            </div>
          )}
        </div>
        <div className="left_nav">
          <div
            style={currentPage === "mymi" ? { backgroundColor: "#FABCD5" } : {}}
            className="nav_element center"
            onClick={() => setCurrentPage("mymi")}
          >
            My MoodI
          </div>
          <div
            style={
              currentPage === "earnpoints" ? { backgroundColor: "#71C285" } : {}
            }
            className="nav_element center"
            onClick={() => setCurrentPage("earnpoints")}
          >
            Points & Progress
          </div>
          <div
            style={
              currentPage === "threads" ? { backgroundColor: "#FBE400" } : {}
            }
            className="nav_element center"
            onClick={() => setCurrentPage("threads")}
          >
            Add Threads
          </div>
          <div
            style={
              currentPage === "leaderboards"
                ? { backgroundColor: "#BCC2F4" }
                : {}
            }
            className="nav_element center"
            onClick={() => setCurrentPage("leaderboards")}
          >
            Leaderboard
          </div>
          <div
            style={
              currentPage === "about" ? { backgroundColor: "#EC5B40" } : {}
            }
            className="abtus nav_element center"
            onClick={() => setCurrentPage("about")}
          >
            About Us
          </div>
          <div
            style={currentPage === "faqs" ? { backgroundColor: "#EC5B40" } : {}}
            className="abtus nav_element center"
            onClick={() => setCurrentPage("faqs")}
          >
            FAQs
          </div>
        </div>
      </div>
      <div className="dashboard_mob">
        <div className="menu-icon" onClick={toggleMenu}>
          <img src={menuOpen ? closebar : menuIcon} alt="Menu Toggle" />
        </div>
        {menuOpen && (
          <div className="side-nav open">
            <div className="us_mif_a">
              <div className="us_de">
                <p>{user.userName}</p>
                <p className="us_p">{user.MI_NO}</p>
              </div>
              <div className="us_de extra_us_de">
                <p>CCP POINTS</p>
                <p className="us_p">{user.ccpPoints}</p>
              </div>
              <div className="us_de_a extra_us_de">
                <p>{badgeText}</p>
              </div>
            </div>
            <div className="us_de_up" onClick={handleVisble}>
              <p>Update Profile</p>
            </div>
            {isVisible && (
              <div
                className="questions ext_mob_sr"
                style={{
                  overflow: "scroll",
                  background: "rgba(246, 245, 242, 0.4)",
                  color: "black",
                  zIndex: "10",
                }}
              >
                <div className="thread_form_header">
                  <p className="guideline_heading">Update Profile</p>
                  <img src={closeIcon} onClick={handleClosing} alt="close" />
                </div>
                <div className="thread_main">
                  <div className="profile_top">
                    <div className="profile_image">
                      <img src={`${user.profilePic}`} alt="" />
                    </div>
                    <div className="profile_top_a">
                      <div className="profile_name">{user.userName}</div>
                      <div className="profile_mino">{user.MI_NO}</div>
                      <div className="profile_mino">
                        Refer Code - {user.referralCode}
                      </div>
                    </div>
                  </div>
                  <div className="pi_row mob_update">
                    <div className="profile_info">
                      <div className="pi_row">
                        <span>College: </span>
                        <span>{collegeName}</span>
                      </div>
                      <div className="pi_row">
                        <span>City: </span>
                        <span>{cityName}</span>
                      </div>
                      <div className="pi_row">
                        <span>State: </span>
                        <span>{state}</span>
                      </div>
                      <div className="pi_row">
                        <span>Contact Number:</span>
                        <span>{user.phone}</span>
                      </div>
                      <div className="pi_row">
                        <span>Email ID:</span>
                        <span>{user.email}</span>
                      </div>
                    </div>
                    <div className="profiel_social">
                      <div
                        className="pi_row"
                        style={{
                          display: "flex",
                          flexDirection: "row",
                        }}
                      >
                        <label htmlFor="insta">
                          <img src={insta} alt="" />
                        </label>
                        <input
                          type="text"
                          name="insta"
                          id="insta"
                          placeholder={
                            user.insta !== ""
                              ? user.insta
                              : "Instagram profile link"
                          }
                          onChange={onChange}
                          value={updateUser.insta}
                        />
                      </div>
                      <div
                        className="pi_row"
                        style={{
                          display: "flex",
                          flexDirection: "row",
                        }}
                      >
                        <label htmlFor="facebook">
                          <img src={facebook} alt="" />
                        </label>
                        <input
                          type="text"
                          name="facebook"
                          id="facebook"
                          placeholder={
                            user.facebook !== ""
                              ? user.facebook
                              : "Facebook profile link"
                          }
                          onChange={onChange}
                          value={updateUser.facebook}
                        />
                      </div>
                      <div
                        className="pi_row"
                        style={{
                          display: "flex",
                          flexDirection: "row",
                        }}
                      >
                        <label htmlFor="twitter">
                          <img src={twitter} alt="" />
                        </label>
                        <input
                          type="text"
                          name="twitter"
                          id="twitter"
                          placeholder={
                            user.twitter !== ""
                              ? user.twitter
                              : "Twitter profile link"
                          }
                          onChange={onChange}
                          value={updateUser.twitter}
                        />
                      </div>
                      <div
                        className="pi_row"
                        style={{
                          display: "flex",
                          flexDirection: "row",
                        }}
                      >
                        <label htmlFor="linkedIn">
                          <img src={LinkedIn} alt="" />
                        </label>
                        <input
                          type="text"
                          name="linkedIn"
                          id="linkedIn"
                          placeholder={
                            user.linkedIn !== ""
                              ? user.linkedIn
                              : "LinkedIn profile link"
                          }
                          onChange={onChange}
                          value={updateUser.linkedIn}
                        />
                      </div>
                    </div>
                  </div>
                  <button
                    className="tc_submit_btn"
                    onClick={onSubmitClick}
                    disabled={check() && disabled}
                  >
                    Submit
                  </button>
                </div>
              </div>
            )}
            <div className="contained">
              <p
                onClick={() => {
                  setCurrentPage("mymi");
                  toggleMenu(); // Call toggleMenu after setting the page
                }}
              >
                My MoodI
              </p>
              <p
                onClick={() => {
                  setCurrentPage("earnpoints");
                  toggleMenu();
                }}
              >
                Points & Progress
              </p>
              <p
                onClick={() => {
                  setCurrentPage("threads");
                  toggleMenu();
                }}
              >
                Add Threads
              </p>
              <p
                onClick={() => {
                  setCurrentPage("leaderboards");
                  toggleMenu();
                }}
              >
                Leaderboard
              </p>
              <p
                onClick={() => {
                  setCurrentPage("about");
                  toggleMenu();
                }}
              >
                About Us
              </p>
              <p
                onClick={() => {
                  setCurrentPage("faqs");
                  toggleMenu();
                }}
              >
                Faqs
              </p>
            </div>
            <div className="extra_mobile"></div>
          </div>
        )}
        <div className="us_mif">
          <img src={avatar} alt="useravatar" />
          <img src={ccppoint} alt="" />
          <img src={badge} alt="" />
        </div>
        <div className="us_nav">
          <img onClick={() => setCurrentPage("mymi")} src={first} alt="first" />
          <img
            onClick={() => setCurrentPage("earnpoints")}
            src={second}
            alt="first"
          />
          <img
            onClick={() => setCurrentPage("threads")}
            src={fourth}
            alt="first"
          />
          <img
            onClick={() => setCurrentPage("leaderboards")}
            src={third}
            alt="first"
          />
          <img onClick={() => setCurrentPage("about")} src={five} alt="first" />
          <img onClick={() => setCurrentPage("faqs")} src={six} alt="first" />
        </div>
      </div>
    </>
  );
};

export default DashboardLeft;
