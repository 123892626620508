import React, { useState } from "react";
import "./registration.css";
import one from "../../../assets/images/ccp/avatar/one.svg";
import two from "../../../assets/images/ccp/avatar/two.svg";
import three from "../../../assets/images/ccp/avatar/three.svg";
import four from "../../../assets/images/ccp/avatar/four.svg";
import five from "../../../assets/images/ccp/avatar/five.svg";
import six from "../../../assets/images/ccp/avatar/six.svg";
import seven from "../../../assets/images/ccp/avatar/seven.svg";
import eight from "../../../assets/images/ccp/avatar/eight.svg";
import nine from "../../../assets/images/ccp/avatar/nine.svg";
import ten from "../../../assets/images/ccp/avatar/ten.svg";
import eleven from "../../../assets/images/ccp/avatar/eleven.svg";
import twelve from "../../../assets/images/ccp/avatar/twelve.svg";

const avatars = [
  { src: one, number: 1 },
  { src: two, number: 2 },
  { src: three, number: 3 },
  { src: four, number: 4 },
  { src: five, number: 5 },
  { src: six, number: 6 },
  { src: seven, number: 7 },
  { src: eight, number: 8 },
  { src: nine, number: 9 },
  { src: ten, number: 10 },
  { src: eleven, number: 11 },
  { src: twelve, number: 12 },
];

const Avatar = ({ user, setUser, page, setPage }) => {
  const [currentAvatar, setCurrentAvatar] = useState(
    user.image || avatars[0].number
  );

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(currentAvatar);

    if (user.image !== currentAvatar) {
      console.log("inside", currentAvatar);
      setUser({ ...user, image: currentAvatar });
      setPage(page + 1);
    } else {
      alert("* marked fields cannot be left empty");
    }
  };

  return (
    <div className="avatar_main">
      <div className="avatar-selection">
        {avatars.map((avatar) => (
          <div
            key={avatar.number}
            onClick={() => setCurrentAvatar(avatar.number)}
            className={`avatar-item ${
              currentAvatar === avatar.number ? "selected" : ""
            }`}
          >
            <img src={avatar.src} alt={`Avatar ${avatar.number}`} />
          </div>
        ))}
      </div>
      <div className="college_button">
        <button
          type="button"
          className="btn-10 custom-btn"
          onClick={() => setPage(page - 1)}
        >
          Back
        </button>
        <button
          type="button"
          onClick={handleSubmit}
          className="btn-10 custom-btn"
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default Avatar;
