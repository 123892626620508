import React, { useEffect, useRef } from "react";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/all";
import "../style.css";
import text from "../../../../assets/constants/text";
import lighting from "../../../../assets/images/ccp/random_assets/lighting.svg";
import books from "../../../../assets/images/ccp/random_assets/books.svg";
import player from "../../../../assets/images/ccp/random_assets/player.svg";
import smile_emoji from "../../../../assets/images/ccp/random_assets/smile_emoji.svg";
import mic from "../../../../assets/images/ccp/random_assets/mic.svg";
import film from "../../../../assets/images/ccp/random_assets/film.svg";
import RollingAnimation from "./RollingAnimation";

gsap.registerPlugin(ScrollTrigger);

const SecondPage = () => {
  const secondpageRef = useRef(null);

  useEffect(() => {
    const container = secondpageRef.current;
    const elements = container.querySelectorAll(
      ".secondpage_contained, .animate-image"
    );

    if (window.innerWidth >= 730) {
      elements.forEach((el) => {
        let fromX = 0,
          fromY = 10;
        gsap.fromTo(
          el,
          {
            opacity: 0,
            x: fromX,
            y: fromY,
          },
          {
            opacity: 1,
            x: 0,
            y: 0,
            duration: 1,
            stagger: 0.3,
            ease: "power3.out",
            scrollTrigger: {
              trigger: el,
              start: "top 100%",
              end: "bottom 100%",
              toggleActions: "play none none none",
              scrub: 1,
            },
          }
        );
      });
    } else {
      elements.forEach((el) => {
        let fromX = 0,
          fromY = 50;
        gsap.fromTo(
          el,
          {
            opacity: 0,
            x: fromX,
            y: fromY,
          },
          {
            opacity: 1,
            x: 0,
            y: 0,
            duration: 1,
            stagger: 0.3,
            ease: "power3.out",
            scrollTrigger: {
              trigger: el,
              start: "top 100%",
              end: "bottom 100%",
              toggleActions: "play none none none",
              scrub: 1,
            },
          }
        );
      });
    }

    ScrollTrigger.refresh();
  }, []);

  return (
    <div ref={secondpageRef} className="secondPage_main">
      <img className="object_a animate-image" src={books} alt="books" />
      <img className="object_b animate-image" src={mic} alt="mic" />
      <img className="object_c animate-image" src={film} alt="film" />
      <img
        className="object_d animate-image"
        src={smile_emoji}
        alt="smile_emoji"
      />
      <img className="object_e animate-image" src={player} alt="player" />
      <img className="object_f animate-image" src={lighting} alt="lighting" />
      <div className="object_z animate-image">
        <RollingAnimation text={text} direction={"left"} />
        <RollingAnimation text={text} direction={"right"} />
        <RollingAnimation text={text} direction={"left"} />
      </div>
      <div className="secondpage_contained" data-direction="left">
        <p>
          The College Connect Program is the pioneering initiative of Mood
          Indigo's outreach program, creating a powerful bond between you and
          IIT Bombay's prestigious cultural festival. By joining in the Indigo
          Squad, you'll become the influential ambassador of Mood Indigo in your
          college and city.
        </p>
        <p>
          This exceptional opportunity offers you the chance to acquire
          invaluable experience, organize remarkable events, and establish a
          robust network with like-minded individuals who share your drive and
          passion.
        </p>
      </div>
      <div className="test_scrolling"></div>
    </div>
  );
};

export default SecondPage;
