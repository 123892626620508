import React from "react";
import Book from "../../../assets/images/ccp/random_assets/book.svg";
import Ballon from "../../../assets/images/ccp/random_assets/ballon.svg";
import Books from "../../../assets/images/ccp/random_assets/books.svg";
import Film from "../../../assets/images/ccp/random_assets/film.svg";
import Guitar from "../../../assets/images/ccp/random_assets/guitar.svg";
import Lighting from "../../../assets/images/ccp/random_assets/lighting.svg";
import Mic from "../../../assets/images/ccp/random_assets/mic.svg";
import Player from "../../../assets/images/ccp/random_assets/player.svg";
import SmileEmoji from "../../../assets/images/ccp/random_assets/smile_emoji.svg";
import Star from "../../../assets/images/ccp/random_assets/star.svg";
import Heart from "../../../assets/images/ccp/random_assets/heart.svg";
import HeartSecond from "../../../assets/images/ccp/random_assets/heart_second.svg";
import "./registration.css";
import { motion } from "framer-motion";

const Random_assets = () => {
  return (
    <div className="random_assets">
      <motion.img
        whileHover={{ scale: 1.2 }}
        className="book_object"
        src={Book}
        alt="book"
      />
      <motion.img
        whileHover={{ scale: 1.2 }}
        className="star_object"
        src={Star}
        alt="star"
      />
      <motion.img
        whileHover={{ scale: 1.2 }}
        className="star_object_second"
        src={Star}
        alt="star"
      />
      <motion.img
        whileHover={{ scale: 1.2 }}
        className="lighting_object"
        src={Lighting}
        alt="lighting"
      />
      <motion.img
        whileHover={{ scale: 1.2 }}
        className="smile_object"
        src={SmileEmoji}
        alt="smile emoji"
      />
      <motion.img
        whileHover={{ scale: 1.2 }}
        className="heart_object"
        src={Heart}
        alt="heart"
      />
      <motion.img
        whileHover={{ scale: 1.2 }}
        className="ballon_object"
        src={Ballon}
        alt="ballon"
      />
      <motion.img
        whileHover={{ scale: 1.2 }}
        className="books_object"
        src={Books}
        alt="books"
      />
      <motion.img
        whileHover={{ scale: 1.2 }}
        className="film_object"
        src={Film}
        alt="film"
      />
      <motion.img
        whileHover={{ scale: 1.2 }}
        className="film_object_second"
        src={Film}
        alt="film"
      />
      <motion.img
        whileHover={{ scale: 1.2 }}
        className="heart_object_second"
        src={HeartSecond}
        alt="heart"
      />
      <motion.img
        whileHover={{ scale: 1.2 }}
        className="player_object"
        src={Player}
        alt="player"
      />
      <motion.img
        whileHover={{ scale: 1.2 }}
        className="guitar_object"
        src={Guitar}
        alt="guitar"
      />
      <motion.img
        whileHover={{ scale: 1.2 }}
        className="mic_object"
        src={Mic}
        alt="mic"
      />
      <motion.img
        whileHover={{ scale: 1.2 }}
        className="star_object_third"
        src={Star}
        alt="star"
      />
      <motion.img
        whileHover={{ scale: 1.2 }}
        className="star_object_third_second"
        src={Star}
        alt="star"
      />
    </div>
  );
};

export default Random_assets;
