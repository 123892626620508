import React, { useEffect, useRef } from "react";
import "../style.css";
import gsap from "gsap";
import "../home_responsive.css";

import ProgramText from "../../../../assets/images/ccp/first_page_assets/program_text.svg";
import CollegeText from "../../../../assets/images/ccp/first_page_assets/college_text.svg";
import ConnectText from "../../../../assets/images/ccp/first_page_assets/connect_text.svg";
import Books from "../../../../assets/images/ccp/first_page_assets/book.svg";
import Mobile from "../../../../assets/images/ccp/first_page_assets/mobile.svg";
import Newspaper from "../../../../assets/images/ccp/first_page_assets/newspaper.svg";
import Like from "../../../../assets/images/ccp/first_page_assets/like.svg";
import Board from "../../../../assets/images/ccp/first_page_assets/board.svg";
import CcpBox from "../../../../assets/images/ccp/first_page_assets/ccp_box.svg";
import Mic from "../../../../assets/images/ccp/first_page_assets/mic.svg";
import Triangle from "../../../../assets/images/ccp/first_page_assets/Triangle.svg";
import Hello from "../../../../assets/images/ccp/first_page_assets/hello.svg";
import Ballon from "../../../../assets/images/ccp/first_page_assets/ballon_first.svg";
import Player from "../../../../assets/images/ccp/first_page_assets/player_mobile.svg";

import Todolist from "./Todolist";

import TextAnimation from "./TextAnimation";

const FirstPage = () => {
  const balloonRef = useRef(null);
  const ccpref = useRef(null);

  useEffect(() => {
    const balloon = balloonRef.current;
    const ccp = ccpref.current;
    const t2 = gsap.timeline({ repeat: -1, yoyo: true });

    if (window.innerWidth <= 700) {
      t2.to(ccp, {
        duration: 0.7,
        y: "-20vw",
        x: "2vw",
       
        fontSize: "7vw",
        ease: "power1.inOut",
      });
    } else {
      t2.to(ccp, {
        duration: 0.7,
        y: "-20vh",
        x: "2vh",
       
        fontSize: "3vw",
        ease: "power1.inOut",
      });
    }

    const tl = gsap.timeline({ repeat: -1, yoyo: true });
    if (window.innerWidth <= 732) {
      console.log("animation is stop");
    } else {
      tl.to(balloon, {
        duration: 2,
        y: "-30vh",
        x: "25vw",
        ease: "power1.inOut",
      });
    }
  }, []);
  return (
    <div className="firstPage_main">
      <div className="text_animation">
        <img className="board_first_page" src={Board} alt="board" />
        <TextAnimation />
      </div>
      <img
        className="first_page_ballon"
        src={Ballon}
        alt="ballon first"
        ref={balloonRef}
      />
      <img className="connect_text" src={ConnectText} alt="connect text" />
      <img className="program_text" src={ProgramText} alt="program text" />
      <img className="college_text" src={CollegeText} alt="college text" />
      <img className="mobile_first" src={Mobile} alt="mobile" />
      <img className="book_first_page" src={Books} alt="book first page" />
      <img className="newspaper" src={Newspaper} alt="newspapre" />
      <img className="ccp_box" src={CcpBox} alt="ccp" />
      <img className="mic_first" src={Mic} alt="mic" />
      <img className="like_first" src={Like} alt="mic" />
      <p className="ccp_light_text" ref={ccpref}>
        CCP
      </p>
      <div>
        <Todolist />
      </div>
      <img className="triangle" src={Triangle} alt="Trianle" />
      <img className="hello" src={Hello} alt="Hello" />
      <img className="player" src={Player} alt="Player" />
    </div>
  );
};

export default FirstPage;
