import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Axios from "axios";
import {
  FaInstagram,
  FaLinkedin,
  FaSquareFacebook,
  FaXTwitter,
  FaYoutube,
} from "react-icons/fa6";
import CompiInfo from "./CompiInfo"; // Assuming this is another component
import { jwtDecode } from "jwt-decode";
import ObjHome from "../../../../assets/images/multicity/city_obj.svg";
import ObjHomeA from "../../../../assets/images/multicity/Group.svg";
import FooterImage from "../../../../assets/images/multicity/footer_image.svg";

// Import all images
import Bengup from "../../../../assets/images/multicity/mulBackground/bengaluru_down.svg";
import Delhiup from "../../../../assets/images/multicity/mulBackground/delhi_up.svg";
import BhaglapurUp from "../../../../assets/images/multicity/mulBackground/bhag_down.svg";
import BhilaUp from "../../../../assets/images/multicity/mulBackground/bhil_down.svg";
import ChandiUp from "../../../../assets/images/multicity/mulBackground/chandi_down.svg";
import Gwaliorup from "../../../../assets/images/multicity/mulBackground/gwa_down.svg";
import HydUp from "../../../../assets/images/multicity/mulBackground/hyd_down.svg";
import Jaipurup from "../../../../assets/images/multicity/mulBackground/Jaipur_down.svg";
import KurUp from "../../../../assets/images/multicity/mulBackground/kur_down.svg";
import MumUp from "../../../../assets/images/multicity/mulBackground/mum_down.svg";
import NagUp from "../../../../assets/images/multicity/mulBackground/nag_down.svg";
import PudUp from "../../../../assets/images/multicity/mulBackground/pud_down.svg";
import PuneUp from "../../../../assets/images/multicity/mulBackground/pune_down.svg";
import RoparUp from "../../../../assets/images/multicity/mulBackground/ropar_down.svg";
import ThriUp from "../../../../assets/images/multicity/mulBackground/thri_down.svg";

import mul_cities from "../../../../assets/constants/mul_cities";

// CSS import (assuming it's located correctly)
import "../css/cityPage.css";

// Mapping city names to images
const cityImages = {
  Bengaluru: Bengup,
  Delhi: Delhiup,
  Bhagalpur: BhaglapurUp,
  Chhattisgarh: BhilaUp,
  Chandigarh: ChandiUp,
  Gwalior: Gwaliorup,
  Hyderabad: HydUp,
  Jaipur: Jaipurup,
  Kurukshetra: KurUp,
  Mumbai: MumUp,
  Nagpur: NagUp,
  Puducherry: PudUp,
  Pune: PuneUp,
  Ropar: RoparUp,
  Thrissur: ThriUp,
};

const cityFontColor = {
  Bengaluru: "#000",
  Delhi: "#000",
  Bhagalpur: "#000",
  Chhattisgarh: "#000",
  Chandigarh: "#000",
  Gwalior: "#000",
  Hyderabad: "#000",
  Jaipur: "#000",
  Kurukshetra: "#000",
  Mumbai: "#000",
  Nagpur: "#000",
  Puducherry: "#000",
  Pune: "#000",
  Ropar: "#000",
  Thrissur: "#000",
};

const CityPage = () => {
  const params = useParams();
  const { city } = params;
  const [competitions, setCompetitions] = useState([]);
  const [regCompi, setRegCompi] = useState([]);
  const navigate = useNavigate();

  // Find the current city from mul_cities (this should be imported or defined elsewhere)
  const currCity = mul_cities.find((element) => element.cityName === city);

  const [user, setUser] = useState(
    sessionStorage.getItem("miauthtoken")
      ? jwtDecode(sessionStorage.getItem("miauthtoken")).user
      : null
  );

  const getCityCompetitions = () => {
    Axios.get(
      `https://ashish.moodi.org/multicity/get_mul_compi?city=${currCity.cityName}`
    )
      .then((res) => setCompetitions(res.data))
      .catch((err) => console.error(err));
  };

  const getUserRegCompis = () => {
    const data = {
      user: user.userId,
    };
    Axios.post("https://ashish.moodi.org/multicity/get_user_reg_compi", data, {
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => {
        if (res.data.length > 0) {
          setRegCompi(res.data);
        }
      })
      .catch((err) => console.error(err));
  };

  useEffect(() => {
    const initializeUser = async () => {
      if (sessionStorage.getItem("miauthtoken")) {
        setUser(jwtDecode(sessionStorage.getItem("miauthtoken")).user);
        getCityCompetitions();
        getUserRegCompis();
      } else {
        navigate(`/register?redirect=multicities/cityinfo/${city}`);
      }
    };
    initializeUser();

    
  }, []);

  return (
    <div className="mul_page_main">
      <img className="city_bac_obj" src={ObjHome} alt="" />
      <img className="city_bac_b" src={ObjHomeA} alt="" />
      <div className="mul_city_page">
        <div
          className="city_compis_header"
          style={{ background: currCity.cityColor }}
        >
          <img
            className="city_img"
            src={cityImages[currCity.cityName]} // Use the mapped image
            alt={currCity.cityName}
          />
          <div
            className="city_info_1"
            style={{ color: cityFontColor[currCity.cityName] }}
          >
            <p className="cityName">{currCity.cityName.toUpperCase()}</p>
            <p className="cityDate">{currCity.cityDate}</p>
            <p className="cityVenue">
              Venue: <span>{currCity.cityVenue}</span>
            </p>
          </div>
        </div>
        <div className="compi_container">
          {competitions.map((competition) => (
            <CompiInfo
              key={competition.mcId}
              city={city}
              competition={competition}
              user={user}
              regCompi={regCompi}
              setRegCompi={setRegCompi}
            />
          ))}
        </div>
      </div>
      <img className="footer_image_mul" src={FooterImage} alt="footer" />
      <div className="footer_mul" style={{ marginTop: "0vh" }}>
        <div className="icon-container">
          <a
            href="https://www.youtube.com/@iitbmoodindigo"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaYoutube color="black" size={window.innerWidth > 770 ? 50 : 20} />
          </a>
          <a
            href="https://www.instagram.com/iitbombay.moodi/?hl=en"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaInstagram
              color="black"
              size={window.innerWidth > 770 ? 50 : 20}
            />
          </a>
          <a
            href="https://in.linkedin.com/company/mood-indigo"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaLinkedin
              color="black"
              size={window.innerWidth > 770 ? 50 : 20}
            />
          </a>
          <a
            href="https://twitter.com/iitb_moodi?lang=en"
            target="black"
            rel="noopener noreferrer"
          >
            <FaXTwitter
              color="black"
              size={window.innerWidth > 770 ? 50 : 20}
            />
          </a>
          <a
            href="https://www.facebook.com/iitbombay.moodi/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaSquareFacebook
              color="black"
              size={window.innerWidth > 770 ? 50 : 20}
            />
          </a>
        </div>
        <div className="address">
          <p style={{ color: "black" }}>
            Mood Indigo Office, Student Activity Center (SAC),
            <br /> IIT Bombay, Powai, Mumbai, Maharashtra - 400076
          </p>
        </div>
      </div>
    </div>
  );
};

export default CityPage;
