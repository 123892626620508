import React, { useState, useEffect } from "react";
import finance from "../../../assets/images/ccp/club/finance.svg";
import career from "../../../assets/images/ccp/club/career.svg";
import dramatics from "../../../assets/images/ccp/club/dramatics.svg";
import food from "../../../assets/images/ccp/club/food.svg";
import meme from "../../../assets/images/ccp/club/meme.svg";
import sports from "../../../assets/images/ccp/club/sports.svg";
import music from "../../../assets/images/ccp/club/music.svg";
import person from "../../../assets/images/ccp/club/person.svg";
import dance from "../../../assets/images/ccp/club/dance.svg";
import gaming from "../../../assets/images/ccp/club/gaming.svg";
import "./css/club.css";

const Clubs = ({ setCurrentClub }) => {
  // Initialize state with local storage data
  const [joinedClubs, setJoinedClubs] = useState(() => {
    const savedClubs = localStorage.getItem("joinedClubs");
    return savedClubs ? JSON.parse(savedClubs) : {};
  });

  useEffect(() => {
    localStorage.setItem("joinedClubs", JSON.stringify(joinedClubs));
  }, [joinedClubs]);

  const handleClubClick = (clubName) => {
    setCurrentClub(clubName);
  };

  const toggleJoinClub = (clubName) => {
    setJoinedClubs((prev) => ({
      ...prev,
      [clubName]: !prev[clubName],
    }));
  };

  const renderClubStatus = (clubName) => {
    return joinedClubs[clubName] ? "Joined" : "Join";
  };

  return (
    <div className="club_active_main">
      <p className="announcement_heading">CLUBS</p>
      <div className="club_main">
        <div className="club_mi " style={{ cursor: "pointer" }}>
          <div
            className="club_img"
            onClick={() => handleClubClick("all Thread")}
           
          >
            <p style={{textAlign: "center"}}>Back To All Thread</p>
          </div>
          <div className="club_dt">
            <p className="club_usr">1258+</p>
            <img src={person} alt="person" />
          </div>
        </div>
        <div className="club_mi ext_club_mi" style={{ cursor: "pointer" }}>
          <div className="club_img" onClick={() => handleClubClick("finance")}>
            <img src={finance} alt="finance" />
            <p>Finance</p>
          </div>
          <div className="club_dt">
            <p className="usr_p" onClick={() => toggleJoinClub("finance")}>
              {renderClubStatus("finance")}
            </p>
            <p className="club_usr">258+</p>
            <img src={person} alt="person" />
          </div>
        </div>
      </div>
      <div className="club_main">
        <div className="club_mi" style={{ cursor: "pointer" }}>
          <div
            className="club_img"
            onClick={() => handleClubClick("dramatics")}
          >
            <img src={dramatics} alt="dramatics" />
            <p>Dramatics</p>
          </div>
          <div className="club_dt">
            <p className="usr_p" onClick={() => toggleJoinClub("dramatics")}>
              {renderClubStatus("dramatics")}
            </p>
            <p className="club_usr">212+</p>
            <img src={person} alt="person" />
          </div>
        </div>
        <div className="club_mi ext_club_mi" style={{ cursor: "pointer" }}>
          <div className="club_img" onClick={() => handleClubClick("food")}>
            <img src={food} alt="food" />
            <p>Food</p>
          </div>
          <div className="club_dt">
            <p className="usr_p" onClick={() => toggleJoinClub("food")}>
              {renderClubStatus("food")}
            </p>
            <p className="club_usr">184+</p>
            <img src={person} alt="person" />
          </div>
        </div>
      </div>
      <div className="club_main">
        <div className="club_mi" style={{ cursor: "pointer" }}>
          <div className="club_img" onClick={() => handleClubClick("gaming")}>
            <img src={gaming} alt="meme" />
            <p>Gaming</p>
          </div>
          <div className="club_dt">
            <p className="usr_p" onClick={() => toggleJoinClub("gaming")}>
              {renderClubStatus("gaming")}
            </p>
            <p className="club_usr">236+</p>
            <img src={person} alt="person" />
          </div>
        </div>
        <div className="club_mi ext_club_mi" style={{ cursor: "pointer" }}>
          <div className="club_img" onClick={() => handleClubClick("dance")}>
            <img src={dance} alt="sports" />
            <p>Dance</p>
          </div>
          <div className="club_dt">
            <p className="usr_p" onClick={() => toggleJoinClub("dance")}>
              {renderClubStatus("dance")}
            </p>
            <p className="club_usr">291+</p>
            <img src={person} alt="person" />
          </div>
        </div>
      </div>
      <div className="club_main">
        <div className="club_mi" style={{ cursor: "pointer" }}>
          <div className="club_img" onClick={() => handleClubClick("meme")}>
            <img src={meme} alt="meme" />
            <p>Meme</p>
          </div>
          <div className="club_dt">
            <p className="usr_p" onClick={() => toggleJoinClub("meme")}>
              {renderClubStatus("meme")}
            </p>
            <p className="club_usr">276+</p>
            <img src={person} alt="person" />
          </div>
        </div>
        <div className="club_mi ext_club_mi" style={{ cursor: "pointer" }}>
          <div className="club_img" onClick={() => handleClubClick("sports")}>
            <img src={sports} alt="sports" />
            <p>Sports</p>
          </div>
          <div className="club_dt">
            <p className="usr_p" onClick={() => toggleJoinClub("sports")}>
              {renderClubStatus("sports")}
            </p>
            <p className="club_usr">191+</p>
            <img src={person} alt="person" />
          </div>
        </div>
      </div>
      <div className="club_main">
        <div className="club_mi" style={{ cursor: "pointer" }}>
          <div className="club_img" onClick={() => handleClubClick("music")}>
            <img src={music} alt="music" />
            <p>Music</p>
          </div>
          <div className="club_dt">
            <p className="usr_p" onClick={() => toggleJoinClub("music")}>
              {renderClubStatus("music")}
            </p>
            <p className="club_usr">163+</p>
            <img src={person} alt="person" />
          </div>
        </div>
        <div className="club_mi ext_club_mi" style={{ cursor: "pointer" }}>
          <div className="club_img" onClick={() => handleClubClick("career")}>
            <img src={career} alt="career" />
            <p>Career</p>
          </div>
          <div className="club_dt">
            <p className="usr_p" onClick={() => toggleJoinClub("career")}>
              {renderClubStatus("career")}
            </p>
            <p className="club_usr">198+</p>
            <img src={person} alt="person" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Clubs;
