import React, { useEffect, useRef, useState } from "react";
import TeamCompiReg from "./TeamCompiReg";
import Arrow from "../../../../assets/images/multicity/arrow.svg";
import Axios from "axios";
import Close from "../../../../assets/images/ccp/close.svg";
import mul_cities from "../../../../assets/constants/mul_cities";

const CompiInfo = ({ city, competition, user, regCompi, setRegCompi }) => {
  const rulesRef = useRef(null);
  const prizesLypRef = useRef(null);
  const accRef = useRef(null);
  const tcRef = useRef(null);

  const [accOpen, setAccOpen] = useState(false);

  const onAccordionClick = () => {
    accRef.current.classList.toggle("acc_active");
    setAccOpen(!accOpen);
  };

  const onRegisterClick = () => {
    if (competition.isTeamCompi) {
      tcRef.current.classList.remove("no-disp");
    } else {
      if (window.confirm("Are you sure you want to register?") === true) {
        Axios.post(
          "https://ashish.moodi.org/multicity/mul_solo_reg",
          {
            user: user.userId,
            competition: competition.mcId,
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
          .then((res) => {
            if (res.data.status === "done") {
              setRegCompi((current) => [
                ...current,
                { competition: competition.mcId },
              ]);
              alert(
                `You have successfully registered for ${competition.mcName}!!`
              );
              window.location.reload();
            } else {
              window.alert(res.data.status);
            }
          })
          .catch((err) => console.error(err));
      }
    }
  };

  useEffect(() => {
    let rules = rulesRef.current;
    rules.innerHTML = competition.rules;
    let prizes = prizesLypRef.current;
    prizes.innerHTML = competition.prizesLyp;
  }, [competition]);

  const cityData = mul_cities.find((c) => c.cityName === city);
  const cityColor = cityData ? cityData.cityColor : "#ffffff";

  return (
    <div className="compi_main">
      <div
        ref={accRef}
        className="accordion"
        onClick={onAccordionClick}
        style={{ background: cityColor }}
      >
        <div className="compi_det">
          <p className="compi_title">
            {competition.mcName ? competition.mcName.toUpperCase() : ""}
          </p>
          <p className="compi_subtitle">{competition.mcSubtitle}</p>
        </div>
        <div className="compi_card">
          <div className="compi_reg_btn">Rules</div>
          {accOpen ? (
            <div className="up_arrow">
              <img src={Arrow} alt="" />
            </div>
          ) : (
            <>
              {regCompi ? (
                regCompi.find(
                  (element) => element.competition === competition.mcId
                ) ? (
                  <div
                    className="compi_reg_btn bg-green"
                    style={{ position: "inherit" }}
                  >
                    REGISTERED
                  </div>
                ) : (
                  <div
                    className="compi_reg_btn "
                    style={{ position: "inherit" }}
                  >
                    REGISTER
                  </div>
                )
              ) : (
                <div className="compi_reg_btn " style={{ position: "inherit" }}>
                  REGISTER
                </div>
              )}
            </>
          )}
        </div>
      </div>

      <div
        className={`panel ${accOpen ? "show" : ""}`}
        style={{ display: accOpen ? "block" : "none" }}
      >
        <div className="scroll_bar">
          <div
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "space-between",
            }}
          >
            {regCompi ? (
              regCompi.find(
                (element) => element.competition === competition.mcId
              ) ? (
                competition.isTeamCompi ? (
                  <button
                    className="compi_reg_btn bg-green"
                    onClick={onRegisterClick}
                  >
                    VIEW TEAM
                  </button>
                ) : (
                  <button className="compi_reg_btn bg-green">REGISTERED</button>
                )
              ) : (
                <button className="compi_reg_btn" onClick={onRegisterClick}>
                  REGISTER
                </button>
              )
            ) : (
              <button className="compi_reg_btn" onClick={onRegisterClick}>
                REGISTER
              </button>
            )}
            <img
              onClick={() => {
                setAccOpen(false);
              }}
              className="close_panel"
              src={Close}
              alt="close"
            />
          </div>
          <p className="compi_desc">{competition.mcDesc}</p>
          <p className="compi_title compi_rule">RULES</p>
          <p ref={rulesRef} className="compi_rules"></p>
          {/* <p className="compi_title compi_rule">INCENTIVES</p> */}
          <p ref={prizesLypRef} className="compi_prizes"></p>

          <div
            onClick={() => {
              setAccOpen(false);
            }}
            className="compi_reg_btn"
            style={{ background: "white", color: "black" }}
          >
            Back
          </div>
        </div>
      </div>

      {competition.isTeamCompi ? (
        <TeamCompiReg
          city={city}
          tcRef={tcRef}
          competition={competition}
          user={user}
          regCompi={regCompi}
          setRegCompi={setRegCompi}
        />
      ) : null}
    </div>
  );
};

export default CompiInfo;
