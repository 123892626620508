import React, { useEffect, useRef, useState } from "react";
import Axios from "axios";
import { useNavigate } from "react-router-dom";
import Filter from "bad-words";
import badWords from "../../../assets/constants/badwords";

import "./css/thread.css";

import like from "../../../assets/images/ccp/like_a.svg";
import comment from "../../../assets/images/ccp/comment.svg";
import liked from "../../../assets/images/ccp/liked.svg";
import send from "../../../assets/images/ccp/send.svg";
import mi_logo from "../../../assets/images/ccp/CcpPoint.svg";
import share from "../../../assets/images/ccp/share.svg";

const ThreadsPost = ({ threads, club, user }) => {
  var filter = new Filter();
  filter.addWords(...badWords);
  const navigate = useNavigate();
  const [likeImg, setLikeImg] = useState(like);

  const [comments, setComments] = useState({ comment: "" });
  const [threadComments, setThreadComments] = useState([]);
  const [visible, setVisible] = useState(false);
  const commentsRef = useRef(null);
  const currDate = new Date();
  const threadDate = new Date(threads.postedOn);
  const diff = currDate - threadDate;
  const [likeDisabled, setLikeDisabled] = useState(false);

  const getLikeStatus = () => {
    Axios.post(
      "https://ashish.moodi.org/ccp/getThreadLike",
      {
        user: user.userId,
        thread: threads.threadId,
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    ).then((res) => {
      if (res.data.likeStatus === "liked") {
        setLikeImg(liked);
      } else {
        setLikeImg(like);
      }
    });
  };

  const onLikeClick = () => {
    setLikeDisabled(true);
    if (likeImg === liked) {
      setLikeImg(like);
      Axios.post(
        "https://ashish.moodi.org/ccp/removeThreadLike",
        {
          thread: threads.threadId,
          user: user.userId,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
        .then((res) => {
          if (res.data.status === "done") {
            setLikeImg(like);
            setLikeDisabled(false);
          }
        })
        .catch((err) => console.error(err));
    } else {
      setLikeImg(liked);
      Axios.post(
        "https://ashish.moodi.org/ccp/addThreadLike",
        {
          thread: threads.threadId,
          user: user.userId,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
        .then((res) => {
          if (res.data.status === "done") {
            setLikeImg(liked);
            setLikeDisabled(false);
          }
        })
        .catch((err) => console.error(err));
    }
  };

  const onCommentClick = () => {
    setVisible(!visible);
  };

  const onSendClick = () => {
    if (comments.comment !== "") {
      setComments({
        ...comments,
        comment: filter.clean(comments.comment),
      });
      Axios.post(
        "https://ashish.moodi.org/ccp/addThreadComment",
        {
          thread: threads.threadId,
          user: user.userId,
          comment: filter.clean(comments.comment),
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
        .then((res) => {
          if (res.data.status === "done") {
            setThreadComments((current) => [
              ...current,
              {
                userName: user.userName,
                profilePic: user.profilePic,
                comment: filter.clean(comments.comment),
              },
            ]);
            setComments({ comment: "" });
            alert("Comment added successfully");
          }
        })
        .catch((err) => console.error(err));
    }
  };

  const getThreadComments = () => {
    Axios.post(
      "https://ashish.moodi.org/ccp/getthreadcomments",
      {
        thread: threads.threadId,
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
      .then((res) => setThreadComments(res.data))
      .catch((err) => console.error(err));
  };

  const onShareClick = () => {
    if (window.innerWidth > 770) {
      window.navigator.clipboard.writeText(window.location.href);
      window.alert("Link copied to clipboard!!");
    } else {
      if (navigator.share) {
        navigator.share({
          title: `${threads.topic} - ${club} club, Mood Indigo`,
          url: window.location.href,
        });
      }
    }
  };


  const onChange = (e) => {
    setComments({ ...comments, [e.target.name]: e.target.value });
  };

  useEffect(() => {
    getLikeStatus();
    getThreadComments();
    setThreadComments([]);
  }, [threads.threadId]);

  return (
    <div className="thread_a">
      <div className="th_hd_a">
        <div className="th_left">
          <img src={`${threads.profilePic}`} alt="" />
          <p>{threads.userName}</p>
        </div>
      </div>
      <div className="thread_b">
        <div className="thd_image center">
          <img
            src={`https://ashish.moodi.org/ccp/getImage?img=${threads.threadImage}`}
            alt=""
          />
        </div>
        <div className="thread_title">
          <p style={{ marginBottom: "0vh" }}>
            {threads.topic.split(" ").map((word, index) => {
              if (word.startsWith("http")) {
                return (
                  <a
                    key={index}
                    href={word}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {word}
                  </a>
                );
              }
              return word + " ";
            })}
          </p>
        </div>
        <div className="thread_time">
          {diff / (1000 * 60 * 60 * 24) > 1
            ? `Posted ${Math.floor(diff / (1000 * 60 * 60 * 24))} days ago`
            : Math.floor(diff / (1000 * 60 * 60)) === 1
            ? `Posted ${Math.floor(diff / (1000 * 60 * 60))} hour ago`
            : Math.floor(diff / (1000 * 60 * 60)) > 1
            ? `Posted ${Math.floor(diff / (1000 * 60 * 60))} hours ago`
            : Math.floor(diff / (1000 * 60)) === 1
            ? `Posted ${Math.floor(diff / (1000 * 60))} min ago`
            : Math.floor(diff / (1000 * 60)) > 1
            ? `Posted ${Math.floor(diff / (1000 * 60))} mins ago`
            : `Posted ${Math.floor(diff / 1000)} secs ago`}
        </div>
        <div className="thread_caption">{threads.content}</div>
        <div className="th_activity">
          <div className="th_like">
            <button disabled={likeDisabled}>
              <img src={likeImg} alt="" onClick={onLikeClick} />
            </button>
            <img src={comment} alt="" onClick={onCommentClick} />

            <div className="th_right">
              <div className="menu_btn" onClick={onShareClick}>
                <img src={share} alt="" />
              </div>
            </div>
          </div>
          <input
            type="text"
            placeholder="Add something wonderful..."
            value={comments.comment}
            name="comment"
            onChange={onChange}
          />
          <img
            style={
              comments.comment === ""
                ? { cursor: "not-allowed" }
                : { cursor: "pointer" }
            }
            src={send}
            alt=""
            onClick={onSendClick}
          />
        </div>
        <div
          className={`thread_comments ${visible ? "" : "no-disp"}`}
          ref={commentsRef}
        >
          {threadComments.length > 0 ? (
            <p className="comment_header">Comments</p>
          ) : (
            <p className="comment_header">No comments yet!</p>
          )}
          {threadComments.map((comment, index) => (
            <div key={index} className="comment_container">
              <div className="comment_left">
                <img
                  src={
                    comment.profilePic !== ""
                      ? `${comment.profilePic}`
                      : mi_logo
                  }
                  alt=""
                />
                <p>{comment.userName}</p>
              </div>
              <div className="comment_right">{comment.comment}</div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ThreadsPost;
