import React from "react";
import Pin from "../../../../assets/images/compi/pin.jpg";

const Faqs = () => {
  const faqData = [
    {
      question: "Q 1. What is LYP?",
      answer:
        "LYP stands for Live Your Passion. This is an initiative taken up by Mood Indigo in order to promote and showcase the talent of winners of competitions. Wherever applicable, they have been mentioned in the competition details itself under the Prizes & LYP tab.",
    },
    {
      question:
        "Q 2. Will I get a certificate for participation in Mood Indigo competitions?",
      answer:
        "Winners and finalists of all the competitions shall get a digital certificate after Mood Indigo. Everyone participating will get a digital certificate of participation.",
    },
    {
      question:
        "Q 3. Who can participate in these competitions? Is there any fee involved for registration?",
      answer:
        "Any college student can take part in these competitions. There is no registration fee for participation.",
    },
    {
      question: "Q 4. How do I register for Competitions at Mood Indigo?",
      answer:
        "If you do not have an MI Number, go to the 'Register' tab and fill in your details there to get an MI Number. Then go to your Dashboard > Competitions > Genre and register in the required competition. If you already have an MI Number then directly register for the competition by going to Dashboard > Competitions > Genre and register in the required competition.",
    },
    {
      question:
        "Q 5. We participated in multicity eliminations but could not qualify are we still eligible for participating in offline elimination?",
      answer:
        "Anyone can participate through the offline eliminations happening during the main fest from 24th - 27th December irrespective of whether they participated/qualified in Multicity eliminations or not.",
    },
    {
      question: "Q 6. How many teams from a college can participate?",
      answer:
        "There is no restriction on the number of teams participating from a college. We welcome maximum participation from your end.",
    },
  ];

  return (
    <div className="faqs_main">
      <p className="faqs_heading">FAQS</p>
      <div className="faqs_container">
        <div className="pin_main">
          <div className="faqs_pin">
            <img src={Pin} alt="pin" />
            <img src={Pin} alt="pin" />
            <img src={Pin} alt="pin" />
            <img src={Pin} alt="pin" />
            <img src={Pin} alt="pin" />
            <img src={Pin} alt="pin" />
            <img src={Pin} alt="pin" />
            <img src={Pin} alt="pin" />
            <img src={Pin} alt="pin" />
            <img src={Pin} alt="pin" />
          </div>
          <div className="faqs_pin">
            <img src={Pin} alt="pin" />
            <img src={Pin} alt="pin" />
            <img src={Pin} alt="pin" />
            <img src={Pin} alt="pin" />
            <img src={Pin} alt="pin" />
            <img src={Pin} alt="pin" />
            <img src={Pin} alt="pin" />
            <img src={Pin} alt="pin" />
            <img src={Pin} alt="pin" />
            <img src={Pin} alt="pin" />
          </div>
        </div>
        <div className="faq-section">
          {faqData.map((faq, index) => (
            <div key={index} className="faq-item">
              <p className="faq-question">
                <strong>{faq.question}</strong>
              </p>
              <p className="faq-answer">{faq.answer}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Faqs;
