import React, { useEffect, useState } from "react";
import Axios from "axios";

const Mostactive = ({ club, user }) => {
  const [trendingThreads, setTrendingThreads] = useState([]);
  const getTopClubThreads = () => {
    Axios.get(`https://ashish.moodi.org/ccp/trending_threads?club=${club}`)
      .then((res) => setTrendingThreads(res.data))
      .catch((err) => console.error(err));
  };

  useEffect(() => {
    getTopClubThreads();
    // eslint-disable-next-line
  }, [club]);

  return (
    <div className="most_active_main">
      <p className="announcement_heading">MOST ACTIVE</p>
     
      <div className="mst_im">
        {trendingThreads.map((thread, index) => {
          return (
            <div
              className="trending_thread_group"
              style={{ marginTop: "5vh" }}
            >
              <div className="thread_prg">
                <img src={`${thread.profilePic}`} alt="" />
                <p className="thread_prg_p">{thread.userName}</p>
              </div>
              <p>
                <span>{index + 2}. </span>
                <span>{thread.topic}</span>
              </p>
              <img
                className="thread_trending_img"
                src={`https://ashish.moodi.org/ccp/getImage?img=${thread.threadImage}`}
                alt=""
              />
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Mostactive;
