import React from "react";
import GenreCards from "./GenreCards";
const Genre = () => {
  return (
    <div>
      <p className="genre_heading">GENRES</p>
      <GenreCards /> {/* Importing GenreCards component */}
    </div>
  );
};

export default Genre;
