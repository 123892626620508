import React, { useEffect, useRef } from "react";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import "../style.css";
import Lighting from "../../../../assets/images/ccp/random_assets/lighting.svg";
import ritu from "../../../../assets/images/ccp/team_info/ritu.jpg";
import shreyas from "../../../../assets/images/ccp/team_info/shreyas.jpg";
import RollingAnimation from "./RollingAnimation";
import {
  FaInstagram,
  FaLinkedin,
  FaWhatsapp,
  FaTwitter,
  FaFacebook,
  FaYoutube,
} from "react-icons/fa";

gsap.registerPlugin(ScrollTrigger);

const LastPage = () => {
  const lastpageRef = useRef(null);

  useEffect(() => {
    const container = lastpageRef.current;
    const elements = container.querySelectorAll(".lastpage_a, .animate-image");

    elements.forEach((el) => {
      let fromX = 0,
        fromY = 100;
      gsap.fromTo(
        el,
        {
          opacity: 0,
          x: fromX,
          y: fromY,
        },
        {
          opacity: 1,
          x: 0,
          y: 0,
          duration: 1,
          stagger: 0.3,
          ease: "power3.out",
          scrollTrigger: {
            trigger: el,
            start: "top 100%",
            end: "bottom 100%",
            toggleActions: "play none none none",
            scrub: 1,
          },
        }
      );
    });

    ScrollTrigger.refresh();
  }, []);

  const text = [" MOOD INDIGO MOOD INDIGO"];
  const customStyle = {
    fontSize: "7vw",
    color: "rgba(255, 255, 255, 0.42)",
    fontWeight: "700",
    fontFamily: "lemonMilk",
    lineHeight: "1.18",
  };
  return (
    <div ref={lastpageRef} className="lastpast_main">
      <img
        className="lastpage_lighting_element animate-image"
        src={Lighting}
        alt="lighting"
      />
      <div className="lastpage_a">
        <div className="cg-detail-main">
          <div className="cg-detail">
            <img className="cg-photo" src={ritu} alt="ritu raj" />
            <div className="cg-contact">
              <p className="cg-name">RITU RAJ</p>
              <p>Hospitality and PR Head</p>
              <a href="tel:+919142246807" className="cg-phone">
                +91 91422 46807
              </a>
            </div>
            <div className="cg-links">
              <a
                target="_blank"
                rel="noreferrer"
                href="https://www.instagram.com/the_ritu_raj1304?igsh=eDI5eGQ2bW04amI2&utm_source=qr"
              >
                <FaInstagram color="black" size={30} />
              </a>
              <a
                target="_blank"
                rel="noreferrer"
                href="https://www.linkedin.com/in/ritu-raj-9b699724b/"
              >
                <FaLinkedin color="black" size={30} />
              </a>

              <a
                target="_blank"
                rel="noreferrer"
                href="https://wa.me/9142246807"
              >
                <FaWhatsapp color="black" size={30} />
              </a>
            </div>
          </div>
          <div className="cg-detail">
            <img className="cg-photo" src={shreyas} alt="shreyas" />
            <div className="cg-contact">
              <p className="cg-name">SHREYAS MADANKAR </p>
              <p>Hospitality and PR Head</p>
              <a href="tel:+918767683564" className="cg-phone">
                +91 87676 83564
              </a>
            </div>
            <div className="cg-links">
              <a
                target="_blank"
                rel="noreferrer"
                href="https://www.instagram.com/shreyas.m57?igsh=OW84eDU2Y3hwbzdi"
              >
                <FaInstagram color="black" size={30} />
              </a>
              <a
                target="_blank"
                rel="noreferrer"
                href="https://www.linkedin.com/in/shreyas-m-49188824b?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app"
              >
                <FaLinkedin color="black" size={30} />
              </a>

              <a
                target="_blank"
                rel="noreferrer"
                href="https://wa.me/+918767683564"
              >
                <FaWhatsapp color="black" size={30} />
              </a>
            </div>
          </div>
        </div>
        <div className="aboutus_section">
          <p className="aboutus_section_heading">About Us</p>
          <p className="aboutus_section_detail">
            Mood Indigo is not just the annual cultural fest of IIT Bombay but a
            kaleidoscope of emotions, memories, and boundless creativity. This
            'Chaar Din ka Sapna' is the place where people experience four power
            packed days of passionate competitions, fun games and activities,
            events of renowned artists, electrifying concerts and soothing night
            life. Over the 53 years that Mood Indigo has established its legacy
            in, it has served host to artists like Deepika Padukone, Vidya
            Balan. Magical concerts of Sunidhi Chauhan, Vishal Shekhar have awed
            the audience. All in all, MI is a testament to the indomitable
            spirit of exploration and self-expression, etching lasting memories
            in the hearts of all who partake in its magic.
          </p>
        </div>
      </div>
      <div ref={lastpageRef} className="footer">
        <div className="footer_text_animation">
          <RollingAnimation
            style={customStyle}
            text={text}
            direction={"left"}
          />
        </div>
        <div className="footer_details">
          <p>
            Mood Indigo Office, Student Activity Center (SAC), IIT Bombay,
            Powai, Mumbai, Maharashtra - 400076
          </p>
          <div className="footer_icon">
            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.instagram.com/iitbombay.moodi?igsh=MTNmam9pY3Z1YW93aQ=="
            >
              <FaInstagram color="white" size={30} />
            </a>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.linkedin.com/company/mood-indigo/"
            >
              <FaLinkedin color="white" size={30} />
            </a>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://x.com/iitb_moodi?t=tyaVKeWlXXTcc9qIerNTkQ&s=08"
            >
              <FaTwitter color="white" size={30} />
            </a>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.facebook.com/iitbombay.moodi?mibextid=ZbWKwL"
            >
              <FaFacebook color="white" size={30} />
            </a>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.youtube.com/@iitbmoodindigo"
            >
              <FaYoutube color="white" size={30} />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LastPage;
