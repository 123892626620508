import React from "react";

const Event = () => {
  return (
    <div className="point_main">
      <p className="point_main_heading">Event</p>
      <p classNamw="task_description">Event description</p>
    </div>
  );
};

export default Event;
