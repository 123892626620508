import React, { useEffect, useRef, useState } from "react";
import Axios from "axios";
import { useNavigate } from "react-router-dom";

const EmailLogin = ({ user, setUser, page, setPage }) => {
  const otpRef = useRef(null);
  const continueBtnRef = useRef(null);
  const nextBtnRef = useRef(null);
  let navigate = useNavigate();

  const [otp, setOtp] = useState("");
  const [otpVisible, setOtpVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    setPage(page + 1);
  };

  const onChange = (e) => {
    setUser({ ...user, [e.target.name]: e.target.value });
  };

  function isValidEmail(email) {
    return /\S+@\S+\.\S+/.test(email);
  }

  const onContinueClick = (e) => {
    e.preventDefault();
    if (otpVisible) {
      if (user.otp === otp) {
        setIsLoading(true); // Start loading
        Axios.post(
          "https://ashish.moodi.org/users/getUser",
          {
            email: user.email,
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
          .then((res) => {
            setIsLoading(false); // Stop loading
            if (res.data.exists) {
              if (res.data.msg) {
                alert(
                  "Invalid Credentials! Please try again. If you forgot your Credentials then contact on the help section"
                );
              }
              if (res.data.authtoken) {
                sessionStorage.setItem("miauthtoken", res.data.authtoken);
                navigate("/success");
              }
            } else {
              let nextBtn = nextBtnRef.current;
              nextBtn.click();
            }
          })
          .catch((err) => {
            console.error(err);
            setIsLoading(false); // Stop loading on error
          });
      } else {
        alert("Please enter the correct OTP!");
      }
    } else {
      if (user.email !== "" && user.password.length >= 8) {
        if (isValidEmail(user.email)) {
          setIsLoading(true); // Start loading
          Axios.post(
            "https://ashish.moodi.org/email/sendOtp",
            {
              email: user.email,
            },
            {
              headers: {
                "Content-Type": "application/json",
              },
            }
          )
            .then((res) => {
              setOtp(res.data.otp);
              alert(
                "An email with an OTP has been sent to your email id. Please check the spam/promotions folder as well."
              );
              setOtpVisible(true);
              setIsLoading(false); // Stop loading
            })
            .catch((err) => {
              alert(
                "Sorry! Your request cannot be processed at the moment. Please try again!"
              );
              console.error(err);
              setIsLoading(false); // Stop loading on error
            });
        } else {
          alert("Please enter a valid email!");
        }
      } else {
        alert("Email and Password cannot be empty");
      }
    }
  };

  useEffect(() => {
    if (user.googleId !== "") {
      let nextBtn = nextBtnRef.current;
      nextBtn.click();
    }
  }, [user.googleId]);

  return (
    <>
      <p className="emailLogin_text">Email Login</p>
      <form>
        <div className="input-container">
          <input
            type="email"
            name="email"
            id="email"
            onChange={onChange}
            placeholder="Email*"
            autoComplete="email"
            value={user.email}
            className="inputEmail"
            required
          />
          <input
            type="password"
            name="password"
            id="password"
            minLength={8}
            placeholder="Password*"
            onChange={onChange}
            autoComplete="new-password"
            value={user.password}
            className="inputEmail"
          />
        </div>
        <div className="otp_button_header">
          {otpVisible && (
            <input
              type="text"
              name="otp"
              id="otp"
              className="inputEmail"
              placeholder="OTP*"
              onChange={onChange}
              value={user.otp}
              ref={otpRef}
              maxLength={6}
              required
            />
          )}
          <button
            ref={continueBtnRef}
            className="btn-10 custom-btn otp-btn"
            onClick={onContinueClick}
            type="submit"
            disabled={isLoading} // Disable button while loading
          >
            {isLoading ? (
              <span className="loader">wait..</span>
            ) : otpVisible ? (
              "Submit"
            ) : (
              "Get OTP"
            )}
          </button>
        </div>
        <div className="button-container">
          <button
            type="submit"
            className="btn-10 custom-btn_back"
            onClick={() => {
              setPage(page - 1);
            }}
          >
            Back
          </button>
          <button
            ref={nextBtnRef}
            type="submit"
            onClick={handleSubmit}
            className="btn-10 next-btn"
            style={{ display: "none" }}
          >
            Next
          </button>
        </div>
      </form>
    </>
  );
};

export default EmailLogin;
