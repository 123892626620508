import React from "react";
import krishna from "../../../../assets/images/compi/samay.jpg";
import Sanya from "../../../../assets/images/compi/sanya.jpg";
import Barka from "../../../../assets/images/compi/barka.jpg";
import Search from "../../../../assets/images/compi/seacrch.svg";
import Nawa from "../../../../assets/images/compi/nawa.jpg";
import Bhoomi from "../../../../assets/images/compi/ayushman.jpg";
import Kenny from "../../../../assets/images/compi/kenny.jpg";
import Farah from "../../../../assets/images/compi/farah.jpg";

const HellofFame = () => {
  return (
    <div className="hall_container">
      <p className="hall_heading">HALL OF FAME</p>
      <div className="hall_con_main">
        <div className="hall_main samay">
          <img src={krishna} alt="hall" className="hall_logo" />
          <div className="hall_head">
            <p>Samay Raina</p>
          </div>
        </div>
        <div className="hall_main barkha">
          <img src={Barka} alt="hall" className="hall_logo_a" />
          <div className="hall_head barkha_head">
            <p>Barkha Singh</p>
          </div>
        </div>
        <div className="hall_main sanya">
          <img src={Sanya} alt="hall" className="hall_logo" />
          <div className="hall_head sanya_head">
            <p>Sanya Malhotra</p>
          </div>
        </div>
        <div className="hall_main boomi">
          <img src={Bhoomi} alt="hall" className="hall_logo_a" />
          <div className="hall_head ext_boomi">
            <p>Ayushmann Khurrana </p>
          </div>
        </div>
        <div className="hall_main nawa">
          <img src={Nawa} alt="hall" className="hall_logo" />
          <div className="hall_head ext_nawa">
            <p>Nawazuddin siddique</p>
          </div>
        </div>
        <div className="hall_main farah">
          <img src={Farah} alt="hall" className="hall_logo" />
          <div className="hall_head">
            <p>Farah Khan</p>
          </div>
        </div>
        <div className="hall_main kenny">
          <img src={Kenny} alt="hall" className="hall_logo_a" />
          <div className="hall_head kenny_head">
            <p>Kenny Sebastian</p>
          </div>
        </div>
        <div className="hall-of-fame-container">
          <p className="hall-of-fame-text">Previous Year Participants</p>
          <img src={Search} alt="hall" className="hal_search" />
        </div>
      </div>
    </div>
  );
};

export default HellofFame;
