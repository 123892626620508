import React, { useEffect, useState } from "react";
import Axios from "axios";
import Cross from "../../../assets/images/accommodation/Cross.png";
import { jwtDecode } from "jwt-decode";
import IapMemberDetails from "./IapMemberDetails";

const IapTeamReg = ({ program, setShowIap }) => {
  const [team, setTeam] = useState(null);
  const [teamDetails, setTeamDetails] = useState([]);
  const [member, setMember] = useState({ mi_no: "" });
  const [submitDisabled, setSubmitDisabled] = useState(false);
  const [teamReg, setTeamReg] = useState([]);
  const [teamcon, setTeamCon] = useState([]);
  const [newSubmitDisabled, setNewSubmitDisabled] = useState(false);

  const [user, setUser] = useState(
    sessionStorage.getItem("miauthtoken")
      ? jwtDecode(sessionStorage.getItem("miauthtoken")).user
      : null
  );

  const doLeaderReg = () => {
    if (user) {
      Axios.post(
        "https://ashish.moodi.org/iap/leader_reg",
        {
          user: user.userId,
          program: program,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
        .then((res) => {
          setTeam(res.data.team);
          fetchTeamDetails(res.data.team);
        })
        .catch((err) => console.error(err));
    }
  };
  console.log(user.userId);
  const getRegProgram = () => {
    Axios.post(
      "https://ashish.moodi.org/iap/get_team_reg_con",
      {
        user: user.userId,
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
      .then((res) => setTeamCon(res.data))
      .catch((err) => console.error(err));
  };

  console.log("team", teamcon);

  const fetchTeamDetails = (teamId) => {
    Axios.post(
      "https://ashish.moodi.org/iap/iap_team_details",
      {
        team: teamId,
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
      .then((res) => {
        if (res.data.status === "DNE") {
          window.alert("Team does not exist!");
        } else {
          setTeamDetails(res.data);
        }
      })
      .catch((err) => console.error(err));
  };

  const onMemberAddClick = () => {
    setSubmitDisabled(true);
    if (
      teamDetails.find((element) => element.MI_NO === member.mi_no) ||
      member.mi_no === user.mi_no
    ) {
      window.alert("A user can be added only once in a team");
      setSubmitDisabled(false);
    } else {
      Axios.post(
        "https://ashish.moodi.org/iap/member_reg",
        {
          team: team,
          user: member.mi_no,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
        .then((res) => {
          if (res.data.status === "done") {
            setTeamDetails((current) => [
              ...current,
              {
                userName: res.data.userName,
                MI_NO: member.mi_no,
              },
            ]);
            setSubmitDisabled(false);
          } else {
            alert(res.data.status);
            setSubmitDisabled(false);
          }
        })
        .catch((err) => console.error(err));
    }
  };

  const onSubmitClick = () => {
    setNewSubmitDisabled(true);
    Axios.post(
      "https://ashish.moodi.org/iap/confirm_iap_team",
      {
        team: team,
        program: program,
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
      .then((res) => {
        if (res.data.status === "done") {
          setTeamReg((current) => [...current, { program: program }]);
          setNewSubmitDisabled(true);
          alert(
            `Team Registration successfull for ${program}!! You may close the pop-up window.`
          );
          setNewSubmitDisabled(true);
          window.location.reload();
        }
      })
      .catch((err) => console.error(err));
  };

  const onChange = (e) => {
    setMember({ ...member, [e.target.name]: e.target.value });
  };

  const handleiap = () => {
    setShowIap(false);
  };
  useEffect(() => {
    doLeaderReg();
    getRegProgram();
  }, []);

  return (
    <div className={`iap_team_s`}>
      <img src={Cross} onClick={handleiap} alt="cross" className="cross_iap" />
      <div className="ne_reg_container">
        <div className="ne_tem_a" style={{ alignItems: "flex-start" }}>
          <div className="tc_page_title" style={{ color: "#60BDBA" }}>
            TEAM REGISTRATION
          </div>
          <li>Only Team leader should register for this competition</li>
          <li>
            No other team member should register themselves as the Team Leader
          </li>
        </div>
        <div
          className="ne_main"
          style={{ alignItems: "flex-start", textAlign: "justify" }}
        >
          <div className="tc_left">
            <p
              className="labe_tem"
              style={
                window.innerWidth > 770
                  ? { fontSize: "2vw", margin: "0.5vh 0" }
                  : { fontSize: "5vw" }
              }
            >
              Add your team members
            </p>
            <p>
              Team limit: <span>{6}</span>
            </p>

            <div className="members_form">
              <label htmlFor="mi_no">Add New Members:</label>
              <input
                type="text"
                name="mi_no"
                id="mi_no"
                value={member.mi_no}
                onChange={onChange}
                placeholder="Enter the MI Number of the member"
                maxLength={11}
                style={{ color: "#60BDBA", fontWeight: "500" }}
              />
              {submitDisabled ? (
                <button className="member_add_btn" disabled>
                  Adding...
                </button>
              ) : (
                <button
                  className="member_add_btn"
                  onClick={onMemberAddClick}
                  style={{ background: "#A14225" }}
                >
                  Add
                </button>
              )}
            </div>
          </div>
          <div>
            <li style={{ color: "#60BDBA", marginTop: "1vh" }}>
              You need a team of atleast {2} people to participate in this
              competition
            </li>
          </div>
          <div className="tc_right">
            <div className="tc_right_title">Team Members</div>
            <div className="member_details">
              <p>{user ? user.userName : ""}</p>
              {user ? <p>(Leader)</p> : <></>}
            </div>
            {teamDetails.map((element) => {
              return (
                <IapMemberDetails
                  member={element}
                  teamDetails={teamDetails}
                  setTeamDetails={setTeamDetails}
                  team={team}
                />
              );
            })}

            {newSubmitDisabled ? (
              <button className="tc_submit_btn" disabled>
                Submiting...
              </button>
            ) : (
              <button
                className="tc_submit_btn"
                style={{ background: "#A14225" }}
                onClick={onSubmitClick}
                disabled={
                  teamDetails.length >= 2 - 1
                    ? teamDetails.length <= 6 - 1
                      ? false
                      : true
                    : true
                }
              >
                Submit
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default IapTeamReg;
