import React from "react";
import BlogPost from "./BlogPost";

import "./css/mymi.css";

const MyMI = ({user}) => {
  return (
    <div className="">
      <div className="sec_title">WHAT'S UP INDIGO?</div>
      <BlogPost user={user} />
    </div>
  );
};

export default MyMI;
