import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import genresData from "../../../../assets/constants/compi_genre"; // Import your genres
import Axios from "axios";
function GenreCards() {
  const [genres, setGenres] = useState([]);

  useEffect(() => {
    // Set genres from the imported file instead of fetching from API
    setGenres(genresData);
  }, []);

  return (
    <div className="genre-container">
      {genres.map((genre) => (
        <GenreCard
          key={genre.genreId}
          genreId={genre.genreId}
          genreName={genre.genreName}
          color={genre.colors} // Pass the colors as a prop
        />
      ))}
    </div>
  );
}

function GenreCard({ genreId, genreName, color }) {
  const [competitions, setCompetitions] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    // This is where you'd fetch competitions for the genre, keeping the logic as is.
    // If competitions are static or imported like genres, replace Axios call similarly.
    Axios.get(
      `https://ashish.moodi.org/competitions/get_genre_compi?genre=${genreId}`
    )
      .then((res) => setCompetitions(res.data))
      .catch((err) => console.error(err));
  }, [genreId]);

  return (
    <div
      className="genre-card"
      style={{
        backgroundColor: color?.background, // Safely access the color properties
        color: color?.text,
      }}
      onClick={() =>
        navigate(`/competitions/genre/${genreId}`, { state: { genreName } })
      }
    >
      <p className="genre_hed">{genreName}</p>
      <div className="genre_compi_container">
        <div className="genre_compi_list">
          {competitions
            .slice(0, Math.ceil(competitions.length / 2))
            .map((competition) => (
              <div className="genre_compi_info" key={competition.competitionId}>
                <p className="genre_compi_title">
                  {competition.competitionName}
                </p>
                <p className="genre_compi_subtitle">{competition.subtitle}</p>
              </div>
            ))}
        </div>
        <div className="genre_compi_list">
          {competitions
            .slice(Math.ceil(competitions.length / 2)) // Second half of competitions
            .map((competition) => (
              <div className="genre_compi_info" key={competition.competitionId}>
                <p className="genre_compi_title">
                  {competition.competitionName}
                </p>
                <p className="genre_compi_subtitle">{competition.subtitle}</p>
              </div>
            ))}
        </div>
      </div>
    </div>
  );
}

export default GenreCards;
