const streams = [
    "Arts",
    "Commerce",
    "Design",
    "Engineering",
    "Medical",
    "Management",
    "Natural Sciences",
    "Pharmacy",
    "Other",
];

const yearOfStudyOptions = [
    "Junior College",
    "12th Pass",
    "First",
    "Second",
    "Third",
    "Fourth",
    "Fifth",
];

const collegeData = { streams, yearOfStudyOptions };

export default collegeData;
