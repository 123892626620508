import React, { useState } from "react";
import Workshop from "./earnpoint/Worshop";
import Competition from "./earnpoint/Competition";
import Event from "./earnpoint/Event";
import Mcq from "./earnpoint/Mcq";
import SocialMedia from "./earnpoint/SocialMedia";
import Gamingsection from "./earnpoint/Gamingsection";
import "./css/PointProgress.css";

const Pointprogress = () => {
  const [currentView, setCurrentView] = useState("SocialMedia");

  const renderContent = () => {
    switch (currentView) {
      case "SocialMedia":
        return <SocialMedia />;
      case "Event":
        return <Event />;
      case "Workshop":
        return <Workshop />;
      case "Competition":
        return <Competition />;
      case "Gaming":
        return <Gamingsection />;
      default:
        return <SocialMedia />;
    }
  };

  return (
    <div className="point_progress">
      <div className="sec_title" style={{ borderRadius: "3vh 3vh 0vh 0vh" }}>
        EARN POINTS
      </div>
      <div className="options">
        <button 
          className={currentView === "SocialMedia" ? "active" : ""} 
          onClick={() => setCurrentView("SocialMedia")}
        >
          Social Media
        </button>
        <button 
          className={currentView === "Event" ? "active" : ""} 
          onClick={() => setCurrentView("Event")}
        >
          Event
        </button>
        <button 
          className={currentView === "Workshop" ? "active" : ""} 
          onClick={() => setCurrentView("Workshop")}
        >
          Workshop
        </button>
        <button 
          className={currentView === "Competition" ? "active" : ""} 
          onClick={() => setCurrentView("Competition")}
        >
          Competition
        </button>
        <button 
          className={currentView === "Gaming" ? "active" : ""} 
          onClick={() => setCurrentView("Gaming")}
        >
          Gaming
        </button>
      </div>
      <div>{renderContent()}</div>
    </div>
  );
};

export default Pointprogress;
