import React, { useEffect, useRef } from "react";
import Typed from "typed.js";
import ToDO from "../../../../assets/images/ccp/first_page_assets/to_do_list.svg";

const Todolist = () => {
  const typedTextRef = useRef(null);

  useEffect(() => {
    const typed = new Typed(typedTextRef.current, {
      strings: [
        "TO-DO LIST",
      ],
      typeSpeed: 70,
      backSpeed: 30,
      loop: true,
      showCursor: true,
      cursorChar: "_",
    });


    return () => {
      typed.destroy();
    };
  }, []);
  return (
    <div>
      <img className="To_do_list" src={ToDO} alt="todo" />
      <span ref={typedTextRef} className="text modified_todo"/>
    </div>
  );
};

export default Todolist;
