import React from "react";
import IndigoSquad from "./leaderboard/IndigoSquad";
import Leader_details from "./leaderboard/Leaderdetails";
import "./css/leaderboard.css"

const LeaderBoard = ({user}) => {
  console.log("ydfsfdsfsfar",user.userId)
  return (
    <div>
      <p className="sec_title">LeaderBoard</p>
      <IndigoSquad />
      <Leader_details user={user} />
    </div>
  );
};

export default LeaderBoard;
