import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import style from "./style.module.css";
import mi_logo from "../../assets/images/ccp/mood_logo.png";

const NavbarSec = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const homeNav = () => {
    navigate("/");
  };

  const paths = ["/multicities"];
  const newPaths = [
    "/multicities/cityinfo/Bengaluru",
    "/multicities/cityinfo/Puducherry",
    "/multicities/cityinfo/Jaipur",
    "/multicities/cityinfo/Delhi",
    "/multicities/cityinfo/Pune",
    "/multicities/cityinfo/Mumbai",
    "/multicities/cityinfo/Gwalior",
    "/multicities/cityinfo/Thrissur",
    "/multicities/cityinfo/Hyderabad",
    "/multicities/cityinfo/Kurukshetra",
    "/multicities/cityinfo/Nagpur",
    "/multicities/cityinfo/Chandigarh",
    "/multicities/cityinfo/Chhattisgarh",
    "/multicities/cityinfo/Puducherry",
    "/multicities/cityinfo/Bhagalpur",
    "/multicities/cityinfo/Ropar",
  ];
  const compipaths = ["/competitions"];
  const iap = ["/iap"];

  const isMulticit = paths.some((path) => location.pathname.startsWith(path));
  const isNewPath = newPaths.some((path) => location.pathname.startsWith(path));
  const isAccommodation = location.pathname === "/accommodation";
  const isCl = location.pathname === "/cl";
  const isIap = location.pathname === "/iap";
  const isCompi = compipaths.some((path) => location.pathname.startsWith(path)); // Add this

  const navbarClass = isAccommodation
    ? `${style.navbar_main} ${style.accommodation_color}`
    : isNewPath
    ? `${style.navbar_main} ${style.cl_color}`
    : isCl
    ? `${style.navbar_main} ${style.new_path}`
    : isCompi
    ? `${style.navbar_main} ${style.compi_color}`
    : isIap
    ? `${style.navbar_main} ${style.iap_color}` // Add this
    : isMulticit
    ? `${style.navbar_main} ${style.navbar_main_multicit}`
    : style.navbar_main;

  const logoName = isNewPath
    ? `${style.logo_main} ${style.new_path_a}`
    : isMulticit
    ? `${style.logo_main} ${style.logo_main_multicit}`
    : isIap
    ? `${style.logo_main} ${style.logo_main_iap}` // Add this
    : style.logo_main;

  const navbarContained = isNewPath
    ? `${style.navbar_contained} ${style.new_path_b}`
    : isMulticit
    ? `${style.navbar_contained} ${style.navbar_contained_multicit}`
    : isIap
    ? `${style.navbar_contained} ${style.navbar_contained_iap}` // Add this
    : style.navbar_contained;

  const getNavLinkClass = (path) => {
    const baseClass = isMulticit
      ? `${style.nav_link} ${style.nav_link_multicit}`
      : style.nav_link;

    const newPathClass = isNewPath
      ? `${baseClass} ${style.new_path_class}`
      : baseClass;

    return location.pathname === path
      ? `${newPathClass} ${style.active}`
      : newPathClass;
  };

  return (
    <div className={navbarClass}>
      <div className={logoName}>
        <img
          style={{ cursor: "pointer" }}
          src={mi_logo}
          alt="moodi logo"
          onClick={homeNav}
        />
        <p onClick={homeNav} style={{ cursor: "pointer" }}>
          Mood Indigo
        </p>
      </div>
      <div className={navbarContained}>
        <ul className={style.navbar_contained_list}>
          <li className={style.nav_items}>
            <a className={getNavLinkClass("/ccp/mymi")} href="/ccp/mymi">
              CCP
            </a>
          </li>
          <li className={style.nav_items}>
            <a className={getNavLinkClass("/multicities")} href="/multicities">
              MULTICITIES
            </a>
          </li>
          <li className={style.nav_items}>
            <a className={getNavLinkClass("/iap")} href="/iap">
              IAP
            </a>
          </li>
          <li className={style.nav_items}>
            <a className={getNavLinkClass("/cl")} href="/cl">
              CL
            </a>
          </li>
          <li className={style.nav_items}>
            <a
              className={getNavLinkClass("/accommodation")}
              href="/accommodation"
            >
              ACCOMMODATION
            </a>
          </li>
          <li className={style.nav_items}>
            <a
              className={getNavLinkClass("/competitions")}
              href="/competitions"
            >
              COMPETITIONS
            </a>
          </li>
          <li className={style.nav_items}>
            <a className={getNavLinkClass("/mi-merch")} href="/mi-merch">
              MI MERCH
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default NavbarSec;
