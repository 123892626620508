import React, { useState } from "react";
import Mostactive from "./Mostactive";
import Clubs from "./Clubs";
import Announcement from "./Announcement";
import Levelup from "./Levelup";
import WeeklyTarget from "./WeeklyTarget";
import WeeklyStreak from "./WeeklyStreak";
import LeaderBoardButton from "./LeaderboardButton";
import HelpSection from "./HelpSection";
import Help from "../../../assets/images/ccp/help.svg";

const DashboardRight = ({ page, setCurrentClub, currentclub, user }) => {
  // State to manage the visibility of HelpSection
  const [showHelp, setShowHelp] = useState(false);

  // Function to toggle HelpSection visibility
  const handleHelpClick = () => {
    setShowHelp(prev => !prev);
  };

  return (
    <div className="dashboard_right debug-border">
      {page === "threads" && <Mostactive club={currentclub} user={user} />}
      {page === "threads" && <Clubs setCurrentClub={setCurrentClub} />}
      {page === "mymi" && <Announcement />}
      {page === "mymi" && <Levelup />}
      {page === "earnpoints" && <WeeklyStreak />}
      {page === "earnpoints" && <WeeklyTarget />}
      {page === "leaderboards" && <LeaderBoardButton />}
      
      {/* Help Icon */}
      <img
        src={Help}
        alt="help"
        className="help_icon"
        onClick={handleHelpClick}
      />
      
      {/* Conditionally render HelpSection */}
      {showHelp && <HelpSection onClose={() => setShowHelp(false)} />}
    </div>
  );
};

export default DashboardRight;
