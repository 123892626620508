import React, { useState, useEffect, useRef } from "react";
import Axios from "axios";
import like from "../../../assets/images/ccp/like_a.svg";
import liked from "../../../assets/images/ccp/liked.svg";
import share from "../../../assets/images/ccp/share.svg";
const App = ({ user }) => {

  const [blogs, setBlogs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Fetch all blogs on component mount
  useEffect(() => {
    const apiUrl = "https://ashish.moodi.org/ccp/getblogs";

    const fetchData = async () => {
      try {
        const response = await fetch(apiUrl);
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const data = await response.json();
        setBlogs(data);
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  // Render loading or error state
  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error}</p>;

  return (
    <div className="blog_container">
      <div>
        {blogs.map((blog) => (
          <BlogCard key={blog.blogId} blog={blog} user={user} />
        ))}
      </div>
    </div>
  );
};

const BlogCard = ({ blog, user }) => {
  const [likeImg, setLikeImg] = useState(like);
  const [disabled, setDisabled] = useState(false);
  console.log("userid",user.userId);

  useEffect(() => {
    const getLikeStatus = () => {
      Axios.post(
        "https://ashish.moodi.org/ccp/getBlogLike",
        {
          blog: blog.blogId,
          user: user.userId,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
        .then((res) => {
          if (res.data.status === "liked") {
            setLikeImg(liked);
          } else {
            setLikeImg(like);
          }
        })
        .catch((err) => console.error(err));
    };

    getLikeStatus();
  }, [blog.blogId, user.userId]);

  const onLikeClick = () => {
    setDisabled(true);
    if (likeImg === liked) {
      setLikeImg(like);
      Axios.post(
        "https://ashish.moodi.org/ccp/removebloglike",
        {
          blog: blog.blogId,
          user: user.userId,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
        .then((res) => {
          if (res.data.status === "done") {
            setLikeImg(like);
            setDisabled(false);
          }
        })
        .catch((err) => console.error(err));
    } else {
      setLikeImg(liked);
      Axios.post(
        "https://ashish.moodi.org/ccp/addbloglike",
        {
          blog: blog.blogId,
          user: user.userId,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
        .then((res) => {
          if (res.data.status === "done") {
            setLikeImg(liked);
            setDisabled(false);
          }
        })
        .catch((err) => console.error(err));
    }
  };

  const onShareClick = async () => {
    if (window.innerWidth > 770) {
      await navigator.clipboard.writeText("https://my.moodi.org/ccp/mymi");
      alert("Link copied to clipboard");
    } else {
      navigator.share({
        title: `Blogs - My MI Portal`,
        url: window.location.href,
      });
    }
  };

  return (
    <div className="card_main">
      <div className="card">
        <div className="content">
          <div className="front">
            <p className="blog_title">{blog.blogTopic}</p>
            <div className="blog_image">
              <img
                src={`https://ashish.moodi.org/ccp/getImage?img=${blog.blogPic}`}
                alt={blog.blogTopic}
              />
            </div>
          </div>
          <div className="back">
            <div dangerouslySetInnerHTML={{ __html: blog.blogContent }} />
            <p className="posted">
              <span>Posted On:</span>{" "}
              {new Date(blog.postedOn).toLocaleDateString()}
            </p>
            <div className="activity_buttons" style={{width: "100%", display: "flex", justifyContent: "space-evenly"}}>
              <button disabled={disabled} style={{backgroundColor: "transparent", border: 'none'}}>
                <img style={{width: "3vw", color: "white"}} src={likeImg} alt="" onClick={onLikeClick} />
              </button>
              <img src={share} alt="" onClick={onShareClick} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default App;
