import React, { useEffect, useState } from "react";
import axios from "axios";

const Announcement = () => {
  const [announcements, setAnnouncements] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchAnnouncements = async () => {
      try {
        // Replace with your API endpoint
        const response = await axios.get(
          "https://ashish.moodi.org/ccp/get_annoucement"
        );
        const activeAnnouncements = response.data.filter(
          (announcement) => announcement.isActive
        );
        setAnnouncements(activeAnnouncements);
      } catch (err) {
        setError("Failed to fetch announcements.");
      } finally {
        setLoading(false);
      }
    };

    fetchAnnouncements();
  }, []);

  if (loading) {
    return <p>Loading announcements...</p>;
  }

  if (error) {
    return <p>{error}</p>;
  }

  return (
    <div className="announcement">
      <p className="announcement_heading">Announcements</p>
      {announcements.length > 0 ? (
        announcements.map((announcement) => (
          <div key={announcement.heading} className="announcement_item">
            <p className="announcement_contained">
              <strong>{announcement.heading} </strong>
              {announcement.content}
            </p>
            <p className="workshop_register">
              <strong>Register Now:</strong>{" "}
              <a
                className="ann_sop"
                href={announcement.RegLink}
                target="_blank"
                rel="noopener noreferrer"
              >
                {announcement.RegLink}
              </a>
            </p>
          </div>
        ))
      ) : (
        <p>No active announcements.</p>
      )}
    </div>
  );
};

export default Announcement;
