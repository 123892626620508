import React, { useState } from "react";
import closeIcon from "../../../assets/images/ccp/close.svg";

import levelone from "../../../assets/images/ccp/badges/level1.svg";
import leveltwo from "../../../assets/images/ccp/badges/level2.svg";
import levelthree from "../../../assets/images/ccp/badges/level3.svg";
import levelfour from "../../../assets/images/ccp/badges/level4.svg";
import levelfive from "../../../assets/images/ccp/badges/level5.svg";
import levelsix from "../../../assets/images/ccp/badges/level6.svg";
import levelseven from "../../../assets/images/ccp/badges/level7.svg";

const LeaderboardButton = () => {
  const [isPointsVisible, setPointsVisible] = useState(false);
  const [isRewardsVisible, setRewardsVisible] = useState(false);

  const togglePoints = () => {
    setPointsVisible((prevState) => !prevState);
  };

  const toggleRewards = () => {
    setRewardsVisible((prevState) => !prevState);
  };

  const closePoints = () => {
    setPointsVisible(false);
  };

  const closeRewards = () => {
    setRewardsVisible(false);
  };

  return (
    <div className="LeaderboardButton">
      <button onClick={togglePoints}>Points System</button>
      {isPointsVisible && (
        <div
          className="questions ext_question"
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            overflow: "hidden",
            zIndex: 16,
          }}
        >
          <div className="thread_form_header" style={{ width: "40vw" }}>
            <p className="guideline_heading">Point System</p>
            <img src={closeIcon} onClick={closePoints} alt="close" />
          </div>
          <table className="points-table">
            <thead>
              <tr>
                <th className="tables_h">Task Description</th>
                <th className="table_th">Points</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="td">Like and share on Facebook</td>
                <td className="td_a">20</td>
              </tr>
              <tr>
                <td className="td">Updating job profile on LinkedIn</td>
                <td className="td_a">60</td>
              </tr>
              <tr>
                <td className="td">Sharing the posts as Insta story</td>
                <td className="td_a">30</td>
              </tr>
              <tr>
                <td className="td">Same College Referral</td>
                <td className="td_a">40</td>
              </tr>
              <tr>
                <td className="td">Other College Referral</td>
                <td className="td_a">60</td>
              </tr>
              <tr>
                <td className="td">
                  If you start a thread and this thread is verified
                </td>
                <td className="td_a">100</td>
              </tr>
              <tr>
                <td className="td">Weekly Questions</td>
                <td className="td_a">20</td>
              </tr>
              <tr>
                <td className="td">Monthly workshops participation</td>
                <td className="td_a">150</td>
              </tr>
              <tr>
                <td className="td" style={{ borderRadius: "0px 0px 1vw 1vw" }}>
                  Upgradation of Level
                </td>
                <td
                  className="td_a"
                  style={{ borderRadius: "0px 0px 1vw 1vw" }}
                >
                  100
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      )}
      <button onClick={toggleRewards}>Rewards System</button>
      {isRewardsVisible && (
        <div
          className="questions ext_question hei_re"
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            overflow: "scroll",
          }}
        >
          <div className="thread_form_header" style={{ width: "40vw" }}>
            <p className="guideline_heading">Reward System</p>
            <img src={closeIcon} onClick={closeRewards} alt="close" />
          </div>
          <table className="rewards-table">
            <thead>
              <tr className="re_tr">
                <th>Level</th>
                <th>Ranks</th>
                <th>Badge</th>
                <th>Free Accommodation</th>
                <th>Concert Passes</th>
                <th>Gift Hampers</th>
                <th>Internships</th>
                <th>Ranked Marketing Cert.</th>
                <th>Unranked Marketing Cert.</th>
              </tr>
            </thead>
            <tbody>
              <tr className="re_tr">
                <td>Indigo Champion</td>
                <td>1-3</td>
                <td>
                  <img src={levelone} alt="leve" />
                </td>
                <td className="free_a" style={{ width: "9vw" }}>
                  Yes
                </td>
                <td>Yes</td>
                <td>Yes</td>
                <td>Yes</td>
                <td>Yes</td>
                <td>Yes</td>
              </tr>
              <tr className="re_tr">
                <td>Indigo Elite</td>
                <td>4-10</td>
                <td>
                  <img src={leveltwo} alt="leve" />
                </td>
                <td className="free_a" style={{ width: "9vw" }}></td>
                <td>Yes</td>
                <td>Yes</td>
                <td>Yes</td>
                <td>Yes</td>
                <td>yes</td>
              </tr>
              <tr className="re_tr">
                <td>Indigo Vanguard</td>
                <td>11-100</td>
                <td>
                  {" "}
                  <img src={levelthree} alt="leve" />
                </td>
                <td className="free_a" style={{ width: "9vw" }}></td>
                <td></td>
                <td>Yes</td>
                <td>Yes</td>
                <td>yes</td>
                <td>yes</td>
              </tr>
              <tr className="re_tr">
                <td>Indigo Catalyst</td>
                <td>101-200</td>
                <td>
                  <img src={levelfour} alt="leve" />
                </td>
                <td className="free_a" style={{ width: "9vw" }}></td>
                <td></td>
                <td></td>
                <td>yes</td>
                <td>yes</td>
                <td>yes</td>
              </tr>
              <tr className="re_tr">
                <td>Indigo Phoenix</td>
                <td>201-500</td>
                <td>
                  <img src={levelfive} alt="leve" />
                </td>
                <td className="free_a" style={{ width: "9vw" }}></td>
                <td></td>
                <td></td>
                <td></td>
                <td>yes</td>
                <td>yes</td>
              </tr>
              <tr className="re_tr">
                <td>Indigo Premier</td>
                <td>501-1000</td>
                <td>
                  <img src={levelsix} alt="leve" />
                </td>
                <td className="free_a" style={{ width: "9vw" }}></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td>yes</td>
              </tr>
              <tr className="re_tr ext_bd">
                <td>Indigo Beginner</td>
                <td>1001-end</td>
                <td>
                  <img src={levelseven} alt="leve" />
                </td>
                <td className="free_a" style={{ width: "9vw" }}></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

export default LeaderboardButton;
