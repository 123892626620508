import React from 'react'

const Gamingsection = () => {
  return (
    <div className="point_main">
      <p className="point_main_heading">Gaming</p>
      <p>Coming Soon    </p>
    </div>
  )
}

export default Gamingsection
