import React, { useState } from "react";
import Axios from "axios";
import Filter from "bad-words";
import badWords from "../../../assets/constants/badwords";
import closeIcon from "../../../assets/images/ccp/close.svg";


const AddThread = ({ club, user }) => {
  const [thread, setThread] = useState({ topic: "", content: "" });
  const [disabled, setDisabled] = useState(false);
  const [formVisible, setFormVisible] = useState(false);

  const filter = new Filter();
  filter.addWords(...badWords);

  const handleSubmit = (e) => {
    e.preventDefault();
    setDisabled(true);
    const formData = new FormData(e.target);
    formData.set("content", filter.clean(formData.get("content")));
    formData.set("topic", filter.clean(formData.get("topic")));
    formData.append("threadImage", e.threadImage);
    formData.append("club", club);
    formData.append("user", user.userId);
    Axios.post("https://ashish.moodi.org/ccp/addThread", formData, {
        headers: {
            "Content-Type": "multipart/form-data",
        },
    })
        .then((res) => {
            if (res.data === "Done") {
                window.alert("successfully add")
            } else {
              window.alert(res.data);
                alert(res.data);
            }
        })
        .catch((err) => console.error(err));
};


const onChange = (e) => {
    setThread({ ...thread, [e.target.name]: e.target.value });
};


  const openForm = () => {
    setFormVisible(true);
  };

  const closeForm = () => {
    setFormVisible(false);
  };

  return (
    <div>
      <p
        className="sec_title"
        style={{ borderRadius: "3vh 3vh 0vh 0vh", marginBottom: "0vh" }}
      >
        ADD A THREAD
      </p>
      <div className="add_thred">
        <p>{club}</p>
        <button onClick={openForm}>Add Thread</button>
      </div>
      {formVisible && (
        <div className="thread_form_container">
          <div className="thread_form_header">
            <p>New Thread</p>
            <img
              src={closeIcon}
              alt="Close"
              onClick={closeForm}
            />
          </div>
          <form className="thread_main" onSubmit={handleSubmit}>
            <div className="thread_form_group">
              <label htmlFor="topic">Thread Topic* : </label>
              <input
                type="text"
                name="topic"
                id="topic"
                value={thread.topic}
                onChange={onChange}
                required
              />
            </div>
            <div className="thread_form_group">
              <label htmlFor="content">Thread Content* : </label>
              <textarea
                id="content"
                name="content"
                value={thread.content}
                onChange={onChange}
                required
              />
            </div>
            <div className="thread_form_group">
              <label htmlFor="threadImage">Thread Image: </label>
              <input
                type="file"
                name="threadImage"
                id="threadImage"
                accept="image/*"
                required
              />
            </div>
            <input
              type="submit"
              className="thread_submit_btn"
              disabled={disabled}
              value="Submit"
            />
          </form>
        </div>
      )}
    </div>
  );
};

export default AddThread;
