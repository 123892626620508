import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { jwtDecode } from "jwt-decode";
import Axios from "axios";
import Mcq from "./Mcq";
import {
  FaInstagram,
  FaLinkedin,
  FaTwitter,
  FaFacebook,
  FaYoutube,
} from "react-icons/fa";
import insta from "../../../../assets/images/ccp/instagram-black.svg";
import facebook from "../../../../assets/images/ccp/fb-black.svg";
import twitter from "../../../../assets/images/ccp/linked.png";

const SocialMedia = () => {
  const navigate = useNavigate();

  const [user, setUser] = useState(
    sessionStorage.getItem("miauthtoken")
      ? jwtDecode(sessionStorage.getItem("miauthtoken")).user
      : undefined
  );

  const [sma, setSma] = useState({ shareLink: "" });
  const [instaImage, setInstaImage] = useState(null);
  const [questions, setQuestions] = useState([]);
  const [fbImage, setFbImage] = useState(null);
  const [linkedInImage, setLinkedInImage] = useState(null);
  const [disabled, setDisabled] = useState(false);

  useEffect(() => {
    if (user) {
      fetchQuestions();
    } else {
      navigate("/register");
    }
  }, [navigate, user]);

  const fetchQuestions = () => {
    Axios.post(
      "https://ashish.moodi.org/ccp/get_mcq_question",
      { user: user.userId },
      { headers: { "Content-Type": "application/json" } }
    )
      .then((res) => setQuestions(res.data))
      .catch((err) => console.error(err));
  };

  const onChange = (e) => {
    setSma({ ...sma, [e.target.name]: e.target.value });
  };

  const onSubmitClick = (e) => {
    e.preventDefault();
    setDisabled(true);
    Axios.post(
      "https://ashish.moodi.org/ccp/sma",
      {
        user: user.userId,
        insta: instaImage ? 1 : 0,
        fb: fbImage ? 1 : 0,
        linkedIn: linkedInImage ? 1 : 0,
      },
      { headers: { "Content-Type": "application/json" } }
    ).then((res) => {
      if (res.data.status === "done") {
        window.alert("Proof Submitted Successfully");
        setDisabled(false);
        window.location.reload();
      } else {
        window.alert("Not Submitted Successfully");
        setDisabled(false);
      }
    });
  };

  return (
    <div className="social_main">
      <div className="point_main" style={{ borderRadius: "3vh 3vh 0vh 0vh" }}>
        <p className="point_main_heading">Task</p>
        <p className="task_description">
          Share Mood Indigo's latest posts on your Social Media and submit
          proofs to get CCP points as rewards
        </p>
        <div className="social_form">
          <p>Add Link to your story or post</p>
          <form className="upload_form" onSubmit={onSubmitClick}>
            <div className="upload_form_group">
              <input
                type="text"
                name="shareLink"
                id="shareLink"
                placeholder="Provide link of post or story"
                value={sma.shareLink}
                onChange={onChange}
                required
              />
            </div>

            <div className="upload_form_group">
              <div>
                <label htmlFor="instaShare" className="custom-file-upload">
                  <img src={insta} style={{ width: "1.7vw" }} alt="" />{" "}
                  Instagram Share
                </label>
                <input
                  onChange={(e) => setInstaImage(e.target.files[0])}
                  type="file"
                  accept="image/*"
                  name="instaShare"
                  id="instaShare"
                  required
                />
              </div>
              <div>
                <label htmlFor="instaShare" className="custom-file-upload">
                  <img src={facebook} style={{ width: "1.7vw" }} alt="" />{" "}
                  Facebook Share
                </label>
                <input
                  onChange={(e) => setFbImage(e.target.files[0])}
                  type="file"
                  accept="image/*"
                  name="fbShare"
                  id="fbShare"
                />
              </div>
              <div>
                <label htmlFor="instaShare" className="custom-file-upload">
                  <img src={twitter} style={{ width: "1.7vw" }} alt="" />{" "}
                  LinkedIn Share
                </label>
                <input
                  onChange={(e) => setLinkedInImage(e.target.files[0])}
                  type="file"
                  accept="image/*"
                  name="linkedInShare"
                  id="linkedInShare"
                />
              </div>
            </div>
            <div style={{ marginBottom: "0.5vh" }}>
              <input
                type="submit"
                className="submit_btn"
                disabled={disabled}
                value={"Submit"}
              />
            </div>
          </form>
        </div>
      </div>

      <div className="soc_link">
        <a
          target="_blank"
          rel="noreferrer"
          href="https://www.instagram.com/iitbombay.moodi?igsh=MTNmam9pY3Z1YW93aQ=="
        >
          <FaInstagram className="icon_hover" color="black" size={30} />
        </a>
        <a
          target="_blank"
          rel="noreferrer"
          href="https://www.linkedin.com/company/mood-indigo/"
        >
          <FaLinkedin className="icon_hover" color="black" size={30} />
        </a>
        <a
          target="_blank"
          rel="noreferrer"
          href="https://x.com/iitb_moodi?t=tyaVKeWlXXTcc9qIerNTkQ&s=08"
        >
          <FaTwitter className="icon_hover" color="black" size={30} />
        </a>
        <a
          target="_blank"
          rel="noreferrer"
          href="https://www.facebook.com/iitbombay.moodi?mibextid=ZbWKwL"
        >
          <FaFacebook className="icon_hover" color="black" size={30} />
        </a>
        <a
          target="_blank"
          rel="noreferrer"
          href="https://www.youtube.com/@iitbmoodindigo"
        >
          <FaYoutube className="icon_hover" color="black" size={30} />
        </a>
      </div>
      <div>
        {questions.map((question) => (
          <Mcq key={question.qid} question={question} user={user} />
        ))}
      </div>
    </div>
  );
};

export default SocialMedia;
