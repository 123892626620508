import React, { useState, useEffect } from "react";
import "../style.css";
import Get_certified from "../../../../assets/images/ccp/certified_img.svg";
import Leader from "../../../../assets/images/ccp/leader.png";
import Internship from "../../../../assets/images/ccp/internship.jpg";
import CcpPoints from "../../../../assets/images/ccp/CcpPoints.png";
import Experience from "../../../../assets/images/ccp/experience.svg";
import gsap from "gsap";

const ThirdPage = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isAutoSliding] = useState(true);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const originalCcpContent = [
    {
      id: 1,
      content:
        "Get an Organizational Certificate from Mood Indigo, IIT Bombay after completion of tenure.",
      content_image: Get_certified,
      content_heading: "GET CERTIFIED",
      width: "20vw",
    },
    {
      id: 2,
      content:
        "Embark on a Transformational Journey! Explore Boundless Possibilities and Propel Your Future with Our Internship Program!",
      content_image: Internship,
      content_heading: "INTERNSHIPS",
      width: "10vw",
    },
    {
      id: 3,
      content:
        "Earn CCP Points and get Free Accommodation, Special Entry to Events at Mood Indigo, Merchandise, Video Courses and many more.",
      content_image: CcpPoints,
      content_heading: "WIN EXCITING REWARDS",
      width: "15vw",
    },
    {
      id: 4,
      content:
        "Become the face of Mood Indigo in your college and lead the team.",
      content_image: Leader,
      content_heading: "BE A LEADER !",
      width: "20vw",
    },
    {
      id: 5,
      content:
        "Become the Architect of Unforgettable Experiences with Mood Indigo and curate extraordinary events in your city.",
      content_image: Experience,
      content_heading: "unleash YOUR creativity",
      width: "25vw",
    },
  ];

  const smallWindowCcpContent = [
    {
      id: 1,
      content:
        "Get an Organizational Certificate from Mood Indigo, IIT Bombay after completion of tenure.",
      content_image: Get_certified,
      content_heading: "GET CERTIFIED",
      width: "45vw",
    },
    {
      id: 2,
      content:
        "Embark on a Transformational Journey! Explore Boundless Possibilities and Propel Your Future with Our Internship Program!",
      content_image: Internship,
      content_heading: "INTERNSHIPS",
      width: "20vw",
    },
    {
      id: 3,
      content:
        "Earn CCP Points and get Free Accommodation, Special Entry to Events at Mood Indigo, Merchandise, Video Courses and many more.",
      content_image: CcpPoints,
      content_heading: "WIN EXCITING REWARDS",
      width: "30vw",
    },
    {
      id: 4,
      content:
        "Become the face of Mood Indigo in your college and lead the team.",
      content_image: Leader,
      content_heading: "BE A LEADER !",
      width: "50vw",
    },
    {
      id: 5,
      content:
        "Become the Architect of Unforgettable Experiences with Mood Indigo and curate extraordinary events in your city.",
      content_image: Experience,
      content_heading: "unleash YOUR creativity",
      width: "50vw",
    },
  ];

  const ccp_content =
    windowWidth < 800 ? smallWindowCcpContent : originalCcpContent;

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    const animateSlide = () => {
      const tl = gsap.timeline();

      tl.fromTo(
        ".sliding",
        {
          opacity: 0,
          x: 100,
        },
        {
          opacity: 1,
          x: 0,
          ease: "power4.out",
          duration: 1,
        }
      )
        .fromTo(
          ".content_image",
          {
            opacity: 0,
            scale: 0.8,
            x: -100,
          },
          {
            opacity: 1,
            scale: 1,
            x: 0,
            ease: "power4.out",
            duration: 1,
            delay: -1,
          }
        )
        .fromTo(
          ".animation",
          {
            opacity: 0,
            y: 20,
          },
          {
            opacity: 1,
            y: 0,
            ease: "power4.out",
            duration: 1,
            delay: -0.5,
          }
        );
    };

    animateSlide();

    let intervalId;
    if (isAutoSliding) {
      intervalId = setInterval(() => {
        setCurrentIndex((prevIndex) =>
          prevIndex === ccp_content.length - 1 ? 0 : prevIndex + 1
        );
        animateSlide();
      }, 3000);
    }

    return () => clearInterval(intervalId);
  }, [currentIndex, isAutoSliding, ccp_content.length]);

  const nextSlide = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === ccp_content.length - 1 ? 0 : prevIndex + 1
    );
  };

  const prevSlide = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? ccp_content.length - 1 : prevIndex - 1
    );
  };

  const goToSlide = (slideIndex) => {
    setCurrentIndex(slideIndex);
  };

  return (
    <div className="ThirdPage_main">
      <div className="Curve_main">
        <p className="ThirdPage_main_heading">WHY CCP?</p>
      </div>
      <div className="ThirdPage_animation_main">
        <div className="ThirdPage_animation_main_a">
          <p className="sliding">{ccp_content[currentIndex].content}</p>
          <button className="prev_button" onClick={prevSlide}>
            &#10094;
          </button>
        </div>
        <div className="ThirdPage_animation_main_b">
          <p className="content_heading sliding">
            {ccp_content[currentIndex].content}
          </p>
          <img
            src={ccp_content[currentIndex].content_image}
            alt={ccp_content[currentIndex].content_heading}
            style={{ width: ccp_content[currentIndex].width }}
            className="content_image"
          />
        </div>
        <div className="ThirdPage_animation_main_c">
          <p className="sliding">{ccp_content[currentIndex].content}</p>
          <button className="prev_button" onClick={nextSlide}>
            &#10095;
          </button>
        </div>
      </div>
      <div className="dot_container">
        {ccp_content.map((item, index) => (
          <div
            key={item.id}
            className={`dot ${index === currentIndex ? "active_dot" : ""}`}
            onClick={() => goToSlide(index)}
          ></div>
        ))}
      </div>
      <div className="Curve_main_b">
        <p className="ThirdPage_main_heading_b animation">
          {ccp_content[currentIndex].content_heading}
        </p>
      </div>
    </div>
  );
};

export default ThirdPage;
