import React, { useState } from "react";
import Axios from "axios";

const Mcq = ({ question, user }) => {
  const [response, setResponse] = useState({});
  const [disabled, setDisabled] = useState(false);

  const onChange = (e) => {
    setResponse({ ...response, [e.target.name]: e.target.value });
  };

  const onSubmit = (e) => {
    e.preventDefault();
    setDisabled(true); // Disable button to prevent duplicate submissions

    const data = {
      question: question.qid,
      answer: question[`option${response[`answer_${question.qid}`]}`],
      user: user.userId,
      response: response[`answer_${question.qid}`],
    };

    console.log("Data to be sent:", data);

    Axios.post("https://ashish.moodi.org/ccp/mcq_response", data, {
      headers: { "Content-Type": "application/json" },
    })
      .then((res) => {
        console.log("Response from server:", res.data);
        if (res.data.status === "done") {
          window.alert("Response Submitted Successfully!");
        } else {
          window.alert("Not Submitted Successfully!");
          window.alert(res.data.status);
          setDisabled(false);
        }
      })
      .catch((err) => {
        console.error("Error:", err);
        window.alert("An error occurred during submission. Please try again.");
        setDisabled(false); 
      });
  };

  return (
    <div className="point_main" key={question.qid}>
      <p className="point_main_heading">Question:</p>
      <p className="task_description">{question.question}</p>
      <form className="mcq_options" onSubmit={onSubmit}>
        <div className="radio_group">
          <input
            type="radio"
            name={`answer_${question.qid}`}
            id={`option1_${question.qid}`}
            value={1}
            onChange={onChange}
            disabled={disabled} // Disable radio buttons when submit is in progress
          />
          <label htmlFor={`option1_${question.qid}`}>{question.option1}</label>
        </div>
        <div className="radio_group">
          <input
            type="radio"
            name={`answer_${question.qid}`}
            id={`option2_${question.qid}`}
            value={2}
            onChange={onChange}
            disabled={disabled}
          />
          <label htmlFor={`option2_${question.qid}`}>{question.option2}</label>
        </div>
        <div className="radio_group">
          <input
            type="radio"
            name={`answer_${question.qid}`}
            id={`option3_${question.qid}`}
            value={3}
            onChange={onChange}
            disabled={disabled}
          />
          <label htmlFor={`option3_${question.qid}`}>{question.option3}</label>
        </div>
        <div className="radio_group">
          <input
            type="radio"
            name={`answer_${question.qid}`}
            id={`option4_${question.qid}`}
            value={4}
            onChange={onChange}
            disabled={disabled}
          />
          <label htmlFor={`option4_${question.qid}`}>{question.option4}</label>
        </div>
        <input type="submit" className="submit_btn" disabled={disabled} />
      </form>
    </div>
  );
};

export default Mcq;
