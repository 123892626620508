import React, { useEffect, useRef, useState } from "react";
import PersonalInfo from "./PersonalInfo";
import CollegeInfo from "./CollegeInfo";
import SocialInfo from "./SocialInfo";
import LoginInfo from "./LoginInfo";
import EmailLogin from "./EmailLogin";
import Avatar from "./Avatar";
import "./registration.css";
import RandomAssetsPage from "./Random_assets";
import Helps from "../../small_component/Helps";

function RegistrationPage() {
  const params = new URLSearchParams(window.location.search);
  const redirect = params.get("redirect");

  const nextBtnRef = useRef(null);
  const [page, setPage] = useState(0);

  useEffect(() => {
    console.log(redirect);
  }, []);

  const [user, setUser] = useState({
    email: "",
    password: "",
    otp: "",
    googleId: "",
    profilePic: "",
    name: "",
    dob: "",
    phone: "",
    gender: "",
    state: "",
    city: "",
    college: "",
    stream: "",
    yearOfStudy: "",
    referral: "",
    insta: "",
    twitter: "",
    image: "",
  });

  const FormTitles = [
    "",
    "",
    "About You",
    "About Your College",
    "Choose Your Avatar",
    "Social Media",
    "Interests",
  ];

  const PageDisplay = () => {
    switch (page) {
      case 0:
        return (
          <LoginInfo
            user={user}
            setUser={setUser}
            page={page}
            setPage={setPage}
            redirect={redirect}
          />
        );
      case 1:
        return (
          <EmailLogin
            nextBtnRef={nextBtnRef}
            user={user}
            setUser={setUser}
            page={page}
            setPage={setPage}
            redirect={redirect}
          />
        );
      case 2:
        return (
          <PersonalInfo
            user={user}
            setUser={setUser}
            page={page}
            setPage={setPage}
            redirect={redirect}
          />
        );
      case 3:
        return (
          <CollegeInfo
            user={user}
            setUser={setUser}
            page={page}
            setPage={setPage}
            redirect={redirect}
          />
        );
      case 4:
        return (
          <Avatar
            user={user}
            setUser={setUser}
            page={page}
            setPage={setPage}
            redirect={redirect}
          />
        );
      default:
        return (
          <SocialInfo
            user={user}
            setUser={setUser}
            page={page}
            setPage={setPage}
            redirect={redirect}
          />
        );
    }
  };

  return (
    <>
      <RandomAssetsPage />
      <Helps />
      <div className="registration_main">
        <div className="reg-form">
          {page > 0 && (
            <div className="form-title">
              <p>{FormTitles[page]}</p>
            </div>
          )}
          <div>{PageDisplay()}</div>
        </div>
      </div>
    </>
  );
}

export default RegistrationPage;
