import React from "react";
import HomeAccomadation from "./component/HomeAccomadation";
import IntroAcco from "./component/IntroAcco";
import AnimationAcco from "./component/AnimationAcco";

import "./css/accomadation.css";
const Index = () => {
  return (
    <div className="home_container">
      <HomeAccomadation />
      <AnimationAcco />
      <IntroAcco />
    </div>
  );
};

export default Index;
