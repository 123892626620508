import React, { useEffect, useRef } from 'react';
import { gsap } from 'gsap';
import './animation.css'; 

const TolistTextAnimation = ({ text, direction, style }) => {
  const textRef = useRef(null);
  const containerRef = useRef(null);

  useEffect(() => {
    const textWidth = textRef.current.offsetWidth;
    const containerWidth = containerRef.current.offsetWidth;

    gsap.fromTo(
      textRef.current,
      { x: direction === 'left' ? containerWidth : -textWidth },
      {
        x: direction === 'left' ? -textWidth : containerWidth,
        duration: (textWidth + containerWidth) / 200, // Adjust this value to control speed
        ease: 'none',
        repeat: -1,
      }
    );
  }, [direction, text]);

  return (
    <div className="scroll-container" ref={containerRef}>
      <div 
        className={`scroll-text ${direction === 'right' ? 'right-direction' : ''}`} 
        ref={textRef}
        style={style}
      >
        {text}
        {text}
        {text}
        {text}
        {text}
        {text}
        {text}
        {text}
        {text}
        {text}
      </div>
    </div>
  );
};

export default TolistTextAnimation;
