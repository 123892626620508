import React, { useEffect, useRef, useState } from "react";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import aditi from "../../../../assets/images/ccp/testimonials/aditi.jpg";
import rohan from "../../../../assets/images/ccp/testimonials/rohan.jpg";
import rudra from "../../../../assets/images/ccp/testimonials/Rudra.jpg";
import polygon from "../../../../assets/images/ccp/polygon.svg";
import last_image_test from "../../../../assets/images/ccp/testimonials/last_image_page.jpg";
import errow from "../../../../assets/images/ccp/errow.svg";

gsap.registerPlugin(ScrollTrigger);

const Testimonials = () => {
  const [currentTestimonial, setCurrentTestimonial] = useState(0);
  const [currentTestimonial_a, setCurrentTestimonial_a] = useState(0);
  const [arrowDirection, setArrowDirection] = useState("right");
  const containerRef = useRef(null);
  const contentRef1 = useRef(null);
  const contentRef2 = useRef(null);
  const arrowRef = useRef(null);

  const testimonials = [
    {
      name: "Aditi Chhajed",
      image: aditi,
      text: `“My experience at Mood Indigo, IIT Bombay, was truly unforgettable! The event exceeded my expectations in every way - from the thrilling competitions to the incredible concerts, everything was top-notch. CCP helped me a lot to acquire team leading skills and how to interact with new people. The team's organization and execution was flawless and I had a blast working with them. I learned so much and made amazing memories. I can't wait to come back again and create more memories.”`,
    },
    {
      name: "Rudraksh",
      image: last_image_test,
      text: `“Attending Mood Indigo at IIT Bombay last year was an unforgettable experience!  From the electrifying music concerts to the captivating dance performances, every event was meticulously organized and executed to perfection. I particularly loved participating in the competitions, which were challenging and exhilarating. The vibrant atmosphere, enthusiastic participants, and the overall energy of the festival made it a truly amazing experience. Mood Indigo 2023 was a testament to IIT Bombay's ability to host an event that not only entertains but also inspires and brings people together in celebration of art and culture"`,
    },
  ];

  const testimonials_a = [
    {
      aname: "Nupur kapoor",
      aimage: rudra,
      atext: `“Mood Indigo'23 fest at IIT Bombay was an exhilarating experience for me. Stunning music concerts, energetic dance shows, thought-provoking literary events, and what not! The opportunity to see such super talented artists perform live was the cherry on top. I kept my energy throughout, and so did Mood Indigo.Apart from all this, their College Connect program helped me develop my communication, management and leadership skills, along with getting internship opportunity from such an esteemed platform. One should definitely opt for this College Connect program.Mood Indigo's lively and inclusive environment truly made my visit memorable and left me eagerly anticipating the next edition.”`,
    },
    {
      aname: "Rohan Sarwar",
      aimage: rohan,
      atext: `Mood Indigo, IIT Bombay's flagship event, exceeded all my expectations. The vibrant mix of competitions, events and concerts was remarkable. As an Indigo Squad member, I experienced outstanding management and execution, especially with the Mumbai Multicity - Nation wide eliminations hosted at our college! This journey not only honed my skills but also fostered incredible connections. I strongly recommend everyone to participate in the college connect program and be a vital part of this incredible festival.”`,
    },
  ];

  const handleErrowClick = () => {
    const directions = ["left", "right", "top", "bottom"];
    const randomDirection1 =
      directions[Math.floor(Math.random() * directions.length)];
    const oppositeDirections = {
      left: "right",
      right: "left",
      top: "bottom",
      bottom: "top",
    };
    const randomDirection2 = oppositeDirections[randomDirection1];

    const animateOut = (element, direction) => {
      gsap.to(element, {
        x: direction === "left" ? -100 : direction === "right" ? 100 : 0,
        y: direction === "top" ? -100 : direction === "bottom" ? 100 : 0,
        opacity: 0,
        duration: 0.5,
        onComplete: () => {
          gsap.fromTo(
            element,
            {
              x: direction === "left" ? 0 : direction === "right" ? -100 : 0,
              y: direction === "top" ? 100 : direction === "bottom" ? -100 : 0,
              opacity: 0,
            },
            {
              x: 0,
              y: 0,
              opacity: 1,
              duration: 0.5,
              ease: "power3.out",
            }
          );
        },
      });
    };

    animateOut(contentRef1.current, randomDirection1);
    animateOut(contentRef2.current, randomDirection2);

    setCurrentTestimonial((prevIndex) => (prevIndex + 1) % testimonials.length);
    setCurrentTestimonial_a(
      (prevIndex) => (prevIndex + 1) % testimonials_a.length
    );

    // Rotate and move the arrow
    if (window.innerHeight >= 700) {
      gsap.to(arrowRef.current, {
        rotation: arrowDirection === "right" ? 180 : 0,
        duration: 0.5,
        ease: "power3.out",
        onComplete: () => {
          setArrowDirection(arrowDirection === "right" ? "left" : "right");
        },
      });
    } else {
      gsap.to(arrowRef.current, {
        rotation: arrowDirection === "right" ? 180 : 0,
        x: arrowDirection === "right" ? -1100 : 0,
        duration: 0.5,
        ease: "power3.out",
        onComplete: () => {
          setArrowDirection(arrowDirection === "right" ? "left" : "right");
        },
      });
    }
  };

  useEffect(() => {
    if (window.innerWidth >= 700) {
      const elements = containerRef.current.querySelectorAll(".animate");
      elements.forEach((el) => {
        const direction = el.dataset.direction;
        const distance = 100;
        let fromX = 100,
          fromY = 100;

        switch (direction) {
          case "left":
            fromX = -distance;
            break;
          case "right":
            fromX = distance;
            break;
          case "top":
            fromY = -distance;
            break;
          case "bottom":
            fromY = distance;
            break;
          default:
            break;
        }

        gsap.fromTo(
          el,
          {
            opacity: 0,
            x: fromX,
            y: fromY,
          },
          {
            opacity: 1,
            x: 0,
            y: 0,
            duration: 0.5,
            stagger: 0.3,
            ease: "power3.out",
            scrollTrigger: {
              trigger: containerRef.current,
              start: "top 80%",
              end: "bottom-center",
              toggleActions: "play none none none",
              scrub: 1,
            },
          }
        );
      });
    } else {
      const elements = containerRef.current.querySelectorAll(".animate");
      elements.forEach((el) => {
        const direction = el.dataset.direction;
        const distance = 100;
        let fromX = 20,
          fromY = 30;

        switch (direction) {
          case "left":
            fromX = -distance;
            break;
          case "right":
            fromX = distance;
            break;
          case "top":
            fromY = -distance;
            break;
          case "bottom":
            fromY = distance;
            break;
          default:
            break;
        }

        gsap.fromTo(
          el,
          {
            opacity: 0,
            x: fromX,
            y: fromY,
          },
          {
            opacity: 1,
            x: 0,
            y: 0,
            duration: 0.5,
            stagger: 0.3,
            ease: "power3.out",
            scrollTrigger: {
              trigger: containerRef.current,
              start: "top 100%",
              end: "bottom +80%",
              toggleActions: "play none none none",
              scrub: 1,
            },
          }
        );
      });
    }
    ScrollTrigger.refresh();
  }, []);

  const { name, image, text } = testimonials[currentTestimonial];
  const { aname, aimage, atext } = testimonials_a[currentTestimonial_a];

  return (
    <div className="testimonials_page" ref={containerRef}>
      <div className="testimonials_heading animate" data-direction="top">
        <p>TESTIMONIALS</p>
      </div>
      <div
        className="testimonials_a animate"
        data-direction="left"
        style={{ marginLeft: "10%" }}
        ref={contentRef1}
      >
        <div className="aditi_image">
          <img src={image} alt={`${name} testimonials`} />
        </div>
        <div className="name_testimonials">
          <p>{name}</p>
        </div>
        <img className="polygon" src={polygon} alt="polygon" />
        <p>{text}</p>
      </div>
      <div
        className="testimonials_a testimonials_b animate"
        data-direction="right"
        style={{ marginRight: "10%" }}
        ref={contentRef2}
      >
        <div
          className="name_testimonials_modified animate"
          data-direction="bottom"
        >
          <p>{aname}</p>
        </div>
        <img
          className="polygon_modified animate"
          data-direction="right"
          src={polygon}
          alt="polygon modified"
        />
        <p className="testimonials_modified">{atext}</p>
        <div className="aditi_image rohan_extra animate" data-direction="left">
          <img className="aditi_image modified_extra_img" src={aimage} alt={aname} />
        </div>
        <img
          className="errow_modified animate"
          data-direction="bottom"
          src={errow}
          alt="errow"
          onClick={handleErrowClick}
          style={{ cursor: "pointer" }}
          ref={arrowRef}
        />
      </div>
    </div>
  );
};

export default Testimonials;
