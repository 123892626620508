import React from "react";

const WeeklyStreak = () => {
  return (
    <div className="weeklystreak">
      <p className="announcement_heading">Weekly Streak</p>
      <div className="weeklystreak_a">
        <div>
          <p className="weeklystreak_statistics">00</p>
          <p className="weeklystreak_text">CURRENT WEEKLY STREAK</p>
        </div>
        <div>
          <p className="weeklystreak_statistics">00</p>
          <p className="weeklystreak_text">HIGHEST WEEKLY STREAK</p>
        </div>
      </div>
    </div>
  );
};

export default WeeklyStreak;
