import Axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import CompiInfo from "./CompiInfo";
import { jwtDecode } from "jwt-decode";
import genresData from "../../../../assets/constants/compi_genre";
import {
  FaInstagram,
  FaLinkedin,
  FaTwitter,
  FaFacebook,
  FaYoutube,
} from "react-icons/fa";

import RollingAnimation from "../../Home/HomeComponents/RollingAnimation";
const GenrePage = () => {
  const navigate = useNavigate();
  const mulCitiesRef = useRef(null);
  const { genre } = useParams();
  const [competitions, setCompetitions] = useState([]);
  const [regCompi, setRegCompi] = useState([]);
  const [user, setUser] = useState(
    sessionStorage.getItem("miauthtoken")
      ? jwtDecode(sessionStorage.getItem("miauthtoken")).user
      : null
  );

  const genresObj = genresData.find((element) => element.genreId === genre);
  console.log(genresObj);
  const fetchGenreCompi = () => {
    Axios.get(
      `https://ashish.moodi.org/competitions/get_genre_compi?genre=${genre}`
    )
      .then((res) => setCompetitions(res.data))
      .catch((err) => console.error(err));
  };

  // onst genreObj = genres.find((element) => element.genreId === genre);

  const getUserRegCompis = () => {
    const data = {
      user: user.userId,
    };
    Axios.post("https://ashish.moodi.org/competitions/get_reg_compi", data, {
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => {
        if (res.data.length > 0) {
          setRegCompi(res.data);
        }
      })
      .catch((err) => console.error(err));
  };

  useEffect(() => {
    if (sessionStorage.getItem("miauthtoken")) {
      fetchGenreCompi();
      getUserRegCompis();
    } else {
      navigate(`/register?redirect=competitions/genre/${genre}`);
    }
  }, []);

  const lastpageRef = useRef(null);
  const text = [" MOOD INDIGO MOOD INDIGO"];
  const customStyle = {
    fontSize: "7vw",
    color: "rgba(255, 255, 255, 0.42)",
    fontWeight: "700",
    fontFamily: "lemonMilk",
    lineHeight: "1.18",
  };
  return (
    <div className="ne_genre">
      <div ref={mulCitiesRef}></div>
      <div
        className="ne_compi_main"
        style={{
          background: genresObj.colors.background,
        }}
      >
        <p
          className="ne_compi_head"
          style={{
            color:
              genresObj.genreName === "Fine Arts"
                ? "white"
                : genresObj.genreName === "Lifestyle"
                ? "white"
                : "initial",
          }}
        >
          {genresObj.genreName}
        </p>

        <div
          className="ne_compi_info"
          style={{
            background: "#fff",
            color: "#000",
          }}
        >
          {competitions.map((competition) => {
            return (
              <CompiInfo
                competition={competition}
                user={user}
                regCompi={regCompi}
                setRegCompi={setRegCompi}
              />
            );
          })}
        </div>
      </div>
      <div
        ref={lastpageRef}
        className="footer ext_page_6"
        style={{ background: "#29666e", bottom: "0vh" }}
      >
        <div className="footer_text_animation">
          <RollingAnimation
            style={customStyle}
            text={text}
            direction={"left"}
          />
        </div>
        <div
          className="footer_details ne_com_fo"
          style={{ background: "#21A9A4" }}
        >
          <p>
            Mood Indigo Office, Student Activity Center (SAC), IIT Bombay,
            Powai, Mumbai, Maharashtra - 400076
          </p>
          <div className="footer_icon">
            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.instagram.com/iitbombay.moodi?igsh=MTNmam9pY3Z1YW93aQ=="
            >
              <FaInstagram color="white" size={30} />
            </a>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.linkedin.com/company/mood-indigo/"
            >
              <FaLinkedin color="white" size={30} />
            </a>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://x.com/iitb_moodi?t=tyaVKeWlXXTcc9qIerNTkQ&s=08"
            >
              <FaTwitter color="white" size={30} />
            </a>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.facebook.com/iitbombay.moodi?mibextid=ZbWKwL"
            >
              <FaFacebook color="white" size={30} />
            </a>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.youtube.com/@iitbmoodindigo"
            >
              <FaYoutube color="white" size={30} />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GenrePage;
