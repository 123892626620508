import React from "react";
import "../css/animationAcco.css";
import { useNavigate } from "react-router-dom";

function AnimationAcco() {
  const navigate = useNavigate();
  const handleClnav = () => {
    navigate("/cl");
    window.scrollTo(0, 0);
  };

  const AnimationAcco = [
    {
      name: "INSTRUCTIONS",
      description: ["How to Avail"],
      description_a: "On Arrival",
      description_c: "For Accommodation outside IIT Bombay",
      description_d: "Things to Remember",
      detail: [
        "Everybody willing to avail accommodation in Mood Indigo must register here first and then proceed to further steps",
        "All participants have to contact the Contingent Leader (hereafter referred to as ‘CL’) of their college; the details of whom would be made available to you once the CL is decided. Information about your CL can be found here.",
        "The registration for Contingent Leader has started. If your college has no CL, you can register yourself at the CL Portal",
        "CL will have to finalise the contingent before the given deadline and within the allotted contingent limit",
      ],
      detail_a: [
        "You must first head to the Accommodation Desk in the Student Activity Center, (fondly and famously known as 'SAC') along with your college ID card",
        "Your CL must have the list of his/her entire contingent with their MI numbers beforehand, which he/she has to produce at the desk.",
        "Each student will be given accommodation only after it has been confirmed by the Hospitality Core Group Members through the CL of the respective college",
        "After that, you will be allotted your place of stay, and will be provided with your accommodation booklet.",
      ],
      detail_c: [
        "You would be provided with complete details of your place of stay well in time.",
        "You need to report to the hotel allotted.",
        "Your CL must have the list of his/her entire contingent with their MI numbers. beforehand, which he/she has to produce at the hotel reception.",
        "You will be provided with your accommodation booklets and kits in your room itself or at the reception.",
      ],
      detail_d: [
        "Owing to a large number of requests for accommodation, we are bound to provide it strictly on a shared basis.",
        "You will be given accommodation only after it has been confirmed by the Hospitality Core Group members of the festival, through the CL of your college.",
      ],
      bgColor: "green",
      textColor: "#603A25",
      backGroundColor: "#F39C6B",
      div_a: "#603A25",
      rotation: 2.5,
    },
    {
      name: "GENERAL INFORMATION",
      description: "Charges",
      description_a: "Passes",
      description_c: "Getting into IIT Bombay",
      detail: [
        "Rs. 2799/- for 4 days and 4 nights (from 24th December 6 AM to 28th December 10 AM)",
        // "Rs 3999/- LEO1 premium accommodation for 4 days/4 nights (from 24th December 6 AM to 28th December 10 AM). Gain special entry to the concerts plus 4000 LEO1 coins (worth 4000) lifetime redeemable with the LEO1 card, making your stay entirely free.",
      ],
      detail_a: [
        "For people with confirmed accommodation through Mood Indigo:",
        "Mood Indigo will provide you with a booklet and a band and they will act as pass to all the events including concerts.",
        "For everyone else:",
        "For All four days: Passes need to be collected from Hospitality Desk, Student Activity Center (SAC). Distribution of passes begins at 10 AM.",
      ],
      detail_c: [
        "The modes of travelling in Mumbai are taxis, auto rickshaws, local train and buses.",
        "IIT Bombay is located at Powai, which is an eastern suburb in the North-Eastern part (Central Railway Line) of Mumbai.",
        "Kanjur Marg, a Local Train Station is the closest local train stop to IIT Bombay. It is located on the Central Railway line. An auto rickshaw from Kanjur-Marg station to IIT Bombay Main Gate costs Rs.45 approx.",
        "Thane is the nearest national railway station to IIT Bombay. Next is Lokmanya Tilak Terminus (LTT) (near Kurla) and Dadar is third in this regard.",
        "With respect to mornings at Kanjurmarg station, travelling from Mumbai CST, Dadar or Kurla would be less crowded than travelling from Kalyan or Thane.",
        "For buses/auto rickshaws, the destination should be stated as IIT Main Gate, Powai.",
        "Please download m-indicator mobile application for hands-on maps & routes of Mumbai.",
      ],
      bgColor: "blue",
      textColor: "#2e6f5c",
      backGroundColor: "#A1FCDF",
      div_a: "#2E6F5C",
      rotation: -2.5,
    },
    {
      name: "CONTINGENT LEADER",
      description: "Your college does not have a CL yet?",
      detail:
        "CL acts as a representative for all accommodation related query between MI and his college, thus conveying all Mood Indigo information to students of his/her college. The CL gets a certificate of appreciation from Mood Indigo, IIT Bombay after completion of his/her duties along with many more exciting incentives.",
      bgColor: "yellow",
      textColor: "#603A25",
      backGroundColor: "#F39C6B",
      div_a: "#603A25",
      rotation: -2.5,
    },
    {
      name: "FREQUENTLY ASKED QUESTIONS",
      description:
        "What is the procedure for the online payment of accommodation fee?",
      description_a: "When will my accommodation be confirmed?",
      description_c: "Where will the accommodation be provided?",
      description_d:
        "Will the team members be given accommodation at the same place?",
      description_e:
        "We have no cultural committee to nominate a CL & we are a group of 4-5 friends who want to attend the events. How do we get accommodation?",
      description_f:
        "Does the accommodation fee include the food facility as well?",
      description_g: "What all does the accommodation facilities include?",
      description_h:
        "We are a group of friends not participating in any of the competitions and just coming to MI to have fun. Would we be allowed?",
      description_i:
        "What is the procedure to be followed after we reach IIT Bombay?",
      description_j:
        "I am not travelling with my college contingent and will be arriving separately. What should I do?",
      description_k: "From when to when we are going to get accommodation?",
      description_l: "Where are we going to stay?",
      detail:
        "Once a particular contingent is finalised and their accommodation is confirmed by Hospitality and PR Heads of Mood Indigo, each and every person with confirmed accommodation will be given a link to the payment portal where he/she needs to make the payment online.",
      detail_a:
        "You first need to register for Mood Indigo and get your MI number. Your accommodation will then be confirmed by the Hospitality Heads of Mood Indigo. The confirmation for the same will be communicated to the CL, who will convey the same to you and you will also receive a mail regarding it by the first week of December.",
      detail_c:
        "Accommodation will be provided to boys and girls in well-secured separate residential complexes on the campus of IIT Bombay, or somewhere near the campus. There will be separate accommodation complexes for girls and boys to ensure safety of all the visitors.",
      detail_d:
        "We will try our best to provide you with this arrangement but no confirmation can be given. We request to not ping the Hospitality team for the same.",
      detail_e:
        "You should nominate a CL/ACL amongst yourselves, get it approved from the college authorities and then proceed in the same manner.",
      detail_f:
        "No. The accommodation charges don’t include food. However, there will be food courts operational during Mood Indigo to cater to the food requirements.",
      detail_g:
        "The accommodation cost includes staying within the campus during the festival, direct entry to concerts without passes, Hospitality kit, entry to afternites.",
      detail_h:
        "Yes, you can definitely attend MI by registering from your respective colleges. However, for accommodation, you can contact the CL of your college and fill the accommodation request. If your college doesn't have any CL, apply for the same . Accommodation would be confirmed strictly subject to availability.",
      detail_i:
        "You need to come to the Accommodation Desk, Students Activity Centre (SAC) along with your college identity card. You will be allotted your place to stay on campus and given a registration booklet. In the case of a big contingent, a contingent leader needs to present the ID cards of all the people in his contingent at the accommodation desk along with a list of all the people. Accommodation would be strictly on a shared basis.",
      detail_j:
        "You need to proceed with the registration in a similar way. Irrespective of your time of arrival, your CL will have to register and ensure complete payment of his/her contingent. As your registration at SAC would have been done by your CL you can then contact him/her and directly arrive at your place of stay.",
      detail_k:
        "Room allotment will start at the Accommodation desk, Students Activity Centre (SAC) at 6 AM on 24th December. You can start your stay in your room just after the allotment. You need to vacate the rooms before 10 AM on 28th December.",
      detail_l:
        "People who are getting accommodation inside IIT Bombay are going to stay in the hostel rooms of IIT Bombay.",
      bgColor: "pink",

      textColor: "#2e6f5c",
      backGroundColor: "#A1FCDF",
      div_a: "#2E6F5C",
      rotation: 2.5,
    },
  ];

  // const testiRef = useRef(null);
  // const regBtnRef = useRef(null);
  // useEffect(() => {
  //   const regBtn = regBtnRef.current;
  //   gsap.to(regBtn, {
  //     rotate: 360,
  //     duration: 8,
  //     repeat: -1,
  //   });
  //   let ctx = gsap.context(() => {
  //     let container = testiRef.current;
  //     var wipeAnimation = gsap.timeline();
  //     wipeAnimation
  //       .to(".card-pink", {
  //         x: 2000,
  //         rotate: 360,
  //         ease: "none",
  //         duration: 10,
  //         delay: 5,
  //       })
  //       .to(".card-blue", {
  //         x: 2000,
  //         rotate: 360,
  //         ease: "none",
  //         duration: 10,
  //       })
  //       .to(".card-green", {
  //         x: 2000,
  //         rotate: 360,
  //         ease: "none",
  //         duration: 10,
  //       })
  //       .to(".card-yellow", {
  //         x: 2000,
  //         rotate: 360,
  //         ease: "none",
  //         duration: 10,
  //       });
  //     ScrollTrigger.create({
  //       animation: wipeAnimation,
  //       trigger: container,
  //       start: "top top",
  //       end: "+=400%",
  //       pin: true,
  //       scrub: 1.5,
  //       snap: 1 / 4,
  //     });
  //   });
  //   return () => ctx.revert(); // <-- CLEANUP!
  // }, []);

  return (
    <div className="testimonial-page">
      <div className="AnimationAcco-container center">
        {AnimationAcco.map((testimonial) => {
          return (
            <div
              className={`testimonial-card card-${testimonial.bgColor} bg-${testimonial.bgColor} clr-${testimonial.textColor}`}
              style={{
                transform: `rotate(${testimonial.rotation}deg)`,
                backgroundColor: testimonial.backGroundColor,
              }}
              key={testimonial.name}
            >
              {/* Render different content for each section */}
              {testimonial.name === "INSTRUCTIONS" && (
                <div className="cont_div">
                  <div className="div_heading">
                    <p
                      style={{ color: testimonial.textColor }}
                      className="div_heading_a"
                    >
                      {testimonial.name}
                    </p>
                  </div>
                  <div className="cont_div_a">
                    <div className="div_cont_b">
                      <p className="iap_test_des">{testimonial.description}</p>
                      <p className="iap_test_des_a">
                        For the convenience of the enthusiastic participants of
                        Mood Indigo, we here in the Hospitality Department of MI
                        take it upon ourselves to provide you with a convenient
                        stay place during the festival. Owing to the huge scale
                        of participation, we have established a set of
                        procedural guidelines to help make the process seamless
                        and without glitches. Let’s Walk you through the
                        process!
                      </p>
                      <ul className="iap_test_des_a">
                        {testimonial.detail.map((item, index) => (
                          <li key={index}>{item}</li>
                        ))}
                      </ul>
                    </div>
                    <div className="div_cont_b" style={{ marginTop: "2vh" }}>
                      <p className="iap_test_des">
                        {testimonial.description_a}
                      </p>
                      <p className="iap_test_des_new">
                        For Accommodation inside IIT Bombay:
                      </p>
                      <ul className="iap_test_des_a">
                        {testimonial.detail_a.map((item, index) => (
                          <li key={index}>{item}</li>
                        ))}
                      </ul>

                      <p className="iap_test_des_new">
                        {testimonial.description_c}
                      </p>
                      <ul className="iap_test_des_a">
                        <li>
                          The registration for Contingent Leader has started. If
                          your college has no CL, you can register yourself at
                          <a
                            href="my.moodi.org/cl"
                            style={{ marginLeft: "1vw" }}
                          >
                            my.moodi.org/cl
                          </a>
                        </li>
                        {testimonial.detail_c.map((item, index) => (
                          <li key={index}>{item}</li>
                        ))}
                      </ul>
                    </div>

                    <div className="div_cont_b" style={{ marginTop: "2vh" }}>
                      <p className="iap_test_des">
                        {testimonial.description_d}
                      </p>
                      <ul className="iap_test_des_a">
                        {testimonial.detail_d.map((item, index) => (
                          <li key={index}>{item}</li>
                        ))}
                      </ul>
                    </div>
                  </div>
                </div>
              )}

              {testimonial.name === "CONTINGENT LEADER" && (
                <div className="cont_div">
                  <div className="div_heading">
                    <p
                      style={{ color: testimonial.textColor }}
                      className="div_heading_a"
                    >
                      {testimonial.name}
                    </p>
                  </div>
                  <div
                    className="div_cont_a"
                    style={{ backgroundColor: "#603A25" }}
                  >
                    <p className="iap_test_des">{testimonial.description}</p>
                    <p className="iap_test_des_a">{testimonial.detail}</p>
                    <button className="book_button" onClick={handleClnav}>
                      Become Cl
                    </button>
                  </div>
                </div>
              )}

              {testimonial.name === "GENERAL INFORMATION" && (
                <div className="cont_div">
                  <div className="cont_div_a">
                    <div
                      className="div_cont_b"
                      style={{
                        background: testimonial.div_a,
                        transform: "rotate(-1.5deg)",
                      }}
                    >
                      <p className="iap_test_des">{testimonial.description}</p>
                      <ul className="iap_test_des_a">
                        {testimonial.detail.map((item, index) => (
                          <li key={index}>{item}</li>
                        ))}
                      </ul>
                    </div>
                    <div
                      className="div_cont_b"
                      style={{
                        marginTop: "3vh",
                        background: testimonial.div_a,
                      }}
                    >
                      <p className="iap_test_des">
                        {testimonial.description_a}
                      </p>
                      <ul className="iap_test_des_a">
                        {testimonial.detail_a.map((item, index) => (
                          <li key={index}>{item}</li>
                        ))}
                      </ul>
                    </div>
                    <div
                      className="div_cont_b"
                      style={{
                        marginTop: "3vh",
                        background: testimonial.div_a,
                      }}
                    >
                      <p className="iap_test_des">
                        {testimonial.description_c}
                      </p>
                      <ul className="iap_test_des_a">
                        <li>
                          The following link may provide you a rough estimate of
                          the auto fares between two stations Taxi Auto Fare:{" "}
                          <a href="https://www.taxiautofare.com/1/Auto-fare-calculator/oid">
                            https://www.taxiautofare.com/1/Auto-fare-calculator/oid
                          </a>
                        </li>
                        {testimonial.detail_c.map((item, index) => (
                          <li key={index}>{item}</li>
                        ))}
                      </ul>
                    </div>
                  </div>
                  <div className="div_heading">
                    <p
                      style={{ color: testimonial.textColor }}
                      className="div_heading_a"
                    >
                      {testimonial.name}
                    </p>
                  </div>
                </div>
              )}

              {testimonial.name === "FREQUENTLY ASKED QUESTIONS" && (
                <div className="cont_div">
                  <div className="cont_div_a">
                    <div
                      className="div_cont_b"
                      style={{ backgroundColor: "#2E6F5C" }}
                    >
                      <p
                        className="iap_test_des_a"
                        style={{ fontWeight: "700" }}
                      >
                        {testimonial.description}
                      </p>
                      <p className="iap_test_des_a">{testimonial.detail}</p>
                    </div>
                    <div
                      className="div_cont_b"
                      style={{ marginTop: "2vh", backgroundColor: "#2E6F5C" }}
                    >
                      <p
                        className="iap_test_des_a"
                        style={{ fontWeight: "700" }}
                      >
                        {testimonial.description_a}
                      </p>
                      <p className="iap_test_des_a">{testimonial.detail_a}</p>
                    </div>
                    <div
                      className="div_cont_b"
                      style={{ marginTop: "2vh", backgroundColor: "#2E6F5C" }}
                    >
                      <p
                        className="iap_test_des_a"
                        style={{ fontWeight: "700" }}
                      >
                        {testimonial.description_c}
                      </p>
                      <p className="iap_test_des_a">{testimonial.detail_c}</p>
                    </div>
                    <div
                      className="div_cont_b"
                      style={{ marginTop: "2vh", backgroundColor: "#2E6F5C" }}
                    >
                      <p
                        className="iap_test_des_a"
                        style={{ fontWeight: "700" }}
                      >
                        {testimonial.description_d}
                      </p>
                      <p className="iap_test_des_a">{testimonial.detail_d}</p>
                    </div>
                    <div
                      className="div_cont_b"
                      style={{ marginTop: "2vh", backgroundColor: "#2E6F5C" }}
                    >
                      <p
                        className="iap_test_des_a"
                        style={{ fontWeight: "700" }}
                      >
                        {testimonial.description_e}
                      </p>
                      <p className="iap_test_des_a">{testimonial.detail_e}</p>
                    </div>
                    <div
                      className="div_cont_b"
                      style={{ marginTop: "2vh", backgroundColor: "#2E6F5C" }}
                    >
                      <p
                        className="iap_test_des_a"
                        style={{ fontWeight: "700" }}
                      >
                        {testimonial.description_f}
                      </p>
                      <p className="iap_test_des_a">{testimonial.detail_f}</p>
                    </div>
                    <div
                      className="div_cont_b"
                      style={{ marginTop: "2vh", backgroundColor: "#2E6F5C" }}
                    >
                      <p
                        className="iap_test_des_a"
                        style={{ fontWeight: "700" }}
                      >
                        {testimonial.description_g}
                      </p>
                      <p className="iap_test_des_a">{testimonial.detail_g}</p>
                    </div>
                    <div
                      className="div_cont_b"
                      style={{ marginTop: "2vh", backgroundColor: "#2E6F5C" }}
                    >
                      <p
                        className="iap_test_des_a"
                        style={{ fontWeight: "700" }}
                      >
                        {testimonial.description_h}
                      </p>
                      <p className="iap_test_des_a">{testimonial.detail_h}</p>
                    </div>
                    <div
                      className="div_cont_b"
                      style={{ marginTop: "2vh", backgroundColor: "#2E6F5C" }}
                    >
                      <p
                        className="iap_test_des_a"
                        style={{ fontWeight: "700" }}
                      >
                        {testimonial.description_i}
                      </p>
                      <p className="iap_test_des_a">{testimonial.detail_i}</p>
                    </div>
                    <div
                      className="div_cont_b"
                      style={{ marginTop: "2vh", backgroundColor: "#2E6F5C" }}
                    >
                      <p
                        className="iap_test_des_a"
                        style={{ fontWeight: "700" }}
                      >
                        {testimonial.description_j}
                      </p>
                      <p className="iap_test_des_a">{testimonial.detail_j}</p>
                    </div>
                    <div
                      className="div_cont_b"
                      style={{ marginTop: "2vh", backgroundColor: "#2E6F5C" }}
                    >
                      <p
                        className="iap_test_des_a"
                        style={{ fontWeight: "700" }}
                      >
                        {testimonial.description_j}
                      </p>
                      <p className="iap_test_des_a">{testimonial.detail_j}</p>
                    </div>
                    <div
                      className="div_cont_b"
                      style={{ marginTop: "2vh", backgroundColor: "#2E6F5C" }}
                    >
                      <p
                        className="iap_test_des_a"
                        style={{ fontWeight: "700" }}
                      >
                        {testimonial.description_k}
                      </p>
                      <p className="iap_test_des_a">{testimonial.detail_k}</p>
                    </div>
                    <div
                      className="div_cont_b"
                      style={{ marginTop: "2vh", backgroundColor: "#2E6F5C" }}
                    >
                      <p
                        className="iap_test_des_a"
                        style={{ fontWeight: "700" }}
                      >
                        {testimonial.description_l}
                      </p>
                      <p className="iap_test_des_a">{testimonial.detail_l}</p>
                    </div>
                  </div>
                  <div className="div_heading">
                    <p
                      style={{ color: testimonial.textColor }}
                      className="div_heading_a"
                    >
                      {testimonial.name}
                    </p>
                  </div>
                </div>
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default AnimationAcco;
