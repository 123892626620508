import React from "react";
import Axios from "axios";

const TeamMemberDetails = ({ member, teamDetails, setTeamDetails, team }) => {
  const onMemberRemoveClick = () => {
    Axios.post(
      "https://ashish.moodi.org/multicity/remove_mul_team_member",
      {
        team: team,
        mi_no: member.MI_NO,
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
      .then((res) => {
        if (res.data.status === "done") {
          setTeamDetails(
            // eslint-disable-next-line array-callback-return
            teamDetails.filter((element) => {
              if (element.MI_NO !== member.MI_NO) {
                return element;
              }
            })
          );
        }
      })
      .catch((err) => console.error(err));
  };
  return (
    <div className="member_details">
      <p>{member.userName}</p>
      <p>(Member)</p>
      <button className="cross_btn" onClick={onMemberRemoveClick}>
        X
      </button>
    </div>
  );
};

export default TeamMemberDetails;
