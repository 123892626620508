import React, { useState } from "react";
import HelpSection from "../pages/ccp/HelpSection";
import Help from "../../assets/images/ccp/help.svg";

const Helps = () => {
  const [showHelp, setShowHelp] = useState(false);

  // Function to toggle HelpSection visibility
  const handleHelpClick = () => {
    setShowHelp((prev) => !prev);
  };
  return (
    <div>
      <img
        src={Help}
        alt="help"
        className="help_icon"
        style={{ position: "fixed", zIndex: "5", bottom: "3vh", left: "1vh" }}
        onClick={handleHelpClick}
      />

      {/* Conditionally render HelpSection */}
      {showHelp && <HelpSection onClose={() => setShowHelp(false)} />}
    </div>
  );
};

export default Helps;
