import React, { useEffect, useState } from "react";
import Axios from "axios";
import AddThread from "./AddThread";
import ThreadsPost from "./ThreadsPost";

const Threads = ({ club, user }) => {
  const [threads, setThreads] = useState([]);

  const fetchClubThreads = () => {
    Axios.get(`https://ashish.moodi.org/ccp/getthreads?club=${club}`)
    .then((res) => setThreads(res.data))
    .catch((err) => console.error(err));
  };

  useEffect(() => {
    fetchClubThreads();
  }, [club]);

  return (
    <div>
      <AddThread club={club} user={user} />
      <div className="threads-list">
        {threads.map((threads) => (
          <ThreadsPost  threads={threads} club={club} user={user}/>
        ))}
      </div>
    </div>
  );
};

export default Threads;
