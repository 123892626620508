import React from "react";
import "./css/aboutus.css";
import shreyas from "../../../assets/images/ccp/team_info/shreyas.jpg";
import ritu from "../../../assets/images/ccp/team_info/ritu.jpg";
import ashish from "../../../assets/images/ccp/team_info/ashish.jpg";
import dharsti from "../../../assets/images/ccp/team_info/dharsti.jpg";
import kimaya from "../../../assets/images/ccp/team_info/kimaya.jpg"
import { FaInstagram, FaLinkedin, FaWhatsapp, FaTwitter } from "react-icons/fa";

const AboutUs = () => {
  return (
    <div className="about_us">
      <p className="sec_title">PUBLIC RELATIONS TEAM</p>
      <div className="pr_te">
        <div className="cg_adeta">
          <img src={shreyas} alt="shrea" />
          <div className="cg_ainfo">
            <p className="cg_aname">Shreyas Madankar</p>
            <p>Hospitality and PR Head</p>
            <a href="tel:+918767683564" className="cg_aphone">
                +91 87676 83564
            </a>
          </div>
          <div className="cg_alink">
            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.instagram.com/shreyas.m57?igsh=OW84eDU2Y3hwbzdi"
            >
              <FaInstagram className="icon_cl" color="black" size={30} />
            </a>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.linkedin.com/in/shreyas-m-49188824b?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app"
            >
              <FaLinkedin className="icon_cl" color="black" size={30} />
            </a>

            <a target="_blank" rel="noreferrer" href="https://wa.me/8767683564">
              <FaWhatsapp className="icon_cl" color="black" size={30} />
            </a>
          </div>
        </div>
        <div className="cg_adeta">
          <img src={ritu} alt="shrea" />
          <div className="cg_ainfo">
            <p className="cg_aname">Ritu Raj</p>
            <p>Hospitality and PR Head</p>
            <a href="tel:+919142246807" className="cg_aphone">
                +91 91422 46807
            </a>
          </div>
          <div className="cg_alink">
            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.instagram.com/the_ritu_raj1304?igsh=eDI5eGQ2bW04amI2&utm_source=qr"
            >
              <FaInstagram className="icon_cl" color="black" size={30} />
            </a>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.linkedin.com/in/ritu-raj-9b699724b/"
            >
              <FaLinkedin className="icon_cl" color="black" size={30} />
            </a>

            <a target="_blank" rel="noreferrer" href="https://wa.me/9142246807">
              <FaWhatsapp className="icon_cl" color="black" size={30} />
            </a>
          </div>
        </div>
      </div>
      <p className="sec_title ext_sec">
        DEVELOPMENT TEAM
      </p>
      <div className="pr_te_a">
        <div className="cg_adeta">
          <img src={ashish} alt="shrea" />
          <div className="cg_ainfo">
            <p className="cg_aname">Ashish Kushwah</p>
            <p>Web And Tech Head</p>
            <a href="tel:+918839930495" className="cg_aphone">
                +91 88399 30495
            </a>
          </div>
          <div className="cg_alink">
            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.instagram.com/ashish_k297/"
            >
              <FaInstagram className="icon_cl" color="black" size={30} />
            </a>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.linkedin.com/in/ashish-kushwah-02132b250/"
            >
              <FaLinkedin className="icon_cl" color="black" size={30} />
            </a>

            <a target="_blank" rel="noreferrer" href="https://wa.me/8839930495">
              <FaWhatsapp className="icon_cl" color="black" size={30} />
            </a>
            <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://x.com/ashishk297"
                >
                  <FaTwitter className="icon_cl" color="black" size={35} />
                </a>
          </div>
        </div>
        <div className="cg_adeta">
          <img src={kimaya} alt="shrea" />
          <div className="cg_ainfo">
            <p className="cg_aname">Kimaya Itkarkar</p>
            <p>Design Head</p>
            <a href="tel:+917350250937" className="cg_aphone">
                +91 73502 50937
            </a>
          </div>
          <div className="cg_alink">
            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.instagram.com/thornsandbutterflies/"
            >
              <FaInstagram className="icon_cl" color="black" size={30} />
            </a>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.linkedin.com/in/kimaya-itkarkar-0a7828252/"
            >
              <FaLinkedin className="icon_cl" color="black" size={30} />
            </a>

            <a target="_blank" rel="noreferrer" href="https://wa.me/7350250937">
              <FaWhatsapp className="icon_cl" color="black" size={30} />
            </a>
          </div>
        </div>
        <div className="cg_adeta">
          <img src={dharsti} alt="shrea" />
          <div className="cg_ainfo">
            <p className="cg_aname">Drashti Mehta</p>
            <p>Design Head</p>
            <a href="tel:+918422916008" className="cg_aphone">
                +91 84229 16008
            </a>
          </div>
          <div className="cg_alink">
            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.instagram.com/_drxshtx__?igsh=ZmM0aDFuYzUzdHlk"
            >
              <FaInstagram className="icon_cl" color="black" size={30} />
            </a>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.linkedin.com/in/drashti-mehta-7b733b217?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app"
            >
              <FaLinkedin className="icon_cl" color="black" size={30} />
            </a>

            <a target="_blank" rel="noreferrer" href="https://wa.me/8422916008">
              <FaWhatsapp className="icon_cl" color="black" size={30} />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
