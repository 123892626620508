// DashboardMid.js
import React from "react";
import MyMI from "./Mymi";
import EarnPoints from "./PointProgress";
import Clubs from "./Clubs";
import Threads from "./Threads";
import Leaderboards from "./LeaderBoard";
import AboutUs from "./Aboutus";
import Faqs from "./Faqs"

const DashboardMid = ({ page, user, dashMidRef, club }) => {
  console.log("ydfsdfr",user.userId) // Debugging statement

  return (
    <div ref={dashMidRef} className="dashboard_mid">
      {page === "mymi" && <MyMI user={user} />}
      {page === "earnpoints" && <EarnPoints user={user} />}
      {page === "clubs" && <Clubs user={user} />}
      {page === "threads" && <Threads user={user} club={club} />}
      {page === "leaderboards" && <Leaderboards user={user} />}
      {page === "about" && <AboutUs user={user} />}
      {page === "faqs" && <Faqs user={user} />}
    </div>
  );
};

export default DashboardMid;
