import React from "react";
import { useNavigate } from "react-router-dom";

const ResgisterCircle = () => {

    const navigate = useNavigate();

    const handleRegisterNavigation = () => {
        navigate("/register");
    };

  return (
    <div onClick={handleRegisterNavigation} className="register_main">
      <div className="register_image">
      </div>
      <p>REGISTER</p>
    </div>
  );
};

export default ResgisterCircle;
