const mul_cities = [
  {
    cityName: "Bengaluru",
    cityDate: "8th September, 2024",
    cityVenue: "IIIT Bangalore",
    cityColor:
      "linear-gradient(rgba(68, 148, 153, 1), rgba(171, 250, 255, 1), rgba(76, 155, 161, 1))",
    cityFontColor: "",
  },
  {
    cityName: "Jaipur",
    cityDate: "29th September, 2024",
    cityVenue: "Apex University",
    cityColor:
      "linear-gradient(180deg, #FEB72D 0%, #FAEBC8 51.21%, #FEB72D 100%)",
    cityFontColor: "",
  },
  {
    cityName: "Delhi",
    cityDate: "13th - 14th Oct, 2024",
    cityVenue: "TBA",
    cityColor:
      "linear-gradient(180deg, #EE6F93 0%, #FFD7E3 42.19%, #F17B9C 100%)",
    cityFontColor: "",
  },
  {
    cityName: "Pune",
    cityDate: "20th October, 2024",
    cityVenue: "MIT WPU",
    cityColor:
      "linear-gradient(180deg, #2F82FF 0%, #A0C1FF 37%, #A0C1FF 61.5%, #3787FF 100%)",
    cityFontColor: "",
  },
  {
    cityName: "Mumbai",
    cityDate: "1st December, 2024",
    cityVenue: "TBA",
    cityColor:
      "linear-gradient(rgba(68, 148, 153, 1), rgba(171, 250, 255, 1), rgba(76, 155, 161, 1))",
    cityFontColor: "",
  },
  {
    cityName: "Gwalior",
    cityDate: "23rd - 29th Nov, 2024",
    cityVenue: "PIM Gwalior",
    cityColor:
      "linear-gradient(180deg, #FEB72D 0%, #FAEBC8 51.21%, #FEB72D 100%)",
    cityFontColor: "",
  },
  {
    cityName: "Thrissur",
    cityDate: "17th - 20th oct, 2024",
    cityVenue: "Government Engineering College Thrissur",
    cityColor:
      "linear-gradient(180deg, #EE6F93 0%, #FFD7E3 42.19%, #F17B9C 100%)",
  },

  {
    cityName: "Hyderabad",
    cityDate: "4th - 5th Nov, 2024",
    cityVenue: "Woxsen",
    cityColor:
      "linear-gradient(180deg, #2F82FF 0%, #A0C1FF 37%, #A0C1FF 61.5%, #3787FF 100%)",
  },

  {
    cityName: "Kurukshetra",
    cityDate: "2nd - 5th Oct, 2024",
    cityVenue: "TBA",
    cityColor:
      "linear-gradient(rgba(68, 148, 153, 1), rgba(171, 250, 255, 1), rgba(76, 155, 161, 1))",
  },
  {
    cityName: "Nagpur",
    cityDate: "15th - 17th Nov, 2024",
    cityVenue: "VNIT",
    cityColor:
      "linear-gradient(180deg, #FEB72D 0%, #FAEBC8 51.21%, #FEB72D 100%)",
  },

  {
    cityName: "Chandigarh",
    cityDate: "8th - 10th Nov, 2024",
    cityVenue: "PEC",
    cityColor:
      "linear-gradient(180deg, #EE6F93 0%, #FFD7E3 42.19%, #F17B9C 100%)",
  },
  {
    cityName: "Chhattisgarh",
    cityDate: "2nd - 4th Nov, 2024",
    cityVenue: "IIT Bhilai",
    cityColor:
      "linear-gradient(180deg, #2F82FF 0%, #A0C1FF 37%, #A0C1FF 61.5%, #3787FF 100%) ",
  },
  {
    cityName: "Ropar",
    cityDate: "25-27th Oct, 2024",
    cityVenue: "IIT Ropar",
    cityColor:
      "linear-gradient(rgba(68, 148, 153, 1), rgba(171, 250, 255, 1), rgba(76, 155, 161, 1))",
  },

  {
    cityName: "Puducherry",
    cityDate: "25-26th Oct, 2024",
    cityVenue: "NIT Puducherry",
    cityColor:
      "linear-gradient(180deg, #FEB72D 0%, #FAEBC8 51.21%, #FEB72D 100%)",
  },
  {
    cityName: "Bhagalpur",
    cityDate: "10-12th Oct, 2024",
    cityVenue: "IIT Bhagalpur",
    cityColor:
      "linear-gradient(180deg, #EE6F93 0%, #FFD7E3 42.19%, #F17B9C 100%)",
  },
];

export default mul_cities;
