import React, { useRef, useState, useEffect } from "react";
import "../css/cl.css";
import Axios from "axios";
import { useNavigate } from "react-router-dom";
import { jwtDecode } from "jwt-decode";
import HomeImage from "../../../../assets/images/cl/home.svg";
import PageTwoImage from "../../../../assets/images/cl/Frame.svg";
import PerkImage from "../../../../assets/images/cl/perk.svg";
import FiveOne from "../../../../assets/images/cl/fiveOne.svg";
import FiveTwo from "../../../../assets/images/cl/fiveTwo.svg";
import FiveThree from "../../../../assets/images/cl/fiveThree.svg";
import FiveFour from "../../../../assets/images/cl/fivefour.svg";
import shreyas from "../../../../assets/images/ccp/team_info/shreyas.jpg";
import ritu from "../../../../assets/images/ccp/team_info/ritu.jpg";
import Cross from "../../../../assets/images/accommodation/Cross.png";
import {
  FaInstagram,
  FaLinkedin,
  FaWhatsapp,
  FaTwitter,
  FaFacebook,
  FaYoutube,
} from "react-icons/fa";
import RollingAnimation from "../../Home/HomeComponents/RollingAnimation";
const Clpageone = () => {
  const lastpageRef = useRef(null);
  const [isFormVisible, setIsFormVisible] = useState(false);
  const [submitDisables, setSubmitDisables] = useState(false);

  const navigate = useNavigate();

  const [isPageTwoVisible, setIsPageTwoVisible] = useState(false);
  const [isPageThreeVisible, setIsPageThreeVisible] = useState(false);
  const pageTwoRef = useRef(null);
  const pageThreeRef = useRef(null);

  const [user, setUser] = useState(
    sessionStorage.getItem("miauthtoken")
      ? jwtDecode(sessionStorage.getItem("miauthtoken")).user
      : null
  );

  const [registered, setRegistered] = useState(false);
  const fetchClDetails = () => {
    Axios.post(
      "https://ashish.moodi.org/cl/getInfo",
      {
        user: user.userId,
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
      .then((res) => {
        setClObj(res.data);
        if (res.data.collegeIdUrl) {
          setRegistered(true);
        }
      })
      .catch((err) => console.error(err));
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    console.log("Submit form submitted");
    setSubmitDisables(true);

    Axios.post(
      "https://ashish.moodi.org/cl/cl_reg",
      {
        collegeIdUrl: clObj.collegeIdUrl,
        aadharId: clObj.aadharId,
        motivation: clObj.motivation,
        user: user.userId,
        age: clObj.age,
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
      .then((res) => {
        if (res.data.status === "done") {
          window.alert("You have successfully registered!!!");
          window.location.reload();
        }
      })
      .catch((err) => console.error(err));
  };
  const [clObj, setClObj] = useState({
    name: "",
    state: "",
    collegeName: "",
    phone: "",
    email: "",
    age: "",
    gender: "",
    yearOfStudy: "",
    MI_NO: "",
    collegeIdUrl: "",
    aadharId: "",
    motivation: "",
  });

  const onChange = (e) => {
    setClObj({ ...clObj, [e.target.name]: e.target.value });
  };

  useEffect(() => {
    if (user) {
      fetchClDetails();
    }
    const handleScroll = () => {
      if (pageTwoRef.current && pageThreeRef.current) {
        const scrollPosition = window.scrollY + window.innerHeight;

        const pageTwoPosition = pageTwoRef.current.offsetTop;
        if (scrollPosition > pageTwoPosition) {
          setIsPageTwoVisible(true);
        }

        const pageThreePosition = pageThreeRef.current.offsetTop;
        if (scrollPosition > pageThreePosition) {
          setIsPageThreeVisible(true);
        }
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
    // eslint-disable-next-line
  }, [user]);

  const handleform = (e) => {
    if (user) {
      setIsFormVisible(true);
    } else {
      navigate(`/register?redirect=cl`);
      setIsFormVisible(false);
    }
  };
  const handleformImage = (e) => {
    setIsFormVisible(false);
  };

  const text = [" MOOD INDIGO MOOD INDIGO"];
  const customStyle = {
    fontSize: "7vw",
    color: "rgba(255, 255, 255, 0.42)",
    fontWeight: "700",
    fontFamily: "lemonMilk",
    lineHeight: "1.18",
  };
  const responsibilities = [
    "The Contingent Leader, chosen by their college, leads the delegation at Mood Indigo",
    "They manage accommodations, ensuring a smooth and memorable stay",
    "Acting as the key link between attendees and the fest, they enhance communication",
    "They drive participation and enthusiasm from their college",
    "Their leadership elevates the festival’s impact on every participant",
  ];
  return (
    <div className="cl_container">
      <div className="home_page">
        <div>
          <p className="heading_home">CONTINGENT LEADER</p>
          <p className="heading_para">
            Lead the Charge, Shape the Legacy! Welcome to the Contingent Leader
            portal of Mood Indigo 2024—your stage to rally your college, clinch
            top honors, and leave your mark on Asia’s biggest college cultural
            festival!
          </p>
          <p className="heading_para">
            This is your moment to inspire, collaborate, and elevate the 54th
            edition of Mood Indigo into a celebration like no other. Unite your
            peers, harness your collective talent, and rise to the challenge.
            The spotlight is on you- are you ready to take the lead and conquer
            the fest?"
          </p>
        </div>
        <div>
          <img src={HomeImage} alt="main" className="main_home" />
        </div>
      </div>
      <div className="page_two">
        <div
          ref={pageTwoRef}
          className={`page_two_a ${isPageTwoVisible ? "visible" : ""}`}
        >
          <div>
            <p className="heading_page">Who is a Contingent Leader?</p>
            <ul className="heading_para_page_two">
              {responsibilities.map((item, index) => (
                <li key={index}>{item}</li>
              ))}
            </ul>
            <div>
              <button className="register_btn" onClick={handleform}>
                Register
              </button>
            </div>
          </div>
          <div>
            <img src={PageTwoImage} alt="second" className="pageTwo_image" />
          </div>
          9
        </div>
      </div>
      <div className="page_three">
        <div
          ref={pageThreeRef}
          className={`page_three_a ${isPageThreeVisible ? "visible" : ""}`}
        >
          <div>
            <p className="heading_pageThree">
              Perks of Being a Contingent Leader
            </p>
            <p className="heading_pageThree_a">
              Becoming a Contingent Leader at Mood Indigo places you at the
              heart of its vibrant community—The Indigo Squad. As a leader,
              you'll not only play a pivotal role in guiding your college but
              also unlock exciting rewards and exclusive perks
            </p>
          </div>
          <div>
            <img src={PerkImage} alt="third" className="pageThree_image" />
          </div>
        </div>
      </div>
      <div className="page_four">
        <div className="page_four_container">
          <div className="pagef_a">
            <p className="pagef_a_head">Free Accommodation</p>
            <p className="pagef_a_para">
              Lead a team of 30+ students from your college and enjoy your free
              festival stay
            </p>
          </div>

          <div className="pagef_a">
            <p className="pagef_a_head">Exclusive Merchandise </p>
            <p className="pagef_a_para">
              Rally 15+ students to claim limited edition Mood Indigo gear
            </p>
          </div>
        </div>

        <div className="page_four_container">
          <div className="pagef_a">
            <p className="pagef_a_head">VIP invitations and internship</p>
            <p className="pagef_a_para">
              Lead a team of 54+ students from your college and secure
              internships, free accommodation, and exclusive food vouchers
            </p>
          </div>

          <div className="pagef_a">
            <p className="pagef_a_head">Letter from the Dean</p>
            <p className="pagef_a_para">
              Get a distinguished letter of appreciation from the Dean of IIT
              Bombay for your leadership
            </p>
          </div>
        </div>

        <div className="reg_pageFour">
          <button className="register_btn" onClick={handleform}>
            Register
          </button>
        </div>
      </div>
      <div className="page_five">
        <div className="five_a">
          <div className="five_a_content">
            <div className="fiveContent">
              <img src={FiveOne} className="five_image" alt="five" />
              <p className="five-head">What does a Contingent Leader do?</p>
              <ul className="styled-list hidden-content">
                <li>
                  <strong>Key Connector:</strong> Acts as the main link between
                  Mood Indigo and their college, providing vital fest
                  information.
                </li>
                <li>
                  <strong>Accommodation Manager:</strong> Handles accommodation
                  arrangements for their college's delegation.
                </li>
                <li>
                  <strong>Event Organizer:</strong> Creates excitement by
                  hosting events and orientations about Mood Indigo.
                </li>
                <li>
                  <strong>Participation Booster:</strong> Motivates students to
                  join the fest and engage in competitions.
                </li>
                <li>
                  <strong>Festival Ambassador:</strong> Spreads the vibrant
                  spirit of Mood Indigo across their campus.
                </li>
              </ul>
            </div>
            <div className="fiveContent">
              <img src={FiveTwo} className="five_image" alt="five" />
              <p className="five-head">How to become a Contingent Leader?</p>
              <ul className="styled-list hidden-content">
                <li>
                  <strong>Start Your Journey:</strong> Becoming a Contingent
                  Leader starts with a simple form but requires official
                  validation.
                </li>
                <li>
                  <strong>Official Nomination:</strong> A signed letter from
                  your Director or Principal or highest authority on the college
                  letterhead is needed to confirm your role as CL for Mood
                  Indigo 2024.
                </li>
                <li>
                  <strong>One Leader Only:</strong> Each college can nominate
                  only one CL, ensuring a single representative.
                </li>
                <li>
                  <strong>College's Decision:</strong> In case of multiple
                  nominations, your college must internally select the final
                  candidate—Mood Indigo stays uninvolved.
                </li>
                <li>
                  <strong>Claim Your Leadership:</strong> Begin your journey and
                  secure your place as a leader for Mood Indigo 2024!
                </li>
              </ul>
            </div>
          </div>
          <div className="five_a_content">
            <div className="fiveContent ext_second">
              <img src={FiveThree} className="five_image_b" alt="five" />
              <p className="five-head">Appoint Assistant Contingent Leaders!</p>
              <ul className="styled-list hidden-content">
                <li>
                  <strong>Assistant CLs Support:</strong> Along with your
                  leadership, two Assistant CLs will assist in streamlining
                  communication, appointed by the Contingent Leader.
                </li>
                <li>
                  <strong>Identification Requirement:</strong> The CL and
                  Assistant CLs must include their unique Mood Indigo ID numbers
                  along with their names in the official letter.
                </li>
                <li>
                  <strong>Submit Post-Confirmation:</strong> After your CL
                  appointment is confirmed by the Mood Indigo team, submit a
                  scanned copy of the letter to{" "}
                  <strong>publicrelations@moodi.org</strong>, with{" "}
                  <strong>shreyas@moodi.org</strong> and{" "}
                  <strong>rituraj@moodi.org</strong> in CC.
                </li>
                <li>
                  <strong>Ensure Coordination:</strong> This submission is a
                  critical step to guarantee smooth coordination throughout the
                  festival planning process.
                </li>
              </ul>
            </div>
            <div className="fiveContent">
              <img src={FiveFour} className="five_image_b" alt="five" />
              <p className="five-head">
                What if there's no cultural committee?
              </p>
              <ul className="styled-list hidden-content">
                <li>
                  <strong>No Cultural Committee? No Problem:</strong> If you're
                  a group of friends without a formal cultural committee, simply
                  nominate a Contingent Leader (CL) from within your group.
                </li>
                <li>
                  <strong>Get Approval:</strong> Seek official approval from
                  your college authorities for your chosen CL.
                </li>
                <li>
                  <strong>Proceed Like Other Contingents:</strong> Once your CL
                  is appointed, you'll follow the same process as other college
                  contingents.
                </li>
                <li>
                  <strong>Accommodation Process:</strong> After CLs are
                  finalized, the accommodation process will be launched. Make
                  sure to register on my.moodi.org to stay updated.
                </li>
                <li>
                  <strong>Get Ready for an Unforgettable Experience:</strong>{" "}
                  Stay tuned for notifications and prepare for an exciting Mood
                  Indigo adventure!
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="register-container_five">
          <button className="register_btn" onClick={handleform}>
            REGISTER
          </button>
        </div>
      </div>

      <div className="page_six">
        <div className="pr_te cl_pr_te">
          <div className="cg_adeta ext_cg_adeta">
            <img src={shreyas} alt="shrea" />
            <div className="cg_ainfo cg_ainfo_cl_p">
              <p className="cg_aname">Shreyas Madankar</p>
              <p>Hospitality and PR Head</p>
              <a href="tel:+918767683564" className="cg_aphone">
                +91 87676 83564
              </a>
            </div>
            <div className="cg_alink">
              <a
                target="_blank"
                rel="noreferrer"
                href="https://www.instagram.com/shreyas.m57?igsh=OW84eDU2Y3hwbzdi"
              >
                <FaInstagram className="icon_cl" color="black" size={30} />
              </a>
              <a
                target="_blank"
                rel="noreferrer"
                href="https://www.linkedin.com/in/shreyas-m-49188824b?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app"
              >
                <FaLinkedin className="icon_cl" color="black" size={30} />
              </a>

              <a
                target="_blank"
                rel="noreferrer"
                href="https://wa.me/8767683564"
              >
                <FaWhatsapp className="icon_cl" color="black" size={30} />
              </a>
            </div>
          </div>
          <div className="cg_adeta">
            <img src={ritu} alt="shrea" />
            <div className="cg_ainfo">
              <p className="cg_aname">Ritu Raj</p>
              <p>Hospitality and PR Head</p>
              <a href="tel:+919142246807" className="cg_aphone">
                +91 91422 46807
              </a>
            </div>
            <div className="cg_alink">
              <a
                target="_blank"
                rel="noreferrer"
                href="https://www.instagram.com/the_ritu_raj1304?igsh=eDI5eGQ2bW04amI2&utm_source=qr"
              >
                <FaInstagram className="icon_cl" color="black" size={30} />
              </a>
              <a
                target="_blank"
                rel="noreferrer"
                href="https://www.linkedin.com/in/ritu-raj-9b699724b/"
              >
                <FaLinkedin className="icon_cl" color="black" size={30} />
              </a>

              <a
                target="_blank"
                rel="noreferrer"
                href="https://wa.me/9142246807"
              >
                <FaWhatsapp className="icon_cl" color="black" size={30} />
              </a>
            </div>
          </div>
        </div>
      </div>
      <div ref={lastpageRef} className="footer ext_page_6">
        <div className="footer_text_animation">
          <RollingAnimation
            style={customStyle}
            text={text}
            direction={"left"}
          />
        </div>
        <div className="footer_details">
          <p>
            Mood Indigo Office, Student Activity Center (SAC), IIT Bombay,
            Powai, Mumbai, Maharashtra - 400076
          </p>
          <div className="footer_icon">
            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.instagram.com/iitbombay.moodi?igsh=MTNmam9pY3Z1YW93aQ=="
            >
              <FaInstagram color="white" size={30} />
            </a>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.linkedin.com/company/mood-indigo/"
            >
              <FaLinkedin color="white" size={30} />
            </a>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://x.com/iitb_moodi?t=tyaVKeWlXXTcc9qIerNTkQ&s=08"
            >
              <FaTwitter color="white" size={30} />
            </a>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.facebook.com/iitbombay.moodi?mibextid=ZbWKwL"
            >
              <FaFacebook color="white" size={30} />
            </a>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.youtube.com/@iitbmoodindigo"
            >
              <FaYoutube color="white" size={30} />
            </a>
          </div>
        </div>
      </div>
      {isFormVisible && (
        <div className="form_container_cl">
          <form className="cl_form" onSubmit={handleSubmit}>
            <img
              src={Cross}
              alt="cross"
              className="cross_image_cl"
              onClick={handleformImage}
            />
            <p className="cl_heading">Become a CL</p>
            <div className="cl_arrange">
              <div className="cl_a">
                <label>Name</label>
                <input
                  type="text"
                  placeholder="Enter your name"
                  name="name"
                  value={clObj.userName}
                  readOnly
                />
              </div>
              <div className="cl_a">
                <label>College</label>
                <input
                  type="text"
                  placeholder="Enter your college"
                  name="college"
                  value={clObj.collegeName}
                  readOnly
                />
              </div>
              <div className="cl_a">
                <label>State</label>
                <input
                  type="text"
                  placeholder="Enter your state"
                  name="state"
                  value={clObj.state}
                  readOnly
                />
              </div>
            </div>
            <div className="cl_arrange">
              <div className="cl_ext">
                <label>Mobile No.</label>
                <input
                  type="tel"
                  placeholder="Enter your mobile number"
                  name="phone"
                  value={clObj.phone}
                  readOnly
                />
              </div>
              <div className="cl_ext">
                <label>Email ID</label>
                <input
                  type="email"
                  placeholder="Enter your email ID"
                  name="email"
                  value={clObj.email}
                  readOnly
                />
              </div>
              <div className="cl_age_input">
                <label>Age</label>
                <input
                  type="number"
                  placeholder="Enter your age"
                  name="age"
                  onChange={registered ? null : onChange} // Disable onChange if checkbox is checked
                  value={clObj.age}
                  required
                  readOnly={registered}
                />
              </div>
              <div className="cl_ext">
                <label>Gender</label>
                <select name="" id="" value={clObj.gender} disabled>
                  <option value="">Select Gender</option>
                  <option value="Male">Male</option>
                  <option value="Female">Female</option>
                  <option value="Other">Other</option>
                </select>
              </div>
            </div>
            <div className="cl_arrange">
              <div className="cl_a">
                <label>Year of Study</label>
                <input
                  type="text"
                  placeholder="Enter your year of study"
                  name="yearOfStudy"
                  value={clObj.yearOfStudy}
                  readOnly
                />
              </div>
              <div className="cl_a">
                <label>MI Number</label>
                <input
                  type="text"
                  placeholder="Enter your MI number"
                  name="MI_NO"
                  value={clObj.MI_NO}
                  readOnly
                />
              </div>
              <div className="cl_a">
                <label>College ID Drive Link</label>
                <input
                  type="url"
                  placeholder="Enter your college ID drive link"
                  name="collegeIdUrl"
                  onChange={registered ? null : onChange}
                  value={clObj.collegeIdUrl}
                  required
                  readOnly={registered}
                />
              </div>
            </div>
            <div className="cl_last">
              <div className="cl_last_a">
                <div className="cl_a">
                  <label>Aadhar/PAN ID Drive Link</label>
                  <input
                    type="url"
                    placeholder="Enter your Aadhar/PAN ID drive link"
                    name="aadharId"
                    onChange={registered ? null : onChange} // Remove onChange if registered
                    value={clObj.aadharId}
                    required
                    readOnly={registered}
                  />
                </div>
                <div className="cl_terms_conditions ">
                  <p className="cl_terms_head">
                    Upload your ID’s in a google drive and submit the links
                    here, with ‘open for all’ access.
                  </p>
                  <div className="cl_check">
                    {/* <div>
                      <input
                        type="checkbox"
                        className="cl_checkbox"
                        name="terms"
                        id="terms"
                        required
                        checked={registered ? true : clObj.terms}
                      />
                      <span className="cl_check_head">
                        I agree with the{" "}
                        <a href="https://docs.google.com/document/d/1JH48V0M9KCwfaEx9_4GSTX3aHmC8QP3OHMe1qR2w7xA/edit?usp=sharing">
                          Terms and Conditions
                        </a>
                      </span>
                    </div> */}

                    <button
                      type="submit"
                      value="Submit"
                      className="cl_btn"
                      disabled={registered ? true : submitDisables}
                      // Disable button after submission
                    >
                      {registered ? "Registered" : "Submit"}
                    </button>
                  </div>
                </div>
              </div>
              <div div className="mo_cl">
                <label>Motivation to become CL (at least 100 words)</label>
                <textarea
                  className="mo_cl_textarea"
                  type="url"
                  placeholder="Enter your Motivation for become CL"
                  name="motivation"
                  onChange={onChange}
                  value={clObj.motivation}
                  required
                  readOnly={registered}
                />
              </div>
              <div className="cl_terms_conditions cl_mobile">
                <p className="cl_terms_head">
                  Upload your ID’s in a google drive and submit the links here,
                  with ‘open for all’ access.
                </p>
                <div className="cl_check">
                  {/* <div>
                    <input
                      type="checkbox"
                      className="cl_checkbox"
                      checked={registered ? true : clObj.terms}
                      required
                    />
                    <span className="cl_check_head">
                      I agree with the{" "}
                      <a href="https://docs.google.com/document/d/1JH48V0M9KCwfaEx9_4GSTX3aHmC8QP3OHMe1qR2w7xA/edit?usp=sharing">
                        Terms and Conditions
                      </a>
                    </span>
                  </div> */}

                  <button
                    type="submit"
                    value="Submit"
                    className="cl_btn"
                    disabled={registered ? true : submitDisables}
                  >
                    {registered ? "Registered" : "Submit"}
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      )}
    </div>
  );
};

export default Clpageone;
