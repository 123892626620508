import React from "react";
import "./registration.css";

function PersonalInfo({ user, setUser, page, setPage }) {
  const handleSubmit = (e) => {
    e.preventDefault();
    if (
      user.name !== "" &&
      user.dob !== "" &&
      user.phone !== "" &&
      user.gender !== "" &&
      user.por !== "" 
    ) {
      setPage(page + 1);
    } else {
      alert("* marked fields cannot be left empty");
    }
  };
  const onChange = (e) => {
    setUser({ ...user, [e.target.name]: e.target.value });
  };
  return (
    <form>
      <div className="personalInfo">
        <input
          className="inputEmail"
          type="text"
          id="name"
          name="name"
          placeholder="Full Name*"
          onChange={onChange}
          value={user.name}
          autoComplete="name"
          minLength={3}
          required
        />
        <input
          className="inputEmail"
          type="text"
          id="dob"
          name="dob"
          placeholder="Date of Birth*"
          onFocus={(e) => (e.target.type = "date")}
          onChange={onChange}
          value={user.dob}
          required
        />
      </div>
      <div className="personalInfo">
        <input
          className="inputEmail"
          type="tel"
          id="phone"
          name="phone"
          placeholder="Ph. Number*"
          onChange={onChange}
          value={user.phone}
          maxLength={10}
          required
          autoComplete="tel-national"
        />
        <select
          className="inputEmail"
          name="gender"
          id="gender"
          onChange={onChange}
          value={user.gender}
          required
          autoComplete="sex"
        >
          <option value="">Gender*</option>
          <option value="Male">Male</option>
          <option value="Female">Female</option>
          <option value="Other">Other</option>
        </select>
        
      </div>
      <input
          className="inputEmail"
          type="text"
          id="por"
          name="por"
          placeholder="Position In College"
          onChange={onChange}
          value={user.por}
          autoComplete="name"
          minLength={3}
          
        />
      <div>
        <button
          type="submit"
          className="btn-10 custom-btn next-btn ext_sp_mon"
          onClick={handleSubmit}
        >
          Next
        </button>
      </div>
    </form>
  );
}

export default PersonalInfo;
