import React, { useEffect, useRef, useState } from "react";
import TeamCompiReg from "./TeamCompiReg";
import Axios from "axios";
import Uparrow from "../../../../assets/images/compi/upArrow.svg";

const CompiInfo = ({ competition, user, regCompi, setRegCompi }) => {
  const rulesRef = useRef(null);
  const prizesLypRef = useRef(null);
  const accRef = useRef(null);
  const tcRef = useRef(null);

  const [response, setResponse] = useState({ submissionLink: "" });

  const [onlineDisabled, setOnlineDisabled] = useState(false);
  const [team, setTeam] = useState(null);

  const [accOpen, setAccOpen] = useState(false);

  const [isSubmitting, setIsSubmitting] = useState(false); // Track submission process
  const [hasSubmitted, setHasSubmitted] = useState(false);
  const onAccordionClick = () => {
    accRef.current.classList.toggle("acc_active");
    var panel = accRef.current.nextElementSibling;
    if (panel.style.display === "block") {
      panel.style.display = "none";
      setAccOpen(false);
    } else {
      panel.style.display = "block";
      setAccOpen(true);
    }
  };

  const onChange = (e) => {
    setResponse({ ...response, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (response.submissionLink !== " " && response.submissionLink !== "") {
      setIsSubmitting(true);
      if (competition.isTeamCompi) {
        Axios.post(
          "https://ashish.moodi.org/competitions/online_sub",
          {
            userTeamId: team,
            competition: competition.competitionId,
            submissionLink: response.submissionLink,
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
          .then((res) => {
            if (res.data.status === "done") {
              setHasSubmitted(true);
              window.alert("Your entry has been submitted successfully!");
              setIsSubmitting(false);
              window.location.reload();
            }
          })
          .catch((err) => {
            console.error(err);
            setIsSubmitting(false); // Reset submission state in case of error
          });
      } else {
        Axios.post(
          "https://ashish.moodi.org/competitions/online_sub",
          {
            userTeamId: user.userId,
            competition: competition.competitionId,
            submissionLink: response.submissionLink,
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
          .then((res) => {
            if (res.data.status === "done") {
              setHasSubmitted(true);
              window.alert("Your entry has been submitted successfully!");
              setIsSubmitting(false);
              window.location.reload();
            }
          })
          .catch((err) => {
            console.error(err);
            setIsSubmitting(false); // Reset submission state in case of error
          });
      }
    } else {
      window.alert("Submission cannot be empty!");
    }
  };

  const getOnlineStatus = () => {
    if (competition.isTeamCompi) {
      Axios.post(
        "https://ashish.moodi.org/competitions/get_online_status",
        {
          userTeamId: team,
          competition: competition.competitionId,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
        .then((res) => {
          if (res.data.length > 0) {
            // Disable input and button
            setHasSubmitted(true); // Mark as submitted if found
            setOnlineDisabled(true);
            setResponse({
              submissionLink: res.data[0].submissionLink,
            });
          }
        })
        .catch((err) => console.error(err));
    } else {
      Axios.post(
        "https://ashish.moodi.org/competitions/get_online_status",
        {
          userTeamId: user.userId,
          competition: competition.competitionId,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
        .then((res) => {
          if (res.data.length > 0) {
            setHasSubmitted(true); // Mark as submitted if found
            setOnlineDisabled(true); // Disable input and button
            setResponse({
              submissionLink: res.data[0].submissionLink,
            });
          }
        })
        .catch((err) => console.error(err));
    }
  };

  const onRegisterClick = () => {
    if (competition.isTeamCompi) {
      let scrollTop = 0;
      let scrollLeft = 0;
      window.onscroll = function () {
        window.scrollTo(scrollLeft, scrollTop);
      };
      tcRef.current.classList.remove("no-disp");
      window.scrollTo(0, 0);
    } else {
      if (window.confirm("Are you sure you want to register?") === true) {
        Axios.post(
          "https://ashish.moodi.org/competitions/compi_solo_reg",
          {
            user: user.userId,
            competition: competition.competitionId,
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
          .then((res) => {
            if (res.data.status === "done") {
              setRegCompi((current) => [
                ...current,
                { competition: competition.competitionId },
              ]);
              alert(
                `You have successfully registered for ${competition.competitionName}!!`
              );
              window.location.reload();
            } else {
              window.alert(res.data.status);
            }
          })
          .catch((err) => console.error(err));
      }
    }
  };

  useEffect(() => {
    if (competition.isOnline) {
      if (regCompi) {
        if (
          regCompi.find(
            (element) => element.competition === competition.competitionId
          )
        ) {
          getOnlineStatus();
          setOnlineDisabled(false);
        } else {
          setOnlineDisabled(true);
        }
      }
      var date = new Date(competition.submissionDeadline);
      var serverDate = new Date();
      if (serverDate > date) {
        setOnlineDisabled(true);
      }
    }
    let rules = rulesRef.current;
    rules.innerHTML = competition.rules;
    let prizes = prizesLypRef.current;
    prizes.innerHTML = competition.prizesLyp;
  }, [regCompi, team]);

  return (
    <div style={{ height: "fit-content", margin: 0 }}>
      <div className="ne_com_info" ref={accRef} onClick={onAccordionClick}>
        <div className="ne_di_com">
          <p className="ne_com_na">
            {" "}
            {competition.competitionName
              ? competition.competitionName.toUpperCase()
              : ""}
          </p>
          <p className="ne_com_b">{competition.subtitle}</p>
        </div>
        {accOpen ? (
          <div>
            <img src={Uparrow} alt="" />
          </div>
        ) : (
          <>
            {regCompi ? (
              regCompi.find(
                (element) => element.competition === competition.competitionId
              ) ? (
                <div className="ne_reg-btn" style={{ position: "inherit" }}>
                  REGISTERED
                </div>
              ) : (
                <div className="ne_reg-bt" style={{ position: "inherit" }}>
                  REGISTER
                </div>
              )
            ) : (
              <div className="ne_reg-bt" style={{ position: "inherit" }}>
                REGISTER
              </div>
            )}
          </>
        )}
      </div>
      <div className="ne_panel">
        {regCompi ? (
          regCompi.find(
            (element) => element.competition === competition.competitionId
          ) ? (
            competition.isTeamCompi ? (
              <button className="ne_reg-bt" onClick={onRegisterClick}>
                VIEW TEAM
              </button>
            ) : (
              <button className="ne_reg-btn">REGISTERED</button>
            )
          ) : (
            <button className="ne_reg-bt" onClick={onRegisterClick}>
              REGISTER
            </button>
          )
        ) : (
          <button className="ne_reg-btn" onClick={onRegisterClick}>
            REGISTER
          </button>
        )}
        <p style={{ marginTop: "1vh" }}>{competition.description}</p>
        {competition.isOnline ? (
          <form className="ne_form" onSubmit={handleSubmit}>
            <input
              type="text"
              name="submissionLink"
              className="input_com"
              placeholder="Link for online submission"
              onChange={onChange}
              value={response.submissionLink}
              disabled={hasSubmitted || onlineDisabled || isSubmitting}
            />
            <input
              type="submit"
              className="submit_btn"
              value={
                isSubmitting
                  ? "Submitting..." // Show submitting text during submission
                  : hasSubmitted
                  ? "Submitted" // Show submitted text if already submitted
                  : "Submit"
              }
              disabled={hasSubmitted || onlineDisabled || isSubmitting} // Disable if already submitted or during submission
            />
          </form>
        ) : (
          <></>
        )}
        {/* <p className="compi_desc">Descriptions</p> */}
        <p style={{ marginTop: "1vh" }} ref={rulesRef}></p>
        <p ref={prizesLypRef}></p>
      </div>
      <hr
        style={{
          border: "1px solid #000",
          opacity: 0.5,
          margin: "0px 0",
        }}
      />
      {competition.isTeamCompi ? (
        <TeamCompiReg
          tcRef={tcRef}
          competition={competition}
          user={user}
          regCompi={regCompi}
          setRegCompi={setRegCompi}
          team={team}
          setTeam={setTeam}
        />
      ) : (
        <></>
      )}
    </div>
  );
};

export default CompiInfo;
