import React, { useRef } from "react";
import {
  FaInstagram,
  FaLinkedin,
  FaWhatsapp,
  FaTwitter,
  FaFacebook,
  FaYoutube,
} from "react-icons/fa";
import RollingAnimation from "../../Home/HomeComponents/RollingAnimation";
import krishna from "../../../../assets/images/multicity/team_Info/krishna.jpg";
import leen from "../../../../assets/images/multicity/team_Info/leena.jpg";

const AboutCompi = () => {
  const lastpageRef = useRef(null);
  const text = [" MOOD INDIGO MOOD INDIGO"];
  const customStyle = {
    fontSize: "7vw",
    color: "rgba(255, 255, 255, 0.42)",
    fontWeight: "700",
    fontFamily: "lemonMilk",
    lineHeight: "1.18",
  };
  return (
    <div>
      <p className="com_heading">COMPETETIONS TEAM</p>
      <div className="page_six" style={{ background: "#29666e" }}>
        <div className="pr_te cl_pr_te ext_com_ne">
          <div
            className="cg_adeta ext_cg_adeta cg_ainfo_compi"
            style={{ background: "#21A9A4", color: "white" }}
          >
            <img src={krishna} alt="shrea" style={{ borderRadius: "50%" }} />
            <div className="cg_ainfo cg_ainfo_cl_p ">
              <p className="cg_aname">Krishna Chaudhary</p>
              <p>Competitions and LYP Head</p>
              <a
                href="tel:+917066615145"
                className="cg_aphone"
                style={{ color: "white" }}
              >
                +91 70666 15145
              </a>
            </div>
            <div className="cg_alink">
              <a
                target="_blank"
                rel="noreferrer"
                href="https://www.instagram.com/krishna._142/"
              >
                <FaInstagram className="icon_cl" color="white" size={30} />
              </a>
              <a
                target="_blank"
                rel="noreferrer"
                href="https://www.linkedin.com/authwall?trkInfo=AQHbNQ2blrifzgAAAZFmfchIZ1Puzs-om96bQYwuyjWkGveCNnaZ_j8DPYlp-2n1-j39dwxwWgU8tWXudTfJhj9et8lQWSH1tIEaJ3yVK5Pqu655yTVLDk6tURJeBV7pVEHVrAc=&original_referer=&sessionRedirect=https%3A%2F%2Fwww.linkedin.com%2Fin%2Fkrishna-chaudhary-4862a9256%2F"
              >
                <FaLinkedin className="icon_cl" color="white" size={30} />
              </a>

              <a
                target="_blank"
                rel="noreferrer"
                href="https://wa.me/7066615145"
              >
                <FaWhatsapp className="icon_cl" color="white" size={30} />
              </a>
            </div>
          </div>
          <div
            className="cg_adeta ext_cg_adeta cg_ainfo_compi"
            style={{ background: "#21A9A4", color: "white" }}
          >
            <img src={leen} alt="shrea" style={{ borderRadius: "50%" }} />
            <div className="cg_ainfo">
              <p className="cg_aname">Leena Jagwani</p>
              <p>Competitions and LYP Head</p>
              <a
                href="tel:+917066615145"
                className="cg_aphone"
                style={{ color: "white" }}
              >
                +91 93993 59503
              </a>
            </div>
            <div className="cg_alink">
              <a
                target="_blank"
                rel="noreferrer"
                href="https://www.instagram.com/leena_jagwani/"
              >
                <FaInstagram className="icon_cl" color="white" size={30} />
              </a>
              <a
                target="_blank"
                rel="noreferrer"
                href="https://www.linkedin.com/authwall?trkInfo=AQGT60rkxHepLgAAAZFmnJMYMVMoPsxctyqfu4_XKTjdlSh_Z3fyKIGUWYc2gJ0dlXFv0wNvwo1y7tfveGyunp3wXVyi-PCCXjbwcG66SphbMvIS86Usfa88sRlEqpo4_iFROOs=&original_referer=&sessionRedirect=https%3A%2F%2Fwww.linkedin.com%2Fin%2Fleena-jagwani-3b3907257%3Futm_source%3Dshare%26utm_campaign%3Dshare_via%26utm_content%3Dprofile%26utm_medium%3Dandroid_app"
              >
                <FaLinkedin className="icon_cl" color="white" size={30} />
              </a>

              <a
                target="_blank"
                rel="noreferrer"
                href="https://wa.me/9399359503"
              >
                <FaWhatsapp className="icon_cl" color="white" size={30} />
              </a>
            </div>
          </div>
        </div>
      </div>
      <div
        ref={lastpageRef}
        className="footer ext_page_6"
        style={{ background: "#29666e" }}
      >
        <div className="footer_text_animation">
          <RollingAnimation
            style={customStyle}
            text={text}
            direction={"left"}
          />
        </div>
        <div
          className="footer_details ne_com_fo"
          style={{ background: "#21A9A4" }}
        >
          <p>
            Mood Indigo Office, Student Activity Center (SAC), IIT Bombay,
            Powai, Mumbai, Maharashtra - 400076
          </p>
          <div className="footer_icon">
            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.instagram.com/iitbombay.moodi?igsh=MTNmam9pY3Z1YW93aQ=="
            >
              <FaInstagram color="white" size={30} />
            </a>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.linkedin.com/company/mood-indigo/"
            >
              <FaLinkedin color="white" size={30} />
            </a>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://x.com/iitb_moodi?t=tyaVKeWlXXTcc9qIerNTkQ&s=08"
            >
              <FaTwitter color="white" size={30} />
            </a>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.facebook.com/iitbombay.moodi?mibextid=ZbWKwL"
            >
              <FaFacebook color="white" size={30} />
            </a>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.youtube.com/@iitbmoodindigo"
            >
              <FaYoutube color="white" size={30} />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutCompi;
