import React, { useRef } from "react";
import {
  FaInstagram,
  FaLinkedin,
  FaWhatsapp,
  FaTwitter,
  FaFacebook,
  FaYoutube,
} from "react-icons/fa";
import RollingAnimation from "../../Home/HomeComponents/RollingAnimation";
import shreyas from "../../../../assets/images/ccp/team_info/shreyas.jpg";
import ritu from "../../../../assets/images/ccp/team_info/ritu.jpg";

const IntroAcco = () => {
  const lastpageRef = useRef(null);
  const text = [" MOOD INDIGO MOOD INDIGO"];
  const customStyle = {
    fontSize: "7vw",
    color: "rgba(255, 255, 255, 0.42)",
    fontWeight: "700",
    fontFamily: "lemonMilk",
    lineHeight: "1.18",
  };
  return (
    <div className="intro_ac">
      <div className="page_six" style={{ background: "#835FAB" }}>
        <div className="pr_te cl_pr_te">
          <div className="cg_adeta ext_cg_adeta">
            <img src={shreyas} alt="shrea" />
            <div className="cg_ainfo cg_ainfo_cl_p">
              <p className="cg_aname">Shreyas Madankar</p>
              <p>Hospitality and PR Head</p>
              <a href="tel:+918767683564" className="cg_aphone">
                +91 87676 83564
              </a>
            </div>
            <div className="cg_alink">
              <a
                target="_blank"
                rel="noreferrer"
                href="https://www.instagram.com/shreyas.m57?igsh=OW84eDU2Y3hwbzdi"
              >
                <FaInstagram className="icon_cl" color="black" size={30} />
              </a>
              <a
                target="_blank"
                rel="noreferrer"
                href="https://www.linkedin.com/in/shreyas-m-49188824b?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app"
              >
                <FaLinkedin className="icon_cl" color="black" size={30} />
              </a>

              <a
                target="_blank"
                rel="noreferrer"
                href="https://wa.me/8767683564"
              >
                <FaWhatsapp className="icon_cl" color="black" size={30} />
              </a>
            </div>
          </div>
          <div className="cg_adeta">
            <img src={ritu} alt="shrea" />
            <div className="cg_ainfo">
              <p className="cg_aname">Ritu Raj</p>
              <p>Hospitality and PR Head</p>
              <a href="tel:+919142246807" className="cg_aphone">
                +91 91422 46807
              </a>
            </div>
            <div className="cg_alink">
              <a
                target="_blank"
                rel="noreferrer"
                href="https://www.instagram.com/the_ritu_raj1304?igsh=eDI5eGQ2bW04amI2&utm_source=qr"
              >
                <FaInstagram className="icon_cl" color="black" size={30} />
              </a>
              <a
                target="_blank"
                rel="noreferrer"
                href="https://www.linkedin.com/in/ritu-raj-9b699724b/"
              >
                <FaLinkedin className="icon_cl" color="black" size={30} />
              </a>

              <a
                target="_blank"
                rel="noreferrer"
                href="https://wa.me/9142246807"
              >
                <FaWhatsapp className="icon_cl" color="black" size={30} />
              </a>
            </div>
          </div>
        </div>
      </div>
      <div
        ref={lastpageRef}
        className="footer ext_page_6"
        style={{ background: "#835FAB" }}
      >
        <div className="footer_text_animation">
          <RollingAnimation
            style={customStyle}
            text={text}
            direction={"left"}
          />
        </div>
        <div className="footer_details">
          <p>
            Mood Indigo Office, Student Activity Center (SAC), IIT Bombay,
            Powai, Mumbai, Maharashtra - 400076
          </p>
          <div className="footer_icon">
            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.instagram.com/iitbombay.moodi?igsh=MTNmam9pY3Z1YW93aQ=="
            >
              <FaInstagram color="white" size={30} />
            </a>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.linkedin.com/company/mood-indigo/"
            >
              <FaLinkedin color="white" size={30} />
            </a>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://x.com/iitb_moodi?t=tyaVKeWlXXTcc9qIerNTkQ&s=08"
            >
              <FaTwitter color="white" size={30} />
            </a>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.facebook.com/iitbombay.moodi?mibextid=ZbWKwL"
            >
              <FaFacebook color="white" size={30} />
            </a>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.youtube.com/@iitbmoodindigo"
            >
              <FaYoutube color="white" size={30} />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default IntroAcco;
