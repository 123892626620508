import React, { useEffect, useState } from "react";
import Axios from "axios";
import Close from "../../../../assets/images/ccp/close.svg";
import TeamMemberDetails from "./TeamMemberDetails";

const TeamCompiReg = ({
  city,
  tcRef,
  competition,
  user,
  regCompi,
  setRegCompi,
}) => {
  const [team, setTeam] = useState(null);
  const [teamDetails, setTeamDetails] = useState([]);
  const [member, setMember] = useState({ mi_no: "" });

  const onCloseBtnClick = () => {
    tcRef.current.classList.add("no-disp");
  };

  const doLeaderReg = () => {
    Axios.post(
      "https://ashish.moodi.org/multicity/mul_leader_reg",
      {
        user: user.userId,
        competition: competition.mcId,
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
      .then((res) => {
        setTeam(res.data.team);
        fetchTeamDetails(res.data.team);
      })
      .catch((err) => console.error(err));
  };

  const fetchTeamDetails = (teamId) => {
    Axios.post(
      "https://ashish.moodi.org/multicity/mul_team_details",
      {
        team: teamId,
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
      .then((res) => {
        if (res.data.status === "DNE") {
          alert("Team does not exist");
        } else {
          setTeamDetails(res.data);
        }
      })
      .catch((err) => console.error(err));
  };

  const onMemberAddClick = () => {
    if (
      teamDetails.find((element) => element.MI_NO === member.mi_no) ||
      member.mi_no === user.MI_NO
    ) {
      alert("A user can be added only once in a team!");
    } else {
      Axios.post(
        "https://ashish.moodi.org/multicity/mul_member_reg",
        {
          team: team,
          user: member.mi_no,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
        .then((res) => {
          if (res.data.status === "done") {
            setTeamDetails((current) => [
              ...current,
              {
                userName: res.data.userName,
                MI_NO: member.mi_no,
              },
            ]);
          } else {
            alert(res.data.status);
          }
        })
        .catch((err) => console.error(err));
    }
  };

  const onSubmitClick = () => {
    // Validation: Check if the team meets the minimum participant requirement
    console.log("submit is clo");
    if (teamDetails.length < competition.minParticipants - 1) {
      alert(
        `You need a team of at least ${competition.minParticipants} people to participate in this competition.`
      );
      return;
    }

    Axios.post(
      "https://ashish.moodi.org/multicity/confirm_mul_team_reg",
      {
        team: team,
        compi_name: competition.mcName,
        multicity: city,
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
      .then((res) => {
        if (res.data.status === "done") {
          setRegCompi((current) => [
            ...current,
            { competition: competition.mcId },
          ]);
          alert(
            `Team Registration successful for ${competition.mcName}!! You may close the pop-up window.`
          );
          window.location.reload();
        } else {
          alert("Number of participants did not meet the requirement");
        }
      })
      .catch((err) => console.error(err));
  };

  const onChange = (e) => {
    setMember({ ...member, [e.target.name]: e.target.value });
  };

  useEffect(() => {
    doLeaderReg();
  }, []);
  return (
    <div ref={tcRef} className="team_compi_reg_page no-disp">
      <div className="tc_reg_container">
        <div className="tc_top">
          <div className="tc_page_title">TEAM REGISTRATION</div>
          <div className="close-btn" onClick={onCloseBtnClick}>
            <img src={Close} alt="close" />
          </div>
        </div>
        <div className="tc_main">
          <div className="tc_left">
            <div className="tc_info">
              <li>Only Team leader should register for this competition</li>
              <li>
                No other team member should register themselves as the Team
                Leader
              </li>
              <p className="tc_page_title">Add your team members</p>
              <strong>
                Team limit: <span>{competition.maxParticipants}</span>
              </strong>
            </div>
            <div className="members_form">
              <label htmlFor="mi_no">Add New Members:</label>
              <div className="memeber_inp_div">
                <input
                  type="text"
                  name="mi_no"
                  id="mi_no"
                  value={member.mi_no}
                  onChange={onChange}
                  placeholder="Enter the MI Number of the member"
                  maxLength={11}
                />
                <button
                  className="member_add_btn"
                  onClick={onMemberAddClick}
                  disabled={teamDetails.length >= competition.maxParticipants}
                >
                  Add
                </button>
              </div>
            </div>
            <div>
              <li
                style={{
                  listStylePosition: "inside",
                  listStyleType: "disc",
                  textIndent: "-6vw",
                }}
                className="member_list"
              >
                You need a team of atleast {competition.minParticipants} people
                to participate in this competition
              </li>
            </div>
          </div>
          <div className="tc_right">
            <div className="tc_right_title">Team Members</div>
            <div className="member_details">
              <p>{user.userName}</p>
              {user ? <p>(Leader)</p> : <></>}
            </div>
            {teamDetails.map((element) => {
              return (
                <TeamMemberDetails
                  member={element}
                  teamDetails={teamDetails}
                  setTeamDetails={setTeamDetails}
                  team={team}
                />
              );
            })}
            <button
              style={{ cursor: "pointer", zIndex: "10" }}
              className="tc_submit_btn"
              onClick={onSubmitClick}
            >
              Submit
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TeamCompiReg;
