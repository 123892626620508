import React from "react";
import "./css/faqs.css";

const Faqs = () => {
  return (
    <div>
      <div className="sec_title">FAQs</div>
      <div className="faqs_section">
        <div>
          <p>
            <strong>Q.</strong> What is <strong>my referral code</strong> how
            can I use it to earn points?
          </p>
          <p>
            <strong>Ans.</strong> Your referral code can be seen in profile box
            in top left corner, below Mood Indigo. It is of the format{" "}
            <strong>“CCPXXXXXX”</strong>. To earn points, ask your friends to
            use this referral code while registering on{" "}
            <a className="link_a" href="/">my.moodi.org</a>
          </p>
        </div>
        <div>
          <p>
            <strong>Q.</strong> How will I be getting various{" "}
            <strong>rewards</strong>?
          </p>
          <p>
            <strong>Ans.</strong> Certificates and Rewards for volunteering in
            events will be given within one week after the event. Leaderboard
            rank based rewards like accommodation and passes will be given
            during the festival. Leaderboard rank based rewards like
            certificates and internships will be given after the festival.
          </p>
        </div>
        <div>
          <p>
            <strong>Q.</strong> Who can come to volunteer in events?
          </p>
          <p>
            <strong>Ans.</strong> Anyone who has registered on 
            <a className="link_a" href="/">my.moodi.org</a> and wants to be a part of organising
            committee of Mood Indigo can come in multicities and other pre
            festival events as volunteers.
          </p>
        </div>
        <div>
          <p>
            <strong>Q.</strong> How can I attend and earn points
            through workshops?
          </p>
          <p>
            <strong>Ans.</strong> To attend any workshop go to “Workshops” in
            “Earn Points” section. The link will be shared 5 minutes before the
            workshop, join it. Point earning system of that workshop will be
            told during the workshop itself
          </p>
        </div>
        <div>
          <p>
            <strong>Q.</strong> How can I upload a thread in a club?
          </p>
          <p>
            <strong>Ans.</strong> Click on “Add Thread” option on top right.
            Provide the text & image required and then submit the thread. We
            will verify the thread with 3 days and will post it on the club.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Faqs;
